import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Divider,
    IconButton,
    ButtonGroup,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import DrawerCustom from '../../shared/general/Drawer';
import { useTranslation } from 'react-i18next';
import { getAllCompanysByIdVendor } from '../../../../actions/company';
import DeleteIcon from '@mui/icons-material/Delete';

const DialogDownload = ({ dataOption, open, setOpen, selectedItems, setSelectedItems, onHandleSubmit, onHandleDownloadExcel }) => {
    const { t } = useTranslation();
    const [selectedOptionType, setSelectedOptionType] = useState('');
    const [allCompanies, setAllCompanies] = useState([]);
    const dispatch = useDispatch();
    const { result: userProfile } = useSelector((state) => state.userProfile);

    // Yup validation schema
    const validationSchema = Yup.object().shape({
        idCompany: Yup.string().required(t('Company is required')),
    });

    const {
        control,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            idCompany: '',
        },
    });

    const idCompany = watch('idCompany');

    const handleAddItem = (idBillingExtra) => {
        const item = dataOption && dataOption.find((item) => item.idBillingExtra === idBillingExtra);
        if (item && !selectedItems.some((i) => i.idBillingExtra === idBillingExtra)) {
            setSelectedItems((prev) => [...prev, item]);
        }
    };

    const handleRemoveItem = (item) => {
        setSelectedItems((prev) => prev.filter((i) => i !== item));
    };

    const onSave = async (data) => {
        try {
            const idCompany = data.idCompany;
            await onHandleSubmit(idCompany, selectedOptionType, selectedItems); // Handle PDF generation

            setOpen(false);
            setSelectedItems([]);
            setSelectedOptionType('');
            reset();
        } catch (error) {
            console.error('Error during the download process:', error);
        }
    };

    const onDownloadExcel = async () => {
        try {
            await onHandleDownloadExcel();
            setOpen(false);
            setSelectedItems([]);
            setSelectedOptionType('');
            reset();
        } catch (error) {
            console.error('Error during the download process:', error);
        }
    };



    const fetchCompanies = async (idVendor) => {
        try {
            const response = await dispatch(getAllCompanysByIdVendor(idVendor));
            if (response && response.data) {
                setAllCompanies(response.data);
                setValue('idCompany', response.data.length > 0 ? response.data[0].idCompany : '');
            }
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    };

    useEffect(() => {
        if (userProfile) {
            fetchCompanies(userProfile.idVendor);
        }
    }, [userProfile]);

    useEffect(() => {
        setSelectedItems([]);
    }, [idCompany]);

    return (
        <DrawerCustom
            anchor="right"
            open={open}
            onClose={() => setOpen(false)}
            title={t('ดาวน์โหลดบิล')}
        >
            <Box padding={3} width={500}>
                <FormControl fullWidth margin="normal">
                    <ButtonGroup variant="outlined" fullWidth>
                        <Button
                            onClick={() => setSelectedOptionType('invoice')}
                            variant={selectedOptionType === 'invoice' ? 'contained' : 'outlined'}
                        >
                            {t('ใบวางบิล')}
                        </Button>
                        <Button
                            onClick={() => setSelectedOptionType('debtNoticeORtaxInvoice')}
                            variant={selectedOptionType === 'debtNoticeORtaxInvoice' ? 'contained' : 'outlined'}
                        >
                            {t('ใบเเจ้งหนี้/ใบกำกับภาษี')}
                        </Button>
                    </ButtonGroup>

                </FormControl>

                {/* Select Company */}
                <FormControl fullWidth margin="normal" error={!!errors.idCompany}>
                    <InputLabel id="select-company-label" sx={{ backgroundColor: 'white' }}>
                        {t('Company')}
                    </InputLabel>
                    <Controller
                        name="idCompany"
                        control={control}
                        render={({ field }) => (
                            <Select {...field} labelId="select-company-label">
                                {allCompanies.map((company) => (
                                    <MenuItem key={company.idCompany} value={company.idCompany}>
                                        {company.companyName}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                    {errors.idCompany && (
                        <Typography color="error">{errors.idCompany.message}</Typography>
                    )}
                </FormControl>

                {/* Select Document */}
                <FormControl fullWidth margin="normal">
                    <InputLabel sx={{ backgroundColor: 'white' }}>{t('Select')} {t('Items')}</InputLabel>
                    <Select
                        value={selectedItems.length > 0 ? selectedItems[selectedItems.length - 1].idBillingExtra : ''}
                        onChange={(e) => handleAddItem(e.target.value)}
                    >
                        {idCompany && Array.isArray(dataOption) &&
                            dataOption
                                .filter((item) => item.idCompany === idCompany)
                                .filter((item) => !selectedItems.some((i) => i.idBillingExtra === item.idBillingExtra))
                                .map((option) => (
                                    <MenuItem key={option.idBillingExtra} value={option.idBillingExtra}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                    </Select>
                </FormControl>

                {/* Selected Documents */}
                <Box mt={2} pl={2} pr={2}>
                    <Typography variant="h6">{t('Lists')}</Typography>
                    <ul style={{ padding: '0 16px', listStyleType: 'none' }}>
                        {selectedItems.map((item, index) => (
                            <li
                                key={index}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom: '8px',
                                }}
                            >
                                <span>{item.name}</span>
                                <IconButton
                                    size="small"
                                    color="secondary"
                                    onClick={() => handleRemoveItem(item)}
                                    style={{ marginLeft: 'auto' }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </li>
                        ))}
                    </ul>
                </Box>

                <Divider style={{ margin: '16px 0' }} />

                {/* Save and Cancel */}
                <Box display="flex" justifyContent="flex-end">
                    <Button onClick={() => setOpen(false)}>{t('Cancel')}</Button>

                    <Button
                        color="primary"
                        variant="contained"
                        disabled={selectedItems.length <= 0 || !selectedOptionType}
                        onClick={() => handleSubmit((data) => onSave(data))}
                        style={{ marginLeft: 8 }}
                    >
                        {t('Download')} PDF
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={selectedItems.length <= 0}
                        onClick={() => onDownloadExcel()}
                        style={{ marginLeft: 8 }}
                    >
                        {t('Download')} Excel
                    </Button>

                </Box>
            </Box>
        </DrawerCustom>
    );
};

export default DialogDownload;
