import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import 'dayjs/locale/th';
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import {
    Table, TableBody, TableCell,
    TableContainer, TableHead, TablePagination,
    TableRow, Box, Typography, Container,
    Breadcrumbs, TextField, Grid,
    Popper, Avatar, InputAdornment, Stack,
    MenuItem
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import SearchIcon from "@mui/icons-material/Search";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyle from "../../shared/general/Card";
import { useTranslation } from "react-i18next";
import { LeaveListXlsx } from "./xlsx-export/leave-list-xlsx";
import { getLeaveDetails } from "../../../../actions/report";
import { getAllCompaniesByVendor, getCompanyContract } from "../../../../actions/vendor";
import { SelectAllCompaniesVendor } from "./SelectCompanyLists";
import DatePickerCustom from "../../shared/date/datePicker";
import AlertResponse from "../../shared/general/AlertResponse";
import leaveService from "../../../../services/leave.service";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
  "& .search-filter-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .filter-wrap": {},
    "& .action-container": {},
  },
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const StyledBox = styled(Box)({
  "&.open": {
    paddingLeft: 16,
    width: "calc(100% - 1000px)",
  },
  "&.close": {
    paddingLeft: 0,
    width: "100%",
  },
});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  whiteSpace: "nowrap",
  "& .MuiTypography-root": {
    color: "#637381",
    backgroundColor: "#f4f6f8",
    "&.weekend": {
        fontStyle: "oblique",
    },
    "&.workday": {
        fontWeight: 600,
    },
  },
  "&.sticky": {
    padding: 0,
    position: "sticky",
    left: 0,
    zIndex: 4,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px 0px 20px #EEEEEE",
    "& .MuiTableCell-root": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: 8,
  whiteSpace: "nowrap",
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .avatar": {
    minWidth: 240,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .fullname": {
    fontWeight: 600,
  },
  "& .position": {
    color: theme.palette.text.secondary,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .secondaryAction": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .location": {
      fontWeight: 600,
    },
  },
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
      fontWeight: 600,
      fontSize: 14,
      marginBottom: 8,
  },
});

const QuotaLeaveList = (props) => {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: vendorContract } = useSelector((state) => state.vendorContract);

  const [leaveRoundList, setLeaveRoundList] = useState([]);
  const [selectedLeaveRound, setSelectedLeaveRound] = useState({
    date: "",
    start: null,
    end: null,
  });

  const [filterDate, setFilterDate] = useState({
    start: null,
    end: null,
  })

  const [selectedCompany, setSelectedCompany] = useState(null);

  const [isFetching, setIsFetching] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [employeeList, setEmployeeList] = useState([]);
  const [leaveGroupList, setLeaveGroupList] = useState([]);

  const [isFirstSearch, setIsFirstSearch] = useState(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOpenAlert = () => {
    // setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    // setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    // setAlertType(newValue);
  };

  const fetchData = async () => {
    try {
      setIsFetching(true);
      const response = await getLeaveDetails({
        date: selectedLeaveRound.date,
        idCompany: selectedCompany.idCompany,
        start: dayjs(filterDate.start).format("YYYY-MM-DD"),
        end: dayjs(filterDate.end).format("YYYY-MM-DD"),
      });

      if (response) {
        setIsFetching(false);
        setEmployeeList((response.data && response.data.employees)? response.data.employees: [])
        setLeaveGroupList((response.data && response.data.leaveGroup)? response.data.leaveGroup: []);
      } else {
        // handleChangeAlertType(`${t("NoData")}`);
        // handleOpenAlert(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownload = () => {
    if (employeeList && employeeList.length > 0) {
      LeaveListXlsx(t, employeeList, leaveGroupList);
    } else {

    }
  };

  const fetchLeaveRound = (idVendor) => {
    leaveService.getLeaveRoundList({idVendor: idVendor}).then(res => {
      setLeaveRoundList(res.data)
      const today = dayjs();
      const foundLeaveRound = res.data.find(item => (
        dayjs(today).isBetween(
          dayjs(item.start),
          dayjs(item.end),
          "[]"
        )
      ))
      setSelectedLeaveRound({
        date: foundLeaveRound.date,
        start: foundLeaveRound.start,
        end: foundLeaveRound.end,
      });
      setFilterDate(prev => ({
        ...prev,
        start: dayjs(foundLeaveRound.start),
        end: dayjs(foundLeaveRound.end),
      }))
    }).catch(error => {
      setLeaveRoundList([])
    })
  }

  const renderValueLeaveRound = (value) => {
    const foundLeaveRoundValue = leaveRoundList.find(item => item.date === value)

    if(foundLeaveRoundValue){
      return <Typography>
        {i18n.language === "th"? 
          `${dayjs(foundLeaveRoundValue.start).format("D MMMM BBBB")} - ${dayjs(foundLeaveRoundValue.end).format("D MMMM BBBB")}`
        : 
          `${dayjs(foundLeaveRoundValue.start).locale(i18n.language).format("D MMMM YYYY")} - ${dayjs(foundLeaveRoundValue.end).locale(i18n.language).format("D MMMM YYYY")}`
        }
      </Typography>
    } else {
      return <Typography>-</Typography>
    }
  }

  const handleChangeLeaveRound = (value) => {
    const foundLeaveRound = leaveRoundList.find(item => item.date === value);
    if(foundLeaveRound){
      setSelectedLeaveRound({
        date: foundLeaveRound.date,
        start: foundLeaveRound.start,
        end: foundLeaveRound.end,
      });
      setFilterDate(prev => ({
        ...prev,
        start: dayjs(foundLeaveRound.start),
        end: dayjs(foundLeaveRound.end),
      }));
    }
  }

  const handleSearch = () => {
    setIsFirstSearch(false);
    fetchData();
  }

  useEffect(() => {
    dispatch(getCompanyContract());
  }, [])

  useEffect(() => {
    if(userProfile && vendorContract && vendorContract.length > 0){
      const foundCompany = vendorContract[0];
      if(foundCompany){
        setSelectedCompany(foundCompany);
        fetchLeaveRound(userProfile.idVendor);
      } else {
        setSelectedCompany(null);
      }
    }
  }, [vendorContract, userProfile]);

  const LeaveTypeColumns =
    leaveGroupList &&
    leaveGroupList.flatMap((i) => [
      {
        id: `${i.idLeaveGroup}_quota`,
        label: `${i.leaveGroupName} (${t("LeaveRights")})`,
        minWidth: 170,
      },
      {
        id: `${i.idLeaveGroup}_used`,
        label: `${i.leaveGroupName} (${t("Used")})`,
        minWidth: 170,
      },
      {
        id: `${i.idLeaveGroup}_remaining`,
        label: `${i.leaveGroupName} (${t("Remaining")})`,
        minWidth: 170,
      },
    ]);

  const columns = [
    { id: "JopPosition", label: t("Position"), minWidth: 170 },
    { id: "Company", label: t("Company"), minWidth: 170 },
    { id: "Division", label: t("Division"), minWidth: 170 },
    { id: "Department", label: t("Department"), minWidth: 170 },
    { id: "Section", label: t("Section"), minWidth: 100 },
    ...(LeaveTypeColumns || []),
  ];

    // const fetchedCompanyByVendor = async () => {
    //     try {
    //         const response = await getAllCompaniesByVendor();
    //         if (response && response.data) {
    //             setCompanyLists(response.data);
    //         } else {
    //             setCompanyLists(null);
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    // useEffect(() => {
    //     fetchedCompanyByVendor();
    // }, []);

    // const onChangeCompany = (newValue) => {
    //     setSelectedCompany(newValue);
    // };

    // const fetchData = async () => {
    //     try {
    //         setIsFetching(true);
    //         const response = await getLeaveDetails({
    //             idCompany: selectedCompany && selectedCompany.idCompany,
    //             inputSearch: dayjs(inputSearch).locale('th').format("YYYY")
    //         });

    //         if (response && response.data) {
    //             setIsFetching(false);
    //             setLeaveList(response.data.LeaveEmployees ? response.data.LeaveEmployees : null);
    //             setLeaveType(response.data.leaveType ? response.data.leaveType : null);
    //         } else {
    //             handleChangeAlertType(`${t("NoData")}`);
    //             handleOpenAlert(true);
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }

    // const handleSubmit = () => {
    //     if (leaveList && leaveList.length > 0) {
    //         LeaveListXlsx(leaveList, leaveType);
    //     } else {
    //         handleChangeAlertType(`${t("NoData")}`);
    //         handleOpenAlert(true);
    //     }
    // };

    // useEffect(() => {
    //     if (selectedCompany && inputSearch) {
    //         fetchData();
    //     }
    // }, [selectedCompany, inputSearch]);

    // const LeaveTypeColumns = leaveType && leaveType.flatMap(i => ([
    //     {
    //         id: `${i.idLeaveType}_quota`,
    //         label: `โควต้า ${i.name}`,
    //         minWidth: 170
    //     },
    //     {
    //         id: `${i.idLeaveType}_used`,
    //         label: `${i.name} ใช้ไป`,
    //         minWidth: 170
    //     },
    //     {
    //         id: `${i.idLeaveType}_remaining`,
    //         label: `${i.name} คงเหลือ`,
    //         minWidth: 170
    //     },
    // ]));

    // const columns = [
    //     { id: "JopPosition", label: "ตำแหน่ง", minWidth: 170 },
    //     { id: "Company", label: "บริษัท", minWidth: 170 },
    //     { id: "Division", label: "ฝ่าย", minWidth: 170 },
    //     { id: "Section", label: "ส่วน", minWidth: 100 },
    //     { id: "Department", label: "แผนก", minWidth: 170 },
    //     ...(LeaveTypeColumns || [])
    // ];

    // useEffect(() => {
    //     if (companyLists && companyLists.length > 0) {
    //         setSelectedCompany(companyLists[0])
    //     }
    // }, [companyLists]);

    // return (
    //     <StyledRoot className="page">
    //         <Container maxWidth="lg">
    //             <Breadcrumbs
    //                 separator={<NavigateNextIcon fontSize="small" />}
    //                 aria-label="breadcrumb"
    //             >
    //                 <Link
    //                     style={{ textDecoration: "none", color: "inherit" }}
    //                     to={"/report-center"}
    //                 >
    //                     {`${t("AllReports")}`}
    //                 </Link>
    //                 <Typography color="text.primary">{t("QuotaOnLeave")}</Typography>
    //             </Breadcrumbs>

    //             <Typography variant="h4" sx={{ mt: 3 }}>
    //                 {t("QuotaOnLeave")}
    //             </Typography>

    //             <Box sx={{ mt: 4 }}>
    //                 <Grid
    //                     container
    //                     spacing={2}
    //                     alignItems={"center"}
    //                 >
    //                     <Grid item xs={6} md={3}>
    //                         <StyledBoxSearch>
    //                             <Typography className="label" color="text.third">
    //                                 {t("SelectYear")}
    //                             </Typography>
    //                             <div className="search-date">
    //                                 <DatePickerCustom
    //                                     maxDate={dayjs()}
    //                                     inputFormat="YYYY"
    //                                     value={inputSearch}
    //                                     name="start"
    //                                     views={['year']}
    //                                     openTo={'year'}
    //                                     onChange={(newValue) => {
    //                                         setInputSearch(newValue);
    //                                     }}
    //                                 />
    //                             </div>
    //                         </StyledBoxSearch>
    //                     </Grid>

    //                     <Grid item xs={6} md={3}>
    //                         <StyledBoxSearch>
    //                             <Typography className="label" color="text.third">
    //                                 {`${t("Company")}`}
    //                             </Typography>
    //                             <div className="search-name">
    //                                 <SelectAllCompaniesVendor
    //                                     options={companyLists ? companyLists : null}
    //                                     value={selectedCompany}
    //                                     disabled={isFetching}
    //                                     onChange={(_, value) => {
    //                                         onChangeCompany(value);
    //                                     }}
    //                                 />
    //                             </div>
    //                         </StyledBoxSearch>
    //                     </Grid>

    //                     <Grid item xs={6} md={3}>
    //                         <StyledBoxSearch>
    //                             <Typography className="label" color="text.third">
    //                                 {`${t("FullName")}`}
    //                             </Typography>
    //                             <div className="search-name">
    //                                 <TextFieldTheme
    //                                     style={{
    //                                         background: "#fff",
    //                                         borderRadius: "10px",
    //                                     }}
    //                                     InputProps={{
    //                                         startAdornment: (
    //                                             <InputAdornment position="center">
    //                                                 <SearchIcon />
    //                                             </InputAdornment>
    //                                         ),
    //                                         placeholder: `${t("Search")}`,
    //                                     }}
    //                                     value={searchText}
    //                                     onChange={(e) => setSearchText(e.target.value)}
    //                                 />
    //                             </div>
    //                         </StyledBoxSearch>
    //                     </Grid>

    //                     <Grid item xs={6} md={3}>
    //                         <StyledBoxSearch>
    //                             <div className="search-name">
    //                                 <ButtonBlue
    //                                     variant="contained"
    //                                     startIcon={<DownloadRoundedIcon />}
    //                                     onClick={() => handleSubmit()}
    //                                     disabled={isFetching}
    //                                     sx={{ mt: 3 }}
    //                                 >
    //                                     {`${t("Download")}`}
    //                                 </ButtonBlue>
    //                             </div>
    //                         </StyledBoxSearch>
    //                     </Grid>
    //                 </Grid>
    //             </Box>

    //             <CardStyle style={{ marginTop: "3vh" }}>
    //                 <Box style={{ padding: "24px" }}>
    //                     {isFetching ? (
    //                         <Typography textAlign="center" color="text.secondary" fontSize="24px">
    //                             {t(`LoadingData`)}...
    //                         </Typography>
    //                     ) : (
    //                         <Box style={{ display: "flex", position: "relative" }}>
    //                             <StyledBox className={"close"}>
    //                                 <TableContainer style={{ width: "100%", maxHeight: 600 }}>
    //                                     <Table stickyHeader aria-label="sticky table">
    //                                         <TableHead>
    //                                             <TableRow>
    //                                                 <StyledCellHeader
    //                                                     align={"center"}
    //                                                     className="sticky"
    //                                                     style={{ minWidth: 240 }}
    //                                                 >
    //                                                     {t("FullName")}
    //                                                 </StyledCellHeader>

    //                                                 {columns.map((column) => (
    //                                                     <StyledCellHeader key={column.id}>
    //                                                         {column.label}
    //                                                     </StyledCellHeader>
    //                                                 ))}
    //                                             </TableRow>
    //                                         </TableHead>
    //                                         <TableBody>
    //                                             <Fragment>
    //                                                 {leaveList && leaveList.length > 0 ? (
    //                                                     leaveList.slice(
    //                                                         page * rowsPerPage,
    //                                                         page * rowsPerPage + rowsPerPage
    //                                                     ).filter((row) => {
    //                                                         const fullname = `${row.firstname + " " + row.lastname}`.toLowerCase();
    //                                                         return fullname.includes(
    //                                                             searchText.toLowerCase()
    //                                                         );
    //                                                     }).map((row, index) => (
    //                                                         <StyledRowContent hover key={index}>
    //                                                             <StyledCellContent
    //                                                                 className="sticky"
    //                                                                 style={{
    //                                                                     borderBottom:
    //                                                                         "1px dashed rgba(224, 224, 224, 1)",
    //                                                                 }}
    //                                                             >
    //                                                                 <div className="avatar">
    //                                                                     <Avatar src={`${row.imageProfile}`} />
    //                                                                     <div>
    //                                                                         <Typography className="fullname">
    //                                                                             {row.firstname + " " + row.lastname}
    //                                                                         </Typography>
    //                                                                     </div>
    //                                                                 </div>
    //                                                             </StyledCellContent>

    //                                                             <StyledCellContent>
    //                                                                 <Typography>{row.positionsName ? row.positionsName : "-"}</Typography>
    //                                                             </StyledCellContent>

    //                                                             <StyledCellContent>
    //                                                                 <Typography>{row.companyName ? row.companyName : "-"}</Typography>
    //                                                             </StyledCellContent>

    //                                                             <StyledCellContent>
    //                                                                 <Typography>{row.divisionName ? row.divisionName : "-"}</Typography>
    //                                                             </StyledCellContent>

    //                                                             <StyledCellContent>
    //                                                                 <Typography>{row.sectionName ? row.sectionName : "-"}</Typography>
    //                                                             </StyledCellContent>

    //                                                             <StyledCellContent>
    //                                                                 <Typography>{row.departmentName ? row.departmentName : "-"}</Typography>
    //                                                             </StyledCellContent>

    //                                                             {leaveType && leaveType.map((leave, idx) => {
    //                                                                 const matchedLeave = row.leaves && row.leaves.length > 0 && row.leaves.find(l => l.idLeaveType === leave.idLeaveType);
    //                                                                 const used = matchedLeave ? (Number.isInteger(matchedLeave.used) ? matchedLeave.used : matchedLeave.used.toFixed(2)) : '-';
    //                                                                 const remaining = matchedLeave && typeof matchedLeave.remaining === 'number' ? matchedLeave.remaining.toFixed(2) : '-';
    //                                                                 const quota = matchedLeave ? matchedLeave.quota : '-';

    //                                                                 const usedColumnId = `${leave.idLeaveType}_used`;
    //                                                                 const remainingColumnId = `${leave.idLeaveType}_remaining`;
    //                                                                 const quotaColumnId = `${leave.idLeaveType}_quota`;

    //                                                                 const usedColumn = columns.find(col => col.id === usedColumnId);
    //                                                                 const remainingColumn = columns.find(col => col.id === remainingColumnId);
    //                                                                 const quotaColumn = columns.find(col => col.id === quotaColumnId);

    //                                                                 return (
    //                                                                     <React.Fragment key={idx}>
    //                                                                         <StyledCellContent>
    //                                                                             <Typography style={{ textAlign: "center" }}>{quotaColumn && <span>{quota}</span>}</Typography>
    //                                                                         </StyledCellContent>
    //                                                                         <StyledCellContent>
    //                                                                             <Typography style={{ textAlign: "center" }}>{usedColumn && <span>{used}</span>}</Typography>
    //                                                                         </StyledCellContent>
    //                                                                         <StyledCellContent>
    //                                                                             <Typography style={{ textAlign: "center" }}>{remainingColumn && <span>{remaining}</span>}</Typography>
    //                                                                         </StyledCellContent>
    //                                                                     </React.Fragment>
    //                                                                 );
    //                                                             })}
    //                                                         </StyledRowContent>
    //                                                     ))
    //                                                 ) : (
    //                                                     <StyledRowContent>
    //                                                         <StyledCellContent
    //                                                             colSpan={5}
    //                                                             style={{ height: 150 }}
    //                                                         >
    //                                                             <Typography align="center">
    //                                                                 {t("NoData")}
    //                                                             </Typography>
    //                                                         </StyledCellContent>
    //                                                     </StyledRowContent>
    //                                                 )}
    //                                             </Fragment>
    //                                         </TableBody>
    //                                     </Table>
    //                                 </TableContainer>
    //                                 <TablePagination
    //                                     rowsPerPageOptions={[10, 25, 100]}
    //                                     component="div"
    //                                     count={leaveList ? leaveList.length : 0}
    //                                     rowsPerPage={rowsPerPage}
    //                                     page={page}
    //                                     onPageChange={handleChangePage}
    //                                     onRowsPerPageChange={handleChangeRowsPerPage}
    //                                 />
    //                             </StyledBox>
    //                         </Box>
    //                     )}
    //                 </Box>
    //             </CardStyle>
    //         </Container>
    //         <AlertResponse
    //             open={openAlert}
    //             handleClose={handleCloseAlert}
    //             alertType={alertType}
    //         />
    //     </StyledRoot>
    
    // );
    return (
      <StyledRoot className="page">
        <Container maxWidth="lg">
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to={"/report-center"}
            >
              {`${t("AllReports")}`}
            </Link>
            <Typography color="text.primary">{t("QuotaOnLeave")}</Typography>
          </Breadcrumbs>

          <Typography variant="h4" sx={{ mt: 3 }}>
            {t("QuotaOnLeave")}
          </Typography>

          <Box marginTop="24px">
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Typography color="text.secondary" fontSize="14px" fontWeight="500" marginBottom="8px">{t("Company")}</Typography>  
                  <Autocomplete
                    options={vendorContract? vendorContract: []}
                    getOptionLabel={(option) => option.companyName}
                    isOptionEqualToValue={(option, value) => option.idCompany === value.idCompany}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        placeholder={`${t("SelectCompany")}`}
                      />
                    )}
                    value={selectedCompany}
                    onChange={(_, value) => {
                      setSelectedCompany(value);
                      // fetchLeaveRound(value.idCompany);
                    }}
                    noOptionsText={`${t("NoData")}`}
                    disableClearable
                  />
              </Grid>

              <Grid item xs={12} md={3}>
                <Typography color="text.secondary" fontSize="14px" fontWeight="500" marginBottom="8px">{t("SelectLeaveRound")}</Typography>
                <TextFieldTheme
                  select
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (selected) => {
                      if(selected.length === 0){
                        return <Typography color="text.secondary">{t("SelectLeaveRound")}</Typography>
                      } else {
                        return renderValueLeaveRound(selected)
                      }
                    }
                  }}
                  value={selectedLeaveRound.date}
                  onChange={(event) => {
                    handleChangeLeaveRound(event.target.value);
                  }}
                >
                  <MenuItem value="" disabled>{t("SelectLeaveRound")}</MenuItem>
                  {leaveRoundList.map(item => (
                    <MenuItem value={item.date}>
                      {i18n.language === "th"? 
                        `${dayjs(item.start).format("D MMMM BBBB")} - ${dayjs(item.end).format("D MMMM BBBB")}`
                      : 
                        `${dayjs(item.start).locale(i18n.language).format("D MMMM YYYY")} - ${dayjs(item.end).locale(i18n.language).format("D MMMM YYYY")}`
                      }
                    </MenuItem>
                  ))}
                </TextFieldTheme>
              </Grid>

              <Grid item xs={12} md={3}>
                <Typography color="text.secondary" fontSize="14px" fontWeight="500" marginBottom="8px">{t("StartDate")}</Typography>
                <DatePickerCustom
                  minDate={dayjs(selectedLeaveRound.start)}
                  maxDate={dayjs(selectedLeaveRound.end)}
                  inputFormat="DD MMMM YYYY"
                  views={['day', 'month', 'year']}
                  openTo={'day'}
                  inputProps={{
                    readOnly: true
                  }}
                  value={filterDate.start}
                  onChange={(newValue) => {
                    setFilterDate(prev => ({
                      ...prev,
                      start: newValue
                    }))
                    if(dayjs(filterDate.end).diff(dayjs(newValue), 'day') < 0){
                      setFilterDate(prev => ({
                        ...prev,
                        end: newValue
                      }))
                    }
                  }}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Typography color="text.secondary" fontSize="14px" fontWeight="500" marginBottom="8px">{t("EndDate")}</Typography>
                <DatePickerCustom
                  minDate={dayjs(selectedLeaveRound.start)}
                  maxDate={dayjs(selectedLeaveRound.end)}
                  inputFormat="DD MMMM YYYY"
                  views={['day', 'month', 'year']}
                  openTo={'day'}
                  inputProps={{
                    readOnly: true
                  }}
                  value={filterDate.end}
                  onChange={(newValue) => {
                    setFilterDate(prev => ({
                      ...prev,
                      end: newValue
                    }))
                  }}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <ButtonBlue variant="contained" onClick={handleSearch} disabled={isFetching}>{t("Search")}</ButtonBlue>
                  <Box paddingLeft="8px">
                    <ButtonBlue
                      type="submit"
                      variant="outlined"
                      startIcon={<DownloadRoundedIcon />}
                      onClick={handleDownload}
                      disabled={isFetching}
                    >
                      {t("Download")}
                    </ButtonBlue>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {isFirstSearch? (
            <Box marginTo="24px">
              <Typography fontSize="20px" fontStyle="oblique" align="center">{t("PleasePressSearchButton")}...</Typography>
            </Box>
          ) : (<CardStyle style={{ marginTop: "3vh" }}>
            <Box style={{ padding: "24px" }}>
              <Box style={{ display: "flex", position: "relative" }}>
                <StyledBox className={"close"}>
                  <TableContainer style={{ width: "100%", maxHeight: 600 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <StyledCellHeader
                            align={"center"}
                            className="sticky"
                            style={{ minWidth: 240 }}
                          >
                            {t("FullName")}
                          </StyledCellHeader>

                          {columns.map((column) => (
                            <StyledCellHeader key={column.id}>
                              {column.label}
                            </StyledCellHeader>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {isFetching === true ? (
                          <StyledRowContent>
                            <StyledCellContent
                              colSpan={5}
                              style={{ height: 150 }}
                            >
                              {/* <Typography align="center">
                                {t("LoadingEmpData")}
                              </Typography> */}
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center"
                                }}
                              >
                                {/* <img alt="loading" src={loading} style={{ width: 100, height: 100 }} /> */}
                              </Box>
                            </StyledCellContent>
                          </StyledRowContent>
                        ) : (
                          <Fragment>
                            {employeeList && employeeList.length > 0 ? (
                              employeeList
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .filter((row) => {
                                  const fullname = `${row.firstname} ${row.lastname}`.toLowerCase();
                                  return fullname.includes(
                                    searchText.toLowerCase()
                                  );
                                })
                                .map((row, index) => (
                                  <StyledRowContent hover key={index}>
                                    <StyledCellContent
                                      className="sticky"
                                      style={{
                                        borderBottom:
                                          "1px dashed rgba(224, 224, 224, 1)",
                                      }}
                                    >
                                      <div className="avatar">
                                        {/* <Avatar alt={row.employeeID} src={`${row.imageProfile}`} /> */}
                                        <div style={{paddingLeft: "8px"}}>
                                          <Typography className="fullname">
                                            {row.firstname + " " + row.lastname}
                                            {/* {getUserFullName(row)
                                              ? getUserFullName(row)
                                              : row.firstname_TH +
                                              " " +
                                              row.lastname_TH} */}
                                          </Typography>
                                        </div>
                                      </div>
                                    </StyledCellContent>

                                    <StyledCellContent>
                                      <Typography>
                                        {row.positionsName || "-"}
                                        {/* {getUserPosition(row) ? getUserPosition(row) : "-"} */}
                                      </Typography>
                                    </StyledCellContent>

                                    <StyledCellContent>
                                      <Typography>
                                        {row.companyName || "-"}
                                        {/* {getUserCompany(row) ? getUserCompany(row) : "-"} */}
                                      </Typography>
                                    </StyledCellContent>

                                    <StyledCellContent>
                                      <Typography>
                                        {row.divisionName || "-"}
                                        {/* {getUserDivision(row) ? getUserDivision(row) : "-"} */}
                                      </Typography>
                                    </StyledCellContent>

                                    <StyledCellContent>
                                      <Typography>
                                        {row.departmentName || "-"}
                                        {/* {getUserDepartment(row) ? getUserDepartment(row) : "-"} */}
                                      </Typography>
                                    </StyledCellContent>

                                    <StyledCellContent>
                                      <Typography>
                                        {row.sectionName || "-"}
                                        {/* {getUserSection(row) ? getUserSection(row) : "-"} */}
                                      </Typography>
                                    </StyledCellContent>

                                    {leaveGroupList &&
                                      leaveGroupList.map((leave, idx) => {

                                        const matchedLeave = row.leaveGroupInfo[leave.idLeaveGroup]
                                          ? row.leaveGroupInfo[leave.idLeaveGroup]
                                          : null;

                                        const quota = matchedLeave
                                          ? matchedLeave.leaveValue === null
                                            ? "ไม่จำกัด"
                                            : (matchedLeave.leaveValue + matchedLeave.carryValue)
                                          : "-"
                                        
                                        const used = matchedLeave? matchedLeave.leaveUsed: 0;
                                        
                                        const remain = matchedLeave
                                          ? matchedLeave.leaveValue === null
                                            ? "ไม่จำกัด"
                                            : (matchedLeave.leaveRemain + matchedLeave.carryRemain)
                                          : "-"

                                        return (
                                          <React.Fragment key={idx}>
                                            <StyledCellContent>
                                              <Typography
                                                style={{ textAlign: "center" }}
                                              >
                                                <span>{quota}</span>
                                              </Typography>
                                            </StyledCellContent>
                                            <StyledCellContent>
                                              <Typography
                                                style={{ textAlign: "center" }}
                                              >
                                                <span>{used}</span>
                                              </Typography>
                                            </StyledCellContent>
                                            <StyledCellContent>
                                              <Typography
                                                style={{ textAlign: "center" }}
                                              >
                                                <span>{remain}</span>
                                              </Typography>
                                            </StyledCellContent>
                                          </React.Fragment>
                                        );
                                      })}
                                  </StyledRowContent>
                                ))
                            ) : (
                              <StyledRowContent>
                                <StyledCellContent
                                  colSpan={5}
                                  style={{ height: 150 }}
                                >
                                  <Typography align="center">
                                    {t("NoEmployee")}
                                  </Typography>
                                </StyledCellContent>
                              </StyledRowContent>
                            )}
                          </Fragment>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={employeeList ? employeeList.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={`${t("RowsPerPage")}`}
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} ${t("OutOf")} ${count !== -1 ? count : `${t("MoreThan")} ${to}`
                      }`
                    }
                  />
                </StyledBox>
              </Box>
            </Box>
          </CardStyle>)}
        </Container>
        {/* <AlertResponse
          open={openAlert}
          handleClose={handleCloseAlert}
          alertType={alertType}
        /> */}
      </StyledRoot>
    )
};

export default QuotaLeaveList;
