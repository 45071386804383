import ExcelJS from 'exceljs';

export const onExportExcel = (listBillingDetail, additionVendor) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Export ใบวางบิล');

    const headerValues = [
        "ชื่อ",
        "นามสกุล",
        "แผนก",
        "ค่าแรง",
        "Charge ค่าแรง",
        "ค่าล่วงเวลา",
        "OTx1 (ชม.)",
        "OTx1 (บาท/ชม.)",
        "OTx1 (Charge)",
        "OTx1 (เรียกเก็บ)",
        "OTx1.5 (ชม.)",
        "OTx1.5 (บาท/ชม.)",
        "OTx1.5 (Charge)",
        "OTx1.5 (เรียกเก็บ)",
        "OTx2 (ชม.)",
        "OTx2 (บาท/ชม.)",
        "OTx2 (Charge)",
        "OTx2 (เรียกเก็บ)",
        "OTx3 (ชม.)",
        "OTx3 (บาท/ชม.)",
        "OTx3 (Charge)",
        "OTx3 (เรียกเก็บ)",
        "รวม OT (เรียกเก็บ)",
        "รวม Charge OT",
        "Charge เหมา",
        "Charge ค่าชดเชย",
        "ค่ากะ",
        "Charge ค่ากะ",
        ...additionVendor.map(item => item.name),
        "รวม Addition",
        "รวม Charge",
        "รวมรายรับ",
        "เพิ่มค่าอื่นๆ",
        "หักค่าอื่นๆ",
        "รวมค่าอื่นๆ",
        "เรียกเก็บสุทธิ",
    ];

    const headerRow = worksheet.addRow(headerValues);
    headerRow.height = 50;

    const headerStyle1 = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    };

    headerRow.eachCell((cell) => {
        cell.style = headerStyle1;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    const colWidths = [
        { key: "firstname", width: 30 },
        { key: "lastname", width: 30 },
        { key: "sectionName", width: 30 },
        { key: "salary", width: 30 },
        { key: "chargeSalary", width: 30 },
        { key: "totalOT", width: 30 },
        { key: "hoursOT1", width: 30 },
        { key: "perHoursOT1", width: 30 },
        { key: "chargeOT1", width: 30 },
        { key: "totalOT1", width: 30 },
        { key: "hoursOT1dot5", width: 30 },
        { key: "perHoursOT1dot5", width: 30 },
        { key: "chargeOT1dot5", width: 30 },
        { key: "totalOT1dot5", width: 30 },
        { key: "hoursOT2", width: 30 },
        { key: "perHoursOT2", width: 30 },
        { key: "chargeOT2", width: 30 },
        { key: "totalOT2", width: 30 },
        { key: "hoursOT3", width: 30 },
        { key: "perHoursOT3", width: 30 },
        { key: "chargeOT3", width: 30 },
        { key: "totalOT3", width: 30 },
        { key: "totalChargeOTAll", width: 30 },
        { key: "totalAllOT", width: 30 },
        { key: "chargeCost", width: 30 },
        { key: "chargeCompensated", width: 30 },
        { key: "shiftCost", width: 30 },
        { key: "chargeShiftCost", width: 30 },
        ...additionVendor.map(() => ({ width: 30 })),
        { key: "totalAddition", width: 30 },
        { key: "totalCharges", width: 30 },
        { key: "totalEarning", width: 30 },
        { key: "otherAddition", width: 30 },
        { key: "otherDeduction", width: 30 },
        { key: "totalOther", width: 30 },
        { key: "totalBilling", width: 30 },
    ];

    colWidths.forEach((col, index) => {
        worksheet.getColumn(index + 1).width = col.width;
    });

    const columnTotals = new Array(headerValues.length).fill(0);

    listBillingDetail && listBillingDetail
        .sort((a, b) => a.idSection - b.idSection)
        .forEach((item) => {
            const additionValues = additionVendor.map(addition => {
                const additionItem = item.listAdditions && item.listAdditions.find(a => a.idAddtions === addition.idAddition);
                return additionItem ? additionItem.value : 0;
            });

            const row = [
                item.firstname,
                item.lastname,
                item.sectionName,
                item.salary || 0,
                item.chargeSalary || 0,
                item.totalOT || 0,
                item.hoursOT1 || 0,
                item.perHoursOT1 || 0,
                item.chargeOT1 || 0,
                item.totalOT1,
                item.hoursOT1dot5 || 0,
                item.perHoursOT1dot5 || 0,
                item.chargeOT1dot5 || 0,
                item.totalOT1dot5,
                item.hoursOT2 || 0,
                item.perHoursOT2 || 0,
                item.chargeOT2 || 0,
                item.totalOT2,
                item.hoursOT3 || 0,
                item.perHoursOT3 || 0,
                item.chargeOT3 || 0,
                item.totalOT3,
                item.totalChargeOTAll,
                item.totalAllOT,
                item.chargeCost || 0,
                item.chargeCompensated || 0,
                item.shiftCost || 0,
                item.chargeShiftCost || 0,
                ...additionValues,
                item.totalAddition,
                item.totalCharges,
                item.totalEarning,
                item.otherAddition,
                item.otherDeduction,
                item.totalOther,
                item.totalBilling,
            ];

            row.forEach((value, index) => {
                if (typeof value === 'number') {
                    columnTotals[index] += value;
                }
            });

            const excelRow = worksheet.addRow(row);

            const contentStyle = {
                font: { size: 18, name: 'TH SarabunPSK' },
                alignment: {
                    vertical: 'middle',
                    horizontal: 'center'
                }
            };

            excelRow.eachCell((cell) => {
                cell.style = contentStyle;
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
            });
        });

    const summaryRowValues = ['รวมทั้งหมด', '', '', ...columnTotals.slice(3)]; // 'รวมทั้งหมด' for the first column and totals for the rest
    const summaryRow = worksheet.addRow(summaryRowValues);
    const summaryStyle = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK' },
        alignment: {
            vertical: 'middle',
            horizontal: 'center'
        },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFD700' }
        }
    };

    summaryRow.eachCell((cell) => {
        cell.style = summaryStyle;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Export ใบวางบิล.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
};
