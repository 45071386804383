import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    MenuItem,
    FormControl,
    Select,
    InputLabel,
    Switch,
    FormControlLabel,
    Box,
    Grid,
    Typography,
    styled,
    Stack,
    Divider,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAllCompanysByIdVendor } from '../../../../actions/company';
import { useDispatch, useSelector } from 'react-redux';
import ButtonBlue from '../../shared/general/ButtonBlue';
import DrawerCustom from '../../shared/general/Drawer';
import { useTranslation } from 'react-i18next';

const StyledRoot = styled("div")({
    width: 550,
    padding: 24,
    "& .topic": {
        fontWeight: "500",
        marginRight: "8px"
    },
    "& .detail": {
        fontSize: "18px"
    },
    "& .topic-textField": {
        fontWeight: "500",
        marginBottom: "8px"
    },
    "& .textField-bottom": {
        marginBottom: "16px"
    }
});

const BillingExtraDialog = ({ open, onClose, onSubmit, initialData }) => {
    const { t, i18n } = useTranslation();
    const [allCompanies, setAllCompanies] = useState([]);
    const [file, setFile] = useState(null);
    const dispatch = useDispatch();
    const { result: userProfile } = useSelector((state) => state.userProfile);

    // Validation schema using Yup
    const prohibitedWords = ['.', ' ', '-', '_', 'example']; // List your prohibited terms here

    const schema = yup.object().shape({
        name: yup
            .string()
            .required('Name is required')
            .test('no-prohibited-words', 'File name contains prohibited characters or words', value => {
                if (value) {
                    return !prohibitedWords.some(word => value.includes(word));
                }
                return true;
            }),
        amount: yup
            .number()
            .typeError('Amount must be a number')
            .required('Amount is required'),
        noteVander: yup.string().nullable(),
        idCompany: yup.string().required('Company selection is required'),
    });

    const {
        control,
        handleSubmit,
        setValue,
        reset,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            amount: '',
            noteVander: '',
            isApproved: null,
            isDraft: true,
            idCompany: '',
        },
    });

    useEffect(() => {
        if (initialData) {
            reset(initialData);
            setFile(initialData.filename)
        } else {
            reset({
                name: '',
                amount: '',
                noteVander: '',
                isApproved: null,
                isDraft: true,
                idCompany: '',
            });
        }
    }, [initialData, reset]);

    const fetchCompanies = async (idVendor) => {
        try {
            const response = await dispatch(getAllCompanysByIdVendor(idVendor));
            if (response && response.data) {
                setAllCompanies(response.data);
                if (!initialData) {
                    setValue('idCompany', response.data.length > 0 ? response.data[0].idCompany : '');
                }
            }
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    };

    useEffect(() => {
        if (userProfile) {
            fetchCompanies(userProfile.idVendor);
        }
    }, [userProfile]);

    const onSave = (data) => {
        if (!file) {
            console.error("No file selected");
            return;
        }

        const formData = new FormData();
        if (initialData && initialData.idBillingExtra) {
            formData.append("idBillingExtra", data.idBillingExtra);
        }
        formData.append("idCompany", data.idCompany);
        formData.append("noteVander", data.noteVander);
        formData.append("amount", data.amount);
        formData.append("name", data.name);
        formData.append("isDraft", data.isDraft ? 1 : 0);
        if ((file instanceof File)) {
            formData.append("file", file);
        }

        // Pass FormData to the onSubmit handler
        onSubmit(formData);
        reset();
        setFile(null)
    };


    // Handle Switch change
    const handleSwitchChange = (event) => {
        setValue('isDraft', !event.target.checked);
    };

    const isDraft = watch('isDraft'); // Get current isDraft value from watch

    // Handle file change for PDF
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile && selectedFile.type === 'application/pdf') {
            setFile(selectedFile);
        } else {
            alert('Please select a valid PDF file.');
        }
    };

    return (
        <DrawerCustom
            anchor="right"
            open={open}
            onClose={() => { setFile(null); onClose(); }}
            title={
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography>{initialData ? `${t("Edit_Data")}` + " Billing" : `${t("Create")}` + " Billing"}</Typography>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={!isDraft}
                                onChange={handleSwitchChange}
                                name="isDraft"
                                color="primary"
                            />
                        }
                        label={isDraft ? t("Draft") : t("Public")}
                        labelPlacement="start"
                    />
                </Box>
            }
        >
            <StyledRoot>
                <form>
                    {/* Name Field */}
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label={t("Name")}
                                fullWidth
                                margin="normal"
                                error={!!errors.name}
                                helperText={errors.name && errors.name.message}
                            />
                        )}
                    />
                    {/* Amount Field */}
                    <Controller
                        name="amount"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label={t("Amount")}
                                type="number"
                                fullWidth
                                margin="normal"
                                error={!!errors.amount}
                                helperText={errors.amount && errors.amount.message}
                            />
                        )}
                    />
                    {/* Note Vander Field */}
                    <Controller
                        name="noteVander"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label={t("Reason")}
                                multiline
                                rows={3}
                                fullWidth
                                margin="normal"
                            />
                        )}
                    />
                    {/* Company Dropdown */}
                    <FormControl fullWidth margin="normal" error={!!errors.idCompany}>
                        <InputLabel id="select-company-label" sx={{ backgroundColor: 'white' }}>{t("Company")}</InputLabel>
                        <Controller
                            name="idCompany"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    labelId="select-company-label"
                                    fullWidth
                                >
                                    {allCompanies.map((company) => (
                                        <MenuItem key={company.idCompany} value={company.idCompany}>
                                            {company.companyName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                        {errors.idCompany && <p style={{ color: 'red' }}>{errors.idCompany.message}</p>}
                    </FormControl>

                    {/* File Upload for PDF */}
                    <Grid item xs={12} pt={2}>
                        <div>
                            <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                            >
                                <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                                    {t("Upload")}
                                </Typography>
                            </Stack>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="inner-dropzone">
                            {file ? (
                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    <div>
                                        <i className="fas fa-file-pdf" style={{ color: 'red', fontSize: '24px' }}></i>
                                    </div>
                                    <span>{(file instanceof File) ? file.name : `${file}.pdf`}</span>
                                    <button
                                        type="button"
                                        onClick={() => setFile(null)}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            color: 'red',
                                            cursor: 'pointer',
                                            fontSize: '16px',
                                        }}
                                    >
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                            ) : (
                                <ButtonBlue variant="outlined" component="label">
                                    {t('เลือกไฟล์ PDF')}
                                    <input
                                        type="file"
                                        accept=".pdf"
                                        hidden
                                        onChange={handleFileChange}
                                    />
                                </ButtonBlue>
                            )}
                        </div>
                    </Grid>

                </form>
            </StyledRoot>
            <Divider style={{ margin: "16px 0" }} />
            <Box padding={2} textAlign="right">
                <Button onClick={() => { setFile(null); onClose(); }}>{t("Cancel")}</Button>
                <Button onClick={handleSubmit(onSave)} color="primary" variant="contained" style={{ marginLeft: 8 }}>
                    {t("Save")}
                </Button>
            </Box>
        </DrawerCustom>
    );
};

export default BillingExtraDialog;
