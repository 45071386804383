import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";

import Container from "@mui/material/Container";
import { Typography } from "@mui/material";

import DataTable from "./dataTable"
import DataTableExtra from "./dataTableExtra"
import dayjs from "dayjs";

const StyledRoot = styled("div")({
  minWidth: 350,
  width: "100%",
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 36,
});

const BillingPage = () => {
  const [monthYear, setMonthYear] = useState(dayjs());

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8 }}>
          รายการตรวจบิล
        </Typography>
        <DataTable monthYear={monthYear} setMonthYear={setMonthYear} />
        <Typography variant="h4" style={{ paddingTop: 16 }}>
          รายการบิลเพิ่มเติม
        </Typography>
        <DataTableExtra monthYear={monthYear} setMonthYear={setMonthYear} />
      </Container>
    </StyledRoot>
  );
};

export default BillingPage;
