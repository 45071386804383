import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import {
  Grid,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
  Popper,
  TextField,
  Box,
} from "@mui/material";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import DrawerCustom from "../../../../shared/general/Drawer";

import { updateUser } from "../../../../../../actions/user";
import {
  getPaymentType,
  getEmployeeProfile,
  employeeManagerUpdate,
} from "../../../../../../actions/employee";

const StyledRoot = styled("div")({
  width: 570,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledListPayment = styled(Box)({
  display: "flex",
});

const Charge = (props) => {
  const { formData, handleChange, handleChangeCheck } = props;
  const { t, i18n } = useTranslation();

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("Salary")}(%)</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="chargeSalary"
          value={formData.chargeSalary}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("OT")}(%)</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="chargeOt"
          value={formData.chargeOt}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("ChargeHire")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="chargeHire"
          value={formData.chargeHire}
          onChange={handleChange}
          fullWidth
        />
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="percent"
              control={
                <Checkbox
                  checked={formData.chargeHireType}
                  onChange={handleChangeCheck}
                  name="chargeHireType"
                />
              }
              label={`${t("Percent")}(%)`}
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("ChargeCompensation")}(%)</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="chargeCompensation"
          value={formData.chargeCompensation}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("ChargeShiftCost")}(%)</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="chargeShiftCost"
          value={formData.chargeShiftCost}
          onChange={handleChange}
          fullWidth
        />
      </Grid>

    </Grid>
  );
};

const Bank = (props) => {
  const { formData, handleChange } = props;
  const { t, i18n } = useTranslation();

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("BankName")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="bookBank"
          value={formData.bookBank}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("BookBankBranch")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="bookBankBranch"
          value={formData.bookBankBranch}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("BookBankID")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="bookBankId"
          value={formData.bookBankId}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <StyledDivider />
      <Grid item xs={12}>
        <StyledContentLabel color="text.secondary">
          สำหรับไฟล์ธนาคาร
        </StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>ชื่อพนักงาน</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="reportBank_Name"
          value={formData.reportBank_Name}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>ชื่อธนาคาร</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="reportBank_BankName"
          value={formData.reportBank_BankName}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("BranchCode")}</StyledContentLabel>
        <StyledContentLabel color="text.secondary">
          {t("BankFiles")}
        </StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="reportBank_BankId"
          value={formData.reportBank_BankId}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("BookBankID")}</StyledContentLabel>
        <StyledContentLabel color="text.secondary">
          {t("BankFiles")}
        </StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="reportBank_BookBankId"
          value={formData.reportBank_BookBankId}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>อ้่างอิง</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="reportBank_Ref"
          value={formData.reportBank_Ref}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

const Cost = (props) => {
  const { formData, handleChange, handleChangeCompanyCharge } = props;
  const { t, i18n } = useTranslation();
  const { result: vendorContractList } = useSelector(
    (state) => state.vendorContract
  );

  const [companyChargeList, setCompanyChargeList] = useState(null);

  useEffect(() => {
    if (vendorContractList) {
      var result = vendorContractList.map((company) => ({
        idCompanyCharge: company.idCompany,
        companyName: company.companyName,
      }));
      setCompanyChargeList(result);
    }
  }, [vendorContractList]);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={4}>
        <StyledContentLabel>Cost Center</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextFieldTheme
          name="costCenterCharge"
          value={formData.costCenterCharge}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <StyledContentLabel>Cost Element</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextFieldTheme
          name="costElementCharge"
          value={formData.costElementCharge}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <StyledContentLabel>IO</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextFieldTheme
          name="io"
          value={formData.io}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <StyledContentLabel>บริษัทเจ้าของงาน</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={8}>
        {companyChargeList && (
          <StyledAutocomplete
            fullWidth
            value={formData.companyCharge}
            name="companyCharge"
            options={companyChargeList}
            onChange={(event, newValue) => {
              console.log(newValue);
              handleChangeCompanyCharge(newValue === null ? "" : newValue);
            }}
            popupIcon={<i class="fa-light fa-chevron-down"></i>}
            getOptionLabel={(option) => option.companyName}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t("All_Companies")}
                name="companyCharge"
              />
            )}
            PopperComponent={StyledPopper}
            noOptionsText={t("No_Data")}
          />
        )}
      </Grid>
    </Grid>
  );
};

const CompanyCharge = (props) => {
  const { formData, handleChangeCompanyCharge } =
    props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: vendorContractList } = useSelector(
    (state) => state.vendorContract
  );

  const [paymentTypeList, setPaymentTypeList] = useState(null);
  const [companyChargeList, setCompanyChargeList] = useState(null);

  useEffect(() => {
    if (vendorContractList) {
      var result = vendorContractList.map((company) => ({
        idCompanyCharge: company.idCompany,
        companyName: company.companyName,
      }));
      setCompanyChargeList(result);
    }
  }, [vendorContractList]);

  useEffect(() => {
    async function fetch(params) {
      const result = await dispatch(getPaymentType());
      if (result) {
        setPaymentTypeList(result.data);
      } else {
      }
    }

    fetch();
  }, []);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>บริษัทเจ้าของงาน</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        {companyChargeList && (
          <StyledAutocomplete
            fullWidth
            value={formData.companyCharge}
            name="companyCharge"
            options={companyChargeList}
            onChange={(event, newValue) => {
              console.log(newValue);
              handleChangeCompanyCharge(newValue === null ? "" : newValue);
            }}
            popupIcon={<i class="fa-light fa-chevron-down"></i>}
            getOptionLabel={(option) => option.companyName}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t("All_Companies")}
                name="companyCharge"
              />
            )}
            PopperComponent={StyledPopper}
            noOptionsText={t("No_Data")}
          />
        )}
      </Grid>
    </Grid>
  );
};

const PaymentChange = (props) => {
  const { formData, handleChangeCompanyCharge, handleChangePaymentType } =
    props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: vendorContractList } = useSelector(
    (state) => state.vendorContract
  );

  const [paymentTypeList, setPaymentTypeList] = useState(null);
  const [companyChargeList, setCompanyChargeList] = useState(null);

  useEffect(() => {
    if (vendorContractList) {
      var result = vendorContractList.map((company) => ({
        idCompanyCharge: company.idCompany,
        companyName: company.companyName,
      }));
      setCompanyChargeList(result);
    }
  }, [vendorContractList]);

  useEffect(() => {
    async function fetch(params) {
      const result = await dispatch(getPaymentType());
      if (result) {
        setPaymentTypeList(result.data);
      } else {
      }
    }

    fetch();
  }, []);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>ประเภทการจ่าย/รอบการจ่าย</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        {paymentTypeList && (
          <StyledAutocomplete
            options={paymentTypeList}
            onChange={(event, newValue) => {
              console.log("newValue: ", newValue);
              handleChangePaymentType(newValue.idPaymentType);
            }}
            value={
              formData.idPaymentType
                ? paymentTypeList.find(
                  (x) => x.idPaymentType === formData.idPaymentType
                )
                : null
            }
            popupIcon={<i class="fa-light fa-chevron-down"></i>}
            getOptionLabel={(option) =>
              `${option.paymentTypeName} ${option.paymentRound
                ? `- (${t("Pay")}${option.paymentRound})`
                : ""
              }`
            }
            renderOption={(props, option) => (
              <StyledListPayment component="li" {...props}>
                <Typography>
                  {option.paymentTypeName}
                  <Typography component="span" color="text.secondary">{`${option.paymentRound
                    ? ` - (${t("Pay")}${option.paymentRound})`
                    : ""
                    }`}</Typography>
                </Typography>
              </StyledListPayment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t("PaymentType")}
                InputProps={{ ...params.InputProps, endAdornment: null }}
              />
            )}
            PopperComponent={StyledPopper}
            noOptionsText={t("No_Data")}
          />
        )}
      </Grid>
    </Grid>
  );
};

const DialogEdit = (props) => {
  const { open, mode, handleCloseDialog } = props;
  const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: vendorContractList } = useSelector(
    (state) => state.vendorContract
  );
  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (open) {
      if (mode === "bank") {
        setFormData({
          idEmp: employeeProfile.idEmp,
          bookBank: employeeProfile.bookBank,
          bookBankBranch: employeeProfile.bookBankBranch,
          bookBankId: employeeProfile.bookBankId,
          bookBankBranchId: employeeProfile.bookBankBranchId,
          reportBank_Name: employeeProfile.reportBank_Name,
          reportBank_Ref: employeeProfile.reportBank_Ref,
          reportBank_BookBankId: employeeProfile.reportBank_BookBankId,
          reportBank_BankName: employeeProfile.reportBank_BankName,
          reportBank_BankId: employeeProfile.reportBank_BankId,
        });
      } else if (mode === "charge") {
        setFormData({
          idEmp: employeeProfile.idEmp,
          chargeCompensation: employeeProfile.chargeCompensation
            ? employeeProfile.chargeCompensation * 100
            : null,
          chargeHire: employeeProfile.chargeHire
            ? employeeProfile.chargeHire <= 1
              ? employeeProfile.chargeHire * 100
              : employeeProfile.chargeHire
            : null,
          chargeSalary: employeeProfile.chargeSalary
            ? employeeProfile.chargeSalary * 100
            : null,
          chargeOt: employeeProfile.chargeOt
            ? employeeProfile.chargeOt * 100
            : null,
          chargeShiftCost: employeeProfile.chargeShiftCost
            ? employeeProfile.chargeShiftCost * 100
            : null,
          chargeHireType:
            employeeProfile.chargeHire && employeeProfile.chargeHire <= 1
              ? true
              : false,

        });
      } else if (mode === "cost") {
        setFormData({
          idEmp: employeeProfile.idEmp,
          costCenterCharge: employeeProfile.costCenterCharge,
          costElementCharge: employeeProfile.costElementCharge,
          io: employeeProfile.io,
        });
      } else if (mode === "companyCharge") {
        let foundCompanyCharge = vendorContractList.find(
          (x) => x.idCompany === employeeProfile.idCompanyCharge
        );
        setFormData({
          idEmp: employeeProfile.idEmp,
          companyCharge: employeeProfile.idCompanyCharge
            ? foundCompanyCharge
            : null,
        });
      } else if (mode === "paymentChange") {
        setFormData({
          idEmp: employeeProfile.idEmp,
          idPaymentType: employeeProfile.idPaymentType,
        });
      }
    }
  }, [open]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeCheck = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSubmit = async () => {
    console.log("mode: ", mode)
    if (mode === "bank" || mode === "cost" || mode === "paymentChange") {
      formData.UpdateBy = userProfile.idUsers;
      const result = await dispatch(updateUser(formData));
      if (result) {
        handleCloseDialog();
        dispatch(getEmployeeProfile(employeeProfile.idEmp));
      }
    } else if (mode === "charge") {
      formData.UpdateBy = userProfile.idUsers;
      formData.chargeCompensation = formData.chargeCompensation
        ? formData.chargeCompensation / 100
        : null;
      formData.chargeShiftCost = formData.chargeShiftCost
        ? formData.chargeShiftCost / 100
        : null;
      formData.chargeHire = formData.chargeHire
        ? formData.chargeHireType
          ? formData.chargeHire / 100
          : parseInt(formData.chargeHire)
        : null;
      formData.chargeSalary = formData.chargeSalary
        ? formData.chargeSalary / 100
        : null;
      formData.chargeOt = formData.chargeOt ? formData.chargeOt / 100 : null;

      delete formData["chargeHireType"];
      const result = await dispatch(updateUser(formData));
      if (result) {
        handleCloseDialog();
        dispatch(getEmployeeProfile(employeeProfile.idEmp));
      }
    } else if (mode === "companyCharge") {

      formData.UpdateBy = userProfile.idUsers;
      formData.idCompanyCharge = formData.companyCharge.idCompanyCharge
      delete formData["companyCharge"];

      console.log("formData: ", formData);

      const result = await dispatch(updateUser(formData));
      if (result) {
        handleCloseDialog();
        dispatch(getEmployeeProfile(employeeProfile.idEmp));
      }
    }
  };

  const handleSubmitChangeCompanyCharge = async () => {
    // let dataForm = {
    //   idEmp: formData.idEmp,
    //   idEmployeePosition: formData.idEmployeePosition,
    //   idCompanyCharge: formData.companyCharge
    //     ? formData.companyCharge.idCompanyCharge
    //     : null,
    //   UpdateBy: userProfile.idUsers,
    // };
    // const result = await dispatch(employeeManagerUpdate(dataForm));
    // if (result) {
    //   handleCloseDialog();
    //   dispatch(getEmployeeProfile(employeeProfile.idEmp));
    // }
  };

  const handleChangeCompanyCharge = (value) => {
    setFormData({
      ...formData,
      ["companyCharge"]: value,
    });
  };

  const handleChangePaymentType = (value) => {
    setFormData({
      ...formData,
      ["idPaymentType"]: value,
    });
  };

  return (
    <DrawerCustom
      title={
        mode === "bank"
          ? `${t("EditBankInfo")}`
          : mode === "cost"
            ? `${t("EditCostCenter")}`
            : mode === "companyCharge"
              ? "แก้ไขบริษัทเจ้าของงาน(เรียกเก็บ)"
              : mode === "paymentChange"
                ? `ประเภทการจ่าย/รอบการจ่าย`
                : `${t("EditOperation")}`
      }
      anchor={"right"}
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        {mode === "bank" && (
          <Bank formData={formData} handleChange={handleChange} />
        )}
        {mode === "charge" && (
          <Charge
            formData={formData}
            handleChange={handleChange}
            handleChangeCheck={handleChangeCheck}
          />
        )}
        {mode === "cost" && (
          <Cost
            formData={formData}
            handleChange={handleChange}
            handleChangeCompanyCharge={handleChangeCompanyCharge}
          />
        )}
        {mode === "companyCharge" && (
          <CompanyCharge
            formData={formData}
            handleChangeCompanyCharge={handleChangeCompanyCharge}
            handleChangePaymentType={handleChangePaymentType}
          />
        )}
        {mode === "paymentChange" && (
          <PaymentChange
            formData={formData}
            handleChangePaymentType={handleChangePaymentType}
          />
        )}
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleCloseDialog}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue
            variant="contained"
            onClick={handleSubmit}
          >
            {t("Save")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
