import React, {
  useEffect,
  useState,
  useCallback,
} from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import DataGrid, {
  Column,
  GroupItem,
  Paging,
  Pager,
  Summary,
  TotalItem,
  Export,
} from "devextreme-react/data-grid";

import utils from "../../../../../utils";

const StyledWrapTable = styled("div")(({ isSent }) => ({
  "& .dx-header-row ": {},
  "& .dx-toolbar": {
    backgroundColor: "transparent",
    "& .dx-toolbar-after": {
      marginRight: isSent === "false" ? "100px" : "0px",
    },
  },
}));

const DataTable = (props) => {
  const { onClickEdit, isSent, isDisplaySection, listBillingEmployees, setListBillingEmployees } = props;
  const { result: billingEmployees } = useSelector(
    (state) => state.billingEmployees
  );
  const { result: additionVendor } = useSelector(
    (state) => state.additionVendor
  );
  const [listAdditions, setListAdditions] = useState([]);

  useEffect(() => {
    if (
      !isDisplaySection &&
      billingEmployees &&
      billingEmployees.listBillingDetail &&
      billingEmployees.listBillingDetail.length > 0
    ) {
      let tempListAdditions = [];
      setListBillingEmployees([
        ...billingEmployees.listBillingDetail.map((payrun) => {
          let valueReturn = { ...payrun };
          if (
            payrun.listAdditions &&
            payrun.listAdditions.length > 0
          ) {
            payrun.listAdditions.map((addition) => {
              if (tempListAdditions.findIndex(add => add.idAddition === addition.idAddition) < 0) {
                let temp = {
                  idAddition: addition.idAddition,
                  name: addition.additionName,
                  code: addition.additionCode,
                  idBillingDetail: payrun.idBillingDetail
                };
                tempListAdditions.push(temp);
              }

              valueReturn[`valueAddition${addition.idAddition}`] = addition.value;
              // valueReturn[`totalAddition`] =
              //   (valueReturn[`totalAddition`] || 0) + addition.value;
              // valueReturn[`totalAddition`] = 0
            });
          }
          // valueReturn[`totalAddition`] = 0
          return {
            ...valueReturn,
          };
        }),
      ]);
      setListAdditions([...tempListAdditions]);
    } else if (
      isDisplaySection &&
      billingEmployees &&
      billingEmployees.listBillingDetailSection &&
      billingEmployees.listBillingDetailSection.length > 0
    ) {
      let tempListAdditions = [];
      setListBillingEmployees([
        ...billingEmployees.listBillingDetailSection.map((payrun) => {
          let valueReturn = { ...payrun };

          if (
            payrun.listAdditions &&
            payrun.listAdditions.length > 0
          ) {
            payrun.listAdditions.map((addition) => {
              if (tempListAdditions.findIndex(add => add.idAddition === addition.idAddition) < 0) {
                let temp = {
                  idAddition: addition.idAddition,
                  name: addition.additionName,
                  code: addition.additionCode,
                  idBillingDetail: payrun.idBillingDetail
                };
                tempListAdditions.push(temp);
              }
              valueReturn[`valueAddition${addition.idAddition}`] = addition.value;
              // valueReturn[`totalAddition`] =
              //   (valueReturn[`totalAddition`] || 0) + addition.value;
              // valueReturn[`totalAddition`] = 0
            });
          }
          // valueReturn[`totalAddition`] = 0
          return {
            ...valueReturn,
          };
        }),
      ]);
      setListAdditions([...tempListAdditions]);
    } else {
      setListBillingEmployees([]);
    }
  }, [
    billingEmployees,
    (billingEmployees && billingEmployees.listBillingDetail),
    (billingEmployees && billingEmployees.listBillingDetailSection),
    isDisplaySection,
    additionVendor
  ]);

  const fullNameColumn_calculateCellValue = (rowData) => {
    return rowData.firstname + " " + rowData.lastname;
  };

  const valueOfNumber = (data, key) => {
    return data[key] || "";
  };

  const valueOfText = (data, key) => {
    return data[key] || "-";
  };

  const customizeNumber = (data) => {
    return `${utils.numberWithCommas(data.value || 0)} บาท`;
  };

  return (
    <StyledWrapTable isSent={isSent ? "true" : "false"}>
      {(listBillingEmployees && !isDisplaySection) && (
        <DataGrid
          id="gridBillingDetail"
          dataSource={listBillingEmployees || []}
          showBorders={true}
          columnWidth={200}
          keyExpr="idBillingDetail"
          onRowClick={(data) => {
            if (!data.summaryCells && !isSent) {
              onClickEdit(data.data);
            }
          }}
        >
          <Column
            caption="ชื่อ-สกุล"
            dataType="string"
            width={250}
            calculateCellValue={fullNameColumn_calculateCellValue}
          />
          <Column
            dataField="positionsName"
            width={300}
            caption="ตำแหน่ง"
            calculateCellValue={(data) => valueOfText(data, `positionsName`)}
          />

          <Column
            dataField="sectionName"
            width={300}
            caption="แผนก"
            calculateCellValue={(data) => valueOfText(data, `sectionName`)}
          />

          <Column
            dataField="departmentName"
            width={300}
            caption="หน่วยงาน"
            calculateCellValue={(data) => valueOfText(data, `departmentName`)}
          />

          <Column dataField="salary" caption="ค่าแรง" format="###,###,##0.00" />

          <Column
            dataField="chargeSalary"
            caption="Charge ค่าแรง"
            format="###,###,##0.00"
          />

          <Column
            dataField="totalOT"
            caption="ค่าล่วงเวลา"
            format="###,###,##0.00"
          />
          <Column
            dataField="hoursOT1"
            caption="OTx1 (ชม.)"
            dataType="string"
            calculateCellValue={(data) => valueOfNumber(data, `hoursOT1`)}
          />
          <Column
            dataField="perHoursOT1"
            caption="OTx1 (บาท/ชม.)"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `perHoursOT1`)}
          />
          <Column
            dataField="chargeOT1"
            caption="OTx1 (Charge)"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `chargeOT1`)}
          />
          <Column
            dataField="totalOT1"
            caption="OTx1 (เรียกเก็บ)"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `totalOT1`)}
          />
          <Column
            dataField="hoursOT1dot5"
            caption="OTx1.5 (ชม.)"
            dataType="string"
            calculateCellValue={(data) => valueOfNumber(data, `hoursOT1dot5`)}
          />
          <Column
            dataField="perHoursOT1dot5"
            caption="OTx1.5 (บาท/ชม.)"
            format="###,###,##0.00"
            calculateCellValue={(data) =>
              valueOfNumber(data, `perHoursOT1dot5`)
            }
          />
          <Column
            dataField="chargeOT1dot5"
            caption="OTx1.5 (Charge)"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `chargeOT1dot5`)}
          />
          <Column
            dataField="totalOT1dot5"
            caption="OTx1.5 (เรียกเก็บ)"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `totalOT1dot5`)}
          />
          <Column
            dataField="hoursOT2"
            caption="OTx2 (ชม.)"
            dataType="string"
            calculateCellValue={(data) => valueOfNumber(data, `hoursOT2`)}
          />
          <Column
            dataField="perHoursOT2"
            caption="OTx2 (บาท/ชม.)"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `perHoursOT2`)}
          />
          <Column
            dataField="chargeOT2"
            caption="OTx2 (Charge)"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `chargeOT2`)}
          />
          <Column
            dataField="totalOT2"
            caption="OTx2 (เรียกเก็บ)"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `totalOT2`)}
          />
          <Column
            dataField="hoursOT3"
            caption="OTx3 (ชม.)"
            dataType="string"
            calculateCellValue={(data) => valueOfNumber(data, `hoursOT3`)}
          />
          <Column
            dataField="perHoursOT3"
            caption="OTx3 (บาท/ชม.)"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `perHoursOT3`)}
          />
          <Column
            dataField="chargeOT3"
            caption="OTx3 (Charge)"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `chargeOT3`)}
          />
          <Column
            dataField="totalOT3"
            caption="OTx3 (เรียกเก็บ)"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `totalOT3`)}
          />
          <Column
            dataField="totalChargeOTAll"
            caption="รวม Charge OT"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `totalChargeOTAll`)}
          />
          <Column
            dataField="totalAllOT"
            caption="รวม OT (เรียกเก็บ)"
            format="###,###,##0.00"
          />
          <Column
            dataField="chargeCost"
            caption="Charge เหมา"
            format="###,###,##0.00"
          />
          <Column
            dataField="chargeCompensated"
            caption="Charge ค่าชดเชย"
            format="###,###,##0.00"
          />
          <Column
            dataField="shiftCost"
            caption="ค่ากะ"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `totalOT3`)}
          />
          <Column
            dataField="chargeShiftCost"
            caption="Charge ค่ากะ"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `totalOT3`)}
          />
          {/* {additionVendor &&
            additionVendor.length > 0 &&
            additionVendor.map((addition) => [
              <Column
                dataField={`valueAddition${addition.idAddition}`}
                caption={`${addition.name}(${addition.code})`}
                format="###,###,##0.00"
                width="auto"
                minWidth={200}
                calculateCellValue={(data) =>
                  valueOfNumber(data, `valueAddition${addition.idAddition}`)
                }
              />
            ])} */}

          {(listAdditions && listAdditions.length > 0) &&
            listAdditions.map(addition => (
              [
                <Column
                  dataField={`valueAddition${addition.idAddition}`}
                  caption={`${addition.name}(${addition.code})`}
                  format="###,###,##0.00"
                  width="auto"
                  minWidth={200}
                  calculateCellValue={(data) => valueOfNumber(data, `valueAddition${addition.idAddition}`)}
                />
              ]
            ))
          }
          <Column
            dataField="totalAddition"
            caption="รวม Addition"
            format="###,###,##0.00"
          />
          <Column
            dataField="totalCharges"
            caption="รวม Charge"
            format="###,###,##0.00"
          />
          <Column
            dataField="totalEarning"
            caption="รวมรายรับ"
            format="###,###,##0.00"
          />
          <Column
            dataField="otherAddition"
            caption="เพิ่มค่าอื่นๆ"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `otherAddition`)}
          />
          <Column
            dataField="otherDeduction"
            caption="หักค่าอื่นๆ"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `otherDeduction`)}
          />
          <Column
            dataField="totalOther"
            caption="รวมค่าอื่นๆ"
            format="###,###,##0.00"
          />
          <Column
            dataField="totalBilling"
            caption="เรียกเก็บสุทธิ"
            format="###,###,##0.00"
          />
          <Summary>
            <TotalItem
              column="salary"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="chargeSalary"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="totalOT"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="chargeOT1"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="totalOT1"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="chargeOT1dot5"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="totalOT1dot5"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="chargeOT2"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="totalOT2"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="chargeOT3"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="totalOT3"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="totalChargeOTAll"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="totalAllOT"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="chargeCost"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="chargeCompensated"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="shiftCost"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="chargeShiftCost"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            {additionVendor &&
              additionVendor.length > 0 &&
              additionVendor.map((addition) => [
                <TotalItem
                  column={`valueAddition${addition.idAddition}`}
                  summaryType="sum"
                  customizeText={customizeNumber}
                />
              ])}
            <TotalItem
              column="totalAddition"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="totalCharges"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="totalEarning"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="otherAddition"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="otherDeduction"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="totalOther"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="totalBilling"
              summaryType="sum"
              customizeText={customizeNumber}
            />
          </Summary>
          <Paging defaultPageSize={10} />
          <Pager
            visible={
              listBillingEmployees && listBillingEmployees.length > 0
                ? true
                : false
            }
            showPageSizeSelector={true}
            allowedPageSizes={[
              10,
              ...(listBillingEmployees && listBillingEmployees.length > 10
                ? [20]
                : []),
              ...(listBillingEmployees && listBillingEmployees.length > 20
                ? [30]
                : []),
            ]}
          />
        </DataGrid>
      )}
      {(listBillingEmployees && isDisplaySection) && (
        <DataGrid
          id="gridBillingDetail"
          dataSource={listBillingEmployees || []}
          showBorders={true}
          columnWidth={200}
          keyExpr="idBillingDetail"
        >
          <Column
            caption="ชื่อ-สกุล"
            dataType="string"
            width={250}
            calculateCellValue={fullNameColumn_calculateCellValue}
          />
          <Column
            dataField="positionsName"
            width={300}
            caption="ตำแหน่ง"
            calculateCellValue={(data) => valueOfText(data, `positionsName`)}
          />

          <Column
            dataField="sectionName"
            width={300}
            caption="แผนก"
            calculateCellValue={(data) => valueOfText(data, `sectionName`)}
            groupIndex={0}
          />

          <Column
            dataField="departmentName"
            width={300}
            caption="หน่วยงาน"
            calculateCellValue={(data) => valueOfText(data, `departmentName`)}
          />

          <Column dataField="salary" caption="ค่าแรง" format="###,###,##0.00" />

          <Column
            dataField="chargeSalary"
            caption="Charge ค่าแรง"
            format="###,###,##0.00"
          />

          <Column
            dataField="totalOT"
            caption="ค่าล่วงเวลา"
            format="###,###,##0.00"
          />
          <Column
            dataField="hoursOT1"
            caption="OTx1 (ชม.)"
            dataType="string"
            calculateCellValue={(data) => valueOfNumber(data, `hoursOT1`)}
          />
          <Column
            dataField="perHoursOT1"
            caption="OTx1 (บาท/ชม.)"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `perHoursOT1`)}
          />
          <Column
            dataField="chargeOT1"
            caption="OTx1 (Charge)"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `chargeOT1`)}
          />
          <Column
            dataField="totalOT1"
            caption="OTx1 (เรียกเก็บ)"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `totalOT1`)}
          />
          <Column
            dataField="hoursOT1dot5"
            caption="OTx1.5 (ชม.)"
            dataType="string"
            calculateCellValue={(data) => valueOfNumber(data, `hoursOT1dot5`)}
          />
          <Column
            dataField="perHoursOT1dot5"
            caption="OTx1.5 (บาท/ชม.)"
            format="###,###,##0.00"
            calculateCellValue={(data) =>
              valueOfNumber(data, `perHoursOT1dot5`)
            }
          />
          <Column
            dataField="chargeOT1dot5"
            caption="OTx1.5 (Charge)"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `chargeOT1dot5`)}
          />
          <Column
            dataField="totalOT1dot5"
            caption="OTx1.5 (เรียกเก็บ)"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `totalOT1dot5`)}
          />
          <Column
            dataField="hoursOT2"
            caption="OTx2 (ชม.)"
            dataType="string"
            calculateCellValue={(data) => valueOfNumber(data, `hoursOT2`)}
          />
          <Column
            dataField="perHoursOT2"
            caption="OTx2 (บาท/ชม.)"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `perHoursOT2`)}
          />
          <Column
            dataField="chargeOT2"
            caption="OTx2 (Charge)"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `chargeOT2`)}
          />
          <Column
            dataField="totalOT2"
            caption="OTx2 (เรียกเก็บ)"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `totalOT2`)}
          />
          <Column
            dataField="hoursOT3"
            caption="OTx3 (ชม.)"
            dataType="string"
            calculateCellValue={(data) => valueOfNumber(data, `hoursOT3`)}
          />
          <Column
            dataField="perHoursOT3"
            caption="OTx3 (บาท/ชม.)"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `perHoursOT3`)}
          />
          <Column
            dataField="chargeOT3"
            caption="OTx3 (Charge)"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `chargeOT3`)}
          />
          <Column
            dataField="totalOT3"
            caption="OTx3 (เรียกเก็บ)"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `totalOT3`)}
          />
          <Column
            dataField="totalChargeOTAll"
            caption="รวม Charge OT"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `totalChargeOTAll`)}
          />
          <Column
            dataField="totalAllOT"
            caption="รวม OT (เรียกเก็บ)"
            format="###,###,##0.00"
          />
          <Column
            dataField="chargeCost"
            caption="Charge เหมา"
            format="###,###,##0.00"
          />
          <Column
            dataField="chargeCompensated"
            caption="Charge ค่าชดเชย"
            format="###,###,##0.00"
          />
          <Column
            dataField="shiftCost"
            caption="ค่ากะ"
            format="###,###,##0.00"
          />
          <Column
            dataField="chargeShiftCost"
            caption="Charge ค่ากะ"
            format="###,###,##0.00"
          />
          {additionVendor &&
            additionVendor.length > 0 &&
            additionVendor.map((addition) => [
              <Column
                dataField={`valueAddition${addition.idAddition}`}
                caption={`${addition.name}(${addition.code})`}
                format="###,###,##0.00"
                width="auto"
                minWidth={200}
                calculateCellValue={(data) =>
                  valueOfNumber(data, `valueAddition${addition.idAddition}`)
                }
              />
            ])}
          <Column
            dataField="totalAddition"
            caption="รวม Addition"
            format="###,###,##0.00"
          />
          <Column
            dataField="totalCharges"
            caption="รวม Charge"
            format="###,###,##0.00"
          />
          <Column
            dataField="totalEarning"
            caption="รวมรายรับ"
            format="###,###,##0.00"
          />
          <Column
            dataField="otherAddition"
            caption="เพิ่มค่าอื่นๆ"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `otherAddition`)}
          />
          <Column
            dataField="otherDeduction"
            caption="หักค่าอื่นๆ"
            format="###,###,##0.00"
            calculateCellValue={(data) => valueOfNumber(data, `otherDeduction`)}
          />
          <Column
            dataField="totalOther"
            caption="รวมค่าอื่นๆ"
            format="###,###,##0.00"
          />
          <Column
            dataField="totalBilling"
            caption="เรียกเก็บสุทธิ"
            format="###,###,##0.00"
          />
          <Summary>
            <GroupItem
              column="salary"
              summaryType="sum"
              valueFormat="###,###,##0.00"
              displayFormat="{0}"
              showInGroupFooter={true}
              alignByColumn={true}
            />
            <GroupItem
              column="chargeSalary"
              summaryType="sum"
              valueFormat="###,###,##0.00"
              displayFormat="{0}"
              showInGroupFooter={true}
              alignByColumn={true}
            />
            <GroupItem
              column="totalOT"
              summaryType="sum"
              valueFormat="###,###,##0.00"
              displayFormat="{0}"
              showInGroupFooter={true}
              alignByColumn={true}
            />
            <GroupItem
              column="chargeOT1"
              summaryType="sum"
              valueFormat="###,###,##0.00"
              displayFormat="{0}"
              showInGroupFooter={true}
              alignByColumn={true}
            />
            <GroupItem
              column="totalOT1"
              summaryType="sum"
              valueFormat="###,###,##0.00"
              displayFormat="{0}"
              showInGroupFooter={true}
              alignByColumn={true}
            />
            <GroupItem
              column="chargeOT1dot5"
              summaryType="sum"
              valueFormat="###,###,##0.00"
              displayFormat="{0}"
              showInGroupFooter={true}
              alignByColumn={true}
            />
            <GroupItem
              column="totalOT1dot5"
              summaryType="sum"
              valueFormat="###,###,##0.00"
              displayFormat="{0}"
              showInGroupFooter={true}
              alignByColumn={true}
            />
            <GroupItem
              column="chargeOT2"
              summaryType="sum"
              valueFormat="###,###,##0.00"
              displayFormat="{0}"
              showInGroupFooter={true}
              alignByColumn={true}
            />
            <GroupItem
              column="totalOT2"
              summaryType="sum"
              valueFormat="###,###,##0.00"
              displayFormat="{0}"
              showInGroupFooter={true}
              alignByColumn={true}
            />
            <GroupItem
              column="chargeOT3"
              summaryType="sum"
              valueFormat="###,###,##0.00"
              displayFormat="{0}"
              showInGroupFooter={true}
              alignByColumn={true}
            />
            <GroupItem
              column="totalOT3"
              summaryType="sum"
              valueFormat="###,###,##0.00"
              displayFormat="{0}"
              showInGroupFooter={true}
              alignByColumn={true}
            />
            <GroupItem
              column="totalChargeOTAll"
              summaryType="sum"
              valueFormat="###,###,##0.00"
              displayFormat="{0}"
              showInGroupFooter={true}
              alignByColumn={true}
            />
            <GroupItem
              column="totalAllOT"
              summaryType="sum"
              valueFormat="###,###,##0.00"
              displayFormat="{0}"
              showInGroupFooter={true}
              alignByColumn={true}
            />
            <GroupItem
              column="chargeCost"
              summaryType="sum"
              valueFormat="###,###,##0.00"
              displayFormat="{0}"
              showInGroupFooter={true}
              alignByColumn={true}
            />
            <GroupItem
              column="shiftCost"
              summaryType="sum"
              valueFormat="###,###,##0.00"
              displayFormat="{0}"
              showInGroupFooter={true}
              alignByColumn={true}
            />
            <GroupItem
              column="chargeShiftCost"
              summaryType="sum"
              valueFormat="###,###,##0.00"
              displayFormat="{0}"
              showInGroupFooter={true}
              alignByColumn={true}
            />
            {additionVendor &&
              additionVendor.length > 0 &&
              additionVendor.map((addition) => [
                <GroupItem
                  column={`valueAddition${addition.idAddition}`}
                  summaryType="sum"
                  valueFormat="###,###,##0.00"
                  displayFormat="{0}"
                  showInGroupFooter={true}
                  alignByColumn={true}
                />
              ])}
            <GroupItem
              column="totalAddition"
              summaryType="sum"
              valueFormat="###,###,##0.00"
              displayFormat="{0}"
              showInGroupFooter={true}
              alignByColumn={true}
            />
            <GroupItem
              column="totalCharges"
              summaryType="sum"
              valueFormat="###,###,##0.00"
              displayFormat="{0}"
              showInGroupFooter={true}
              alignByColumn={true}
            />
            <GroupItem
              column="totalEarning"
              summaryType="sum"
              valueFormat="###,###,##0.00"
              displayFormat="{0}"
              showInGroupFooter={true}
              alignByColumn={true}
            />
            <GroupItem
              column="otherAddition"
              summaryType="sum"
              valueFormat="###,###,##0.00"
              displayFormat="{0}"
              showInGroupFooter={true}
              alignByColumn={true}
            />
            <GroupItem
              column="otherDeduction"
              summaryType="sum"
              valueFormat="###,###,##0.00"
              displayFormat="{0}"
              showInGroupFooter={true}
              alignByColumn={true}
            />
            <GroupItem
              column="totalOther"
              summaryType="sum"
              valueFormat="###,###,##0.00"
              displayFormat="{0}"
              showInGroupFooter={true}
              alignByColumn={true}
            />
            <GroupItem
              column="totalBilling"
              summaryType="sum"
              valueFormat="###,###,##0.00"
              displayFormat="{0}"
              showInGroupFooter={true}
              alignByColumn={true}
            />

            <TotalItem
              column="salary"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="chargeSalary"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="totalOT"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="chargeOT1"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="totalOT1"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="chargeOT1dot5"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="totalOT1dot5"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="chargeOT2"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="totalOT2"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="chargeOT3"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="totalOT3"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="totalChargeOTAll"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="totalAllOT"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="chargeCost"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="chargeCompensated"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="shiftCost"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="chargeShiftCost"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            {additionVendor &&
              additionVendor.length > 0 &&
              additionVendor.map((addition) => [
                <TotalItem
                  column={`valueAddition${addition.idAddition}`}
                  summaryType="sum"
                  customizeText={customizeNumber}
                />
              ])}
            <TotalItem
              column="totalAddition"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="totalCharges"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="totalEarning"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="otherAddition"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="otherDeduction"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="totalOther"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="totalBilling"
              summaryType="sum"
              customizeText={customizeNumber}
            />
          </Summary>
          <Paging defaultPageSize={10} />
          <Pager
            visible={
              listBillingEmployees && listBillingEmployees.length > 0
                ? true
                : false
            }
            showPageSizeSelector={true}
            allowedPageSizes={[
              10,
              ...(listBillingEmployees && listBillingEmployees.length > 10
                ? [20]
                : []),
              ...(listBillingEmployees && listBillingEmployees.length > 20
                ? [30]
                : []),
            ]}
          />
        </DataGrid>
      )}
    </StyledWrapTable>
  );
};
export default DataTable;
