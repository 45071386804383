import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Grid,
  styled,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Radio,
  FormControlLabel,
  Box,
  RadioGroup,
  IconButton,
} from "@mui/material";
import Dropzone, { useDropzone } from "react-dropzone";

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AdapterDateFns from "@tarzui/date-fns-be";

import AddPhotoIcon from "../../assets/add.png";
import GalleryIcon from "../../assets/gallery.png";
import ImageFileIcon from "../../assets/imagefile.png";
import DeleteIcon from "@mui/icons-material/Delete";

import utils from "../../../../utils";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import TimePicker from "../../shared/general/TimePicker";
import StatusRequest from "../../shared/general/stausRequest";

import { allHoliday } from "../../../../actions/holiday";
import { addRequestTime } from "../../../../actions/requestTime";
import { getLeaveQuota, getLeaveRequest } from "../../../../actions/employee";
import { getLeaveAvailable, getPatternForLeave } from "../../../../actions/leave";

import DialogConfirmLeave from "./dialogConfirmLeave";
import { getWorkingHoursByIdEmployees } from "../../../../actions/attendance";
import AlertResponse from "../../shared/general/AlertResponse";

const StyledRoot = styled("div")({
  width: 350,
  padding: 24,
  "& .wrap-status": {
    marginTop: 4,
    display: "flex",
    alignItems: "center",
    "& .MuiBox-root": {
      marginRight: 8,
    },
  },
  "& .wrap-footer-btn": {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 16,
  },
});

const StyledDropzone = styled(Box)({
  width: "100%",
  marginBottom: 12,
  backgroundColor: "#f9f9fb",
  "& .dropzone-leave": {
    // height: 98,
    borderRadius: 4,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },

  "& .inner-dropzone": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    // height: 90,
    outline: "none",
    display: "flex",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "#efefef",
    flexDirection: "column",
    padding: "4px 0",
    "& img": {
      width: 72,
      height: 72,
    },
  },
  "& .inner-dropzone:hover .placeholder": {
    zIndex: 9,
  },
});

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiInputBase-input": {
      padding: "13.5px 14.5px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
  "& .MuiInputBase-fullWidth": {
    padding: "13.5px 14.5px",
  },
});

const StyledFormControl = styled(FormControl)({
  marginBottom: 8,
  "& .MuiInputLabel-root ": {
    color: "#a3a3a3",
  },
  "& .MuiFormLabel-filled, .Mui-focused": {
    color: "#212b36",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& .MuiSelect-select": {
    padding: "13.5px 14.5px",
  },
  "& .Mui-checked": {
    color: "#007afe !important",
  },
});

const StyledWrapTimePicker = styled("div")({
  width: "100%",
  "&.hours": {
    marginRight: 16,
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& .MuiSelect-select": {
    padding: "13.5px 14.5px",
  },
});

const DialogNewLeave = (props) => {
  const { handleClose, handleChangeAlertType, handleOpenAlert, drawerConfig, leaveRoundConfig } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const today = dayjs().toDate();
  dayjs.extend(isBetween);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(customParseFormat);
  // const { result: leaveTypeList } = useSelector((state) => state.leaveType);

  const { result: leavePatternList } = useSelector((state) => state.leavePattern);
  const { result: leaveAvailable } = useSelector((state) => state.leaveAvailable);
  // const { result: holiday } = useSelector((state) => state.holiday);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  
  const [schedule, setSchedule] = useState([]);

  const [requestLeave, setRequestLeave] = useState({

    idLeaveType: 0,
    leaveType: null,

    startDate: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0
    ),
    endDate: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0
    ),
    description: "",
    leaveDayType: "fullday",
    startDateHour: 0,
    startDateMinute: 0,
    endDateHour: 0,
    endDateMinute: 0,
    used: 0,
    quota: 0,
    balance: 0,
    remaining: null,
  });

  const [openConfirm, setOpenConfirm] = useState(false);
  const [fileSelected, setFileSelected] = useState([]);

  const [errorConfig, setErrorConfig] = useState({
    isInvalidStartEnd: false,
    isOverResetEndOfMonth: false,
    isNotLeaveRoundEnd: false,
    isOutOfWorkingTime: false,
    isOverRemaining: false,
  });

  useEffect(() => {
    if (userProfile && drawerConfig.isOpen) {

      let dayCheck = dayjs(new Date());

      if (dayCheck.isAfter(dayjs(leaveRoundConfig.end))) {
        dayCheck = dayjs(leaveRoundConfig.end);
      }

      dayCheck = dayCheck.toDate();

      setOpenConfirm(false);
      setRequestLeave({
        idLeaveType: 0,
        leaveType: null,

        startDate: new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          0,
          0,
          0
        ),
        endDate: new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          0,
          0,
          0
        ),
        description: "",
        leaveDayType: "fullday",
        startDateHour: 0,
        startDateMinute: 0,
        endDateHour: 0,
        endDateMinute: 0,
        used: 0,
        quota: 0,
        balance: 0,
        remaining: null,
      });

      dispatch(
        getWorkingHoursByIdEmployees(userProfile.idEmp, {
          startDate: dayjs(dayCheck).format("YYYY-MM-DD"),
          endDate: dayjs(dayCheck).format("YYYY-MM-DD")
        })
      ).then(res => {
        setSchedule(res.data);
      })

      setErrorConfig((prev) => ({
        ...prev,
        isInvalidStartEnd: false,
        isOverResetEndOfMonth: false,
        isNotLeaveRoundEnd: false,
      }));
    }
  }, [drawerConfig.isOpen, userProfile]);


  useEffect(() => {
    calculateLeaveUse();
  }, [schedule, requestLeave.idLeaveType, requestLeave.leaveDayType, requestLeave.startDate, requestLeave.endDate, 
    requestLeave.startDateHour, requestLeave.startDateMinute,
    requestLeave.endDateHour, requestLeave.endDateMinute
  ])

  const fetchingWorkingHours = (start, end) => {
    dispatch(getWorkingHoursByIdEmployees(userProfile.idEmp, {
      startDate: dayjs(start).format("YYYY-MM-DD"),
      endDate: dayjs(end).format("YYYY-MM-DD")
    })).then(res => {
      setSchedule(res.data);
    })
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: (acceptedFiles) => {
      let formData = new FormData();
      acceptedFiles.map((file) => formData.append("file", file));
      setFileSelected(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    maxFiles: 1,
  });

  const thumbs = fileSelected.map((file) => (
    <img key={file.name} src={file.preview} />
  ));

  const handleChangeLeaveType = (event) => {

    const foundLeaveAvailable = leaveAvailable.find(item => item.idLeaveType === event.target.value);

    if(foundLeaveAvailable){

      setRequestLeave(prev => ({
        ...prev,
        idLeaveType: event.target.value,
        leaveType: foundLeaveAvailable,
        quota: foundLeaveAvailable.leaveValue === null? null: (foundLeaveAvailable.leaveValue + foundLeaveAvailable.carryValue),
        balance: foundLeaveAvailable.leaveValue === null? null: (foundLeaveAvailable.leaveRemain + foundLeaveAvailable.carryRemain),
      }))

    }

  }

  const handleChangeLeaveDayType = (event) => {

    setRequestLeave(prev => ({
      ...prev,
      leaveDayType: event.target.value,
      startDateHour: 0,
      startDateMinute: 0,
      endDateHour: 0,
      endDateMinute: 0,
    }))

  }

  const handleChange = (event) => {
    const temp = { ...requestLeave };
    if (event.target.name === "description") {
      temp.description = event.target.value;
    } else {
      var dataTimeStart = temp.startDate;
      dataTimeStart = dayjs(dataTimeStart).hour(0);
      dataTimeStart = dayjs(dataTimeStart).minute(0);

      var dataTimeEnd = temp.endDate;
      dataTimeEnd = dayjs(dataTimeEnd).hour(0);
      dataTimeEnd = dayjs(dataTimeEnd).minute(0);

      temp[event.target.name] = event.target.value;
      temp.startDate = dataTimeStart;
      temp.endDate = dataTimeEnd;

      if (event.target.name !== "leaveDayType") {
        temp.leaveDayType = "fullday";
      } else {
        temp.leaveDayType = event.target.value;
      };
    };

    setRequestLeave(temp);
    calculateLeaveUse();
  };

  const handleChangeTime = (name, _a, _b, value) => {
    setRequestLeave({
      ...requestLeave,
      [name]: value,
    });
  };

  const calculateLeaveUse = () => {

    setErrorConfig((prev) => ({
      ...prev,
      isInvalidStartEnd: false,
      isOverResetEndOfMonth: false,
      isNotLeaveRoundEnd: false,
      isOutOfWorkingTime: false,
      isOverRemaining: false,
    }));

    let start = dayjs(requestLeave.startDate);
    let end = dayjs(requestLeave.endDate);

    if(requestLeave.leaveDayType === "fullday"){

      start = start.startOf('date');
      end = end.startOf('date');

    } else {

      start = dayjs(`${start.format("YYYY-MM-DD")} ${requestLeave.startDateHour}:${requestLeave.startDateMinute}:00`);
      end = dayjs(`${end.format("YYYY-MM-DD")} ${requestLeave.endDateHour}:${requestLeave.endDateMinute}:00`);

    }
    
    if (leaveRoundConfig) {
      if (
        !start.isBetween(
          dayjs(leaveRoundConfig.start),
          dayjs(leaveRoundConfig.end),
          "day",
          "[]"
        ) || !end.isBetween(
          dayjs(leaveRoundConfig.start),
          dayjs(leaveRoundConfig.end),
          "day",
          "[]"
        )
      ) {
        setErrorConfig((prev) => ({
          ...prev,
          isNotLeaveRoundEnd: true,
        }));
      }
    }

    let used = 0;

    if(requestLeave.leaveDayType === "fullday"){

      schedule.map(item => {
        if(!item.holiday
          && item.isWorkingDay === 1
          && dayjs(item.date).isSameOrAfter(dayjs(requestLeave.startDate), 'day')
          && dayjs(item.date).isSameOrBefore(dayjs(requestLeave.endDate), 'day')
        ){
          used += 1;
        }
      })

    } else if (requestLeave.leaveDayType === "custom") {

      const foundSchedule = schedule.find(item => 
        start.isSameOrAfter(dayjs(item.timeInDateTime))
        && start.isBefore(dayjs(item.timeOutDateTime))
        && end.isAfter(dayjs(item.timeInDateTime))
        && end.isSameOrBefore(dayjs(item.timeOutDateTime))
      )

      if(!foundSchedule){
        setErrorConfig(prev => ({
          ...prev,
          isOutOfWorkingTime: true,
        }))
        return
      }

      let timeInDateTime = dayjs(`${foundSchedule.timeInDateTime}`);
      let timeOutDateTime = dayjs(`${foundSchedule.timeOutDateTime}`);

      let startBreakDateTime = dayjs(`${foundSchedule.date} ${foundSchedule.startBreak}`);

      if(startBreakDateTime.isBefore(timeInDateTime)){
        startBreakDateTime = startBreakDateTime.add(1, 'day');
      }

      let endBreakDateTime = startBreakDateTime;

      if(!foundSchedule.breakTime){
        endBreakDateTime = startBreakDateTime.add(foundSchedule.breakTimeMin, 'minute');
      }

      let minuteUsed = 0;
      let hourUsed = 0;

      let beforeBreakMinuteUsed = 0;
      let afterBreakMinuteUsed = 0;

      beforeBreakMinuteUsed = (startBreakDateTime.isAfter(end)? end: startBreakDateTime).diff(start, 'minute');
      afterBreakMinuteUsed = end.diff((endBreakDateTime.isBefore(start)? start: endBreakDateTime), 'minute');

      if(beforeBreakMinuteUsed < 0){
        beforeBreakMinuteUsed = 0;
      }
      if(afterBreakMinuteUsed < 0){
        afterBreakMinuteUsed = 0;
      }

      minuteUsed = beforeBreakMinuteUsed + afterBreakMinuteUsed;

      hourUsed = minuteUsed / 60;

      let workingHours = foundSchedule.shiftType.workingHours

      if(!foundSchedule.breakTime){
        workingHours = workingHours - foundSchedule.breakTimeMin
      }

      workingHours = (workingHours/60)

      used = (hourUsed / workingHours);

      if(foundSchedule.holiday || foundSchedule.isWorkingDay === 0){
        used = 0;
      }

    }

    let remain = requestLeave.balance === null? null: Math.round((requestLeave.balance - used)*1000)/1000

    if(remain !== null && remain < 0){
      setErrorConfig(prev => ({
        ...prev,
        isOverRemaining: true,
      }))
    }

    setRequestLeave(prev => ({
      ...prev,
      used: Math.round(used*1000)/1000,
      remaining: remain,
    }))

  }

  const isError = () => {
    let isInvalid = false;
    for (const [_, value] of Object.entries(errorConfig)) {
      if (value) isInvalid = true;
    }
    return isInvalid;
  };

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [alertLabel, setAlertLabel] = useState("");

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const displayUsed = () => {
    return `${Number(Math.round(requestLeave.used * 100) / 100).toFixed(2)} ${t("Day")}`
  }

  const displayRemaining = () => {
    if(requestLeave.balance === null) {
      return t("Unlimite");
    } else {
      return `${Number(requestLeave.balance).toFixed(2)} ${t("Day")}`
    }
  }

  return (
    <DrawerCustom
      title={t("CreateLeave")}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={handleClose}
    >
      {openConfirm ? (
        <DialogConfirmLeave
          data={requestLeave}
          handleClose={handleClose}
          handleOpenAlert={handleOpenAlert}
          handleChangeAlertType={handleChangeAlertType}
          openConfirm={setOpenConfirm}
          leaveRoundConfig={leaveRoundConfig}
        />
      ) : (
        <StyledRoot>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div>
                {leaveAvailable && (
                  <StyledFormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      {t("LeaveType")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={requestLeave.idLeaveType}
                      label={t("LeaveType")}
                      name="idLeaveType"
                      onChange={handleChangeLeaveType}
                    >
                      {leaveAvailable.map((item) => (
                        <MenuItem key={item.idLeaveType} value={item.idLeaveType}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                )}
                {leaveAvailable && (
                  <StyledFormControl
                    component="fieldset"
                    style={{ marginBottom: 16 }}
                  >
                    <RadioGroup
                      row
                      name="leaveDayType"
                      value={requestLeave.leaveDayType}
                      onChange={handleChangeLeaveDayType}
                    >
                      <FormControlLabel
                        value="fullday"
                        control={<Radio />}
                        label={t("FullDay")}
                      />
                      <FormControlLabel
                        value="custom"
                        control={<Radio />}
                        label={t("SpecifyTime")}
                        // disabled={disabledSelectLeaveDayType()}
                      />
                    </RadioGroup>
                  </StyledFormControl>
                )}
                <Grid item xs={12}>
                  <div style={{ marginBottom: 16 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label={t("StartDate")}
                        name="startDate"
                        inputFormat="dd/MM/yyyy"
                        value={requestLeave.startDate}
                        onChange={(newValue) => {

                          setRequestLeave(prev => ({
                            ...prev,
                            startDate: newValue
                          }))

                          fetchingWorkingHours(newValue, requestLeave.endDate);
                        }}
                        renderInput={(params) => (
                          <StyledTextField
                            style={{ width: "100%" }}
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true,
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  {requestLeave.leaveDayType === "custom" && <div style={{ display: "flex", marginBottom: 16 }}>
                    <StyledWrapTimePicker className="hours">
                      <TimePicker
                        handleChangeTime={handleChangeTime}
                        unit="hour"
                        name="startDateHour"
                        value={requestLeave.startDateHour}
                      />
                    </StyledWrapTimePicker>
                    <StyledWrapTimePicker>
                      <TimePicker
                        handleChangeTime={handleChangeTime}
                        unit="minute"
                        name="startDateMinute"
                        value={requestLeave.startDateMinute}
                      />
                    </StyledWrapTimePicker>
                  </div>}
                </Grid>
                <Grid item xs={12}>
                  <div style={{ marginBottom: 16 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label={t("EndDate")}
                        name="endDate"
                        inputFormat="dd/MM/yyyy"
                        value={requestLeave.endDate}
                        onChange={(newValue) => {
                          setRequestLeave(prev => ({
                            ...prev,
                            endDate: newValue
                          }))
                          fetchingWorkingHours(requestLeave.startDate, newValue);
                        }}
                        renderInput={(params) => (
                          <StyledTextField
                            style={{ width: "100%" }}
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true,
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  {requestLeave.leaveDayType === "custom" && <div style={{ display: "flex", marginBottom: 16 }}>
                    <StyledWrapTimePicker className="hours">
                      <TimePicker
                        handleChangeTime={handleChangeTime}
                        unit="hour"
                        name="endDateHour"
                        value={requestLeave.endDateHour}
                      />
                    </StyledWrapTimePicker>
                    <StyledWrapTimePicker>
                      <TimePicker
                        handleChangeTime={handleChangeTime}
                        unit="minute"
                        name="endDateMinute"
                        value={requestLeave.endDateMinute}
                      />
                    </StyledWrapTimePicker>
                  </div>}
                </Grid>

                {errorConfig.isOverResetEndOfMonth && (
                  <Grid item xs={12}>
                    <Typography fontSize="20px" fontWeight="500" color="error">
                      *ไม่สามารถลาคร่อมวันตัดรอบได้
                    </Typography>
                  </Grid>
                )}

                {errorConfig.isNotLeaveRoundEnd && (
                  <Grid item xs={12}>
                    <Typography fontSize="20px" fontWeight="500" color="error">
                      *ไม่สามารถลานอกช่วงรอบการลาที่เลือกได้
                    </Typography>
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "baseline" }}
                >
                  <div>
                    <Typography color="text.third" variant="body2">
                      {t("NumberLeaveDays")}
                    </Typography>
                    {errorConfig.isOutOfWorkingTime? ( 
                      <Typography fontSize="20px" fontWeight="500" color="error">
                        *ไม่สามารถลานอกกรอบเวลาทำงานได้
                      </Typography>
                    ) : (
                      <Typography variant="h6">
                        {displayUsed()}
                      </Typography>
                    )}
                    {/* <Typography variant="h6">
                      {displayNumberLeaveDays()} วัน (
                      {displayNumberLeaveHours()}
                      ชั่วโมง)
                    </Typography> */}
                    <Typography color="text.third" variant="body2">
                      {t("RemainingLeaveRights")}
                    </Typography>
                    <Typography variant="h6">
                      {displayRemaining()}
                    </Typography>

                    {errorConfig.isOverRemaining && ( 
                      <Typography fontSize="20px" fontWeight="500" color="error">
                        *สิทธิการลาคงเหลือไม่เพียงพอ
                      </Typography>
                    )}

                    {/* <Typography
                      variant="body2"
                      style={{ color: "#f15e5e", fontWeight: 500 }}
                    >
                      {leaveAvailable && displayErrorRequest()}
                    </Typography>
                    
                    <Typography
                      variant="body2"
                      style={{ color: "#f15e5e", fontWeight: 500 }}
                    >
                      {leaveAvailable &&
                        displayErrorRequestOver()}
                    </Typography> */}
                  </div>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                fullWidth
                id="description"
                label={t("Description")}
                multiline
                rows={4}
                name="description"
                value={requestLeave.description}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                color="text.third"
                variant="body2"
                paddingBottom="16px"
              >
                {t("AttachFile")}
              </Typography>
              {!requestLeave.file && (
                <StyledDropzone>
                  <Dropzone
                    accept="image/jpeg, image/png"
                    maxFiles={1}
                    multiple={false}
                    maxSize={3145728}
                    onDrop={(acceptedFiles, rejectedFiles) => {
                      if (acceptedFiles.length > 0) {
                        setRequestLeave({
                          ...requestLeave,
                          file: acceptedFiles[0],
                        });
                      }
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ className: "dropzone-leave" })}>
                        <div className="inner-dropzone">
                          <input {...getInputProps()} />
                          {/* <Fragment>{thumbs}</Fragment> */}
                          <div style={{ textAlign: "center" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <img alt="add" src={GalleryIcon} />
                            </div>
                            <Typography
                              variant="caption"
                              style={{ lineHeight: 0.5, color: "#999999" }}
                            >
                              Allowed *.jpeg, *.jpg, *.png, max size of 3 MB
                            </Typography>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </StyledDropzone>
              )}

              {requestLeave.file && (
                <Box style={{ position: "relative", textAlign: "center" }}>
                  <IconButton
                    style={{ position: "absolute", top: -8, right: 0 }}
                    color="error"
                    onClick={() => {
                      setRequestLeave({ ...requestLeave, file: null });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <div>
                    {/* <img src={ImageFileIcon} width="40" /> */}
                    <Typography>{requestLeave.file.path}</Typography>
                  </div>
                  {/* <img
                    alt="attach-file"
                    src={URL.createObjectURL(requestLeave.file)}
                    style={{
                      maxWidth: 300,
                      maxHeight: 128,
                      objectFit: "contain",
                    }}
                  /> */}
                </Box>
              )}
            </Grid>
          </Grid>
          <div className="wrap-footer-btn">
            <ButtonBlue
              size=""
              variant={"text"}
              color="secondary"
              onClick={handleClose}
            >
              {t("Cancel")}
            </ButtonBlue>
            {/* <ButtonBlue
              size=""
              variant={"contained"}
              onClick={() => {
                setOpenConfirm(true);
              }}
              disabled={disabledButtonSubmit()}
            >
              {t("CreateLeave")}
            </ButtonBlue> */}

            <ButtonBlue
              size=""
              variant={"contained"}
              onClick={() => {setOpenConfirm(true)}}
              // onClick={() => CompareSelectedDateWithShift()}
              disabled={requestLeave.used === 0 || isError()}
            >
              {t("CreateLeave")}
            </ButtonBlue>
          </div>
          <AlertResponse
            open={openAlert}
            handleClose={handleCloseAlert}
            alertType={alertType}
            label={alertLabel}
          />
        </StyledRoot>
      )}
    </DrawerCustom>
  );
};

export default DialogNewLeave;
