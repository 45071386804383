import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import * as XLSX from "xlsx"

//import AlertResponse from "../../../../shared/general/AlertResponse";
import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import DataGrid, {
  Column,
  Paging,
  Pager,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Scrolling,
  Sorting,
  Selection,
  TotalItem,
  Summary,
  Export,
  Button,
} from "devextreme-react/data-grid";

import AlertResponse from "../../shared/general/AlertResponse";
import StyledCard from "../../shared/general/Card";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import Detail from "./detail";
import utils from "../../../../utils";

import { delelteBillingExtra, getBillingAllCompanysByVendor, getBillingExtra, upsertBillingExtra } from "../../../../actions/billing";
import { Typography, Box, Dialog, DialogTitle, DialogContent, DialogActions, Modal, IconButton } from "@mui/material";
import { Cancel, CheckCircle } from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from "sweetalert2";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ButtonLoadingBlue from "../../shared/general/ButtonLoadingBlue";

const StyledCardContent = styled(CardContent)({
  height: "100%",
  padding: 24,
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
  "& .wrap-header-filter": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
    "& .wrap-filter": {
      display: "flex",
      "& .btn-filter": {
        marginRight: 8,
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: 8,
      },
    },
  },
  "& .wrap-manage": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .wrap-manage-group": {
      display: "flex",
      alignItems: "center",
      "& .btn-mamage-group-company": {
        marginRight: 8,
      },
    },
    "& .wrap-edit-selected": {},
  },
  "& .wrap-panel": {
    height: 490,
    overflow: "auto",
    display: "flex",
  },
});

const StyledWrapDataGrid = styled("div")({
  width: "100%",
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-ot[role="columnheader"]': {
    backgroundColor: "#7c4dff",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-shift[role="columnheader"]': {
    backgroundColor: "#2196f3",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-salary[role="columnheader"]': {
    backgroundColor: "#00796b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-addition[role="columnheader"]': {
    backgroundColor: "#26a69a",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-addition[role="columnheader"]': {
    backgroundColor: "#00897b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-earnings[role="columnheader"]': {
    backgroundColor: "#00897b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-sso[role="columnheader"], .column-tax[role="columnheader"], .column-pf[role="columnheader"]':
  {
    backgroundColor: "#ef5350",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-deduction[role="columnheader"]': {
    backgroundColor: "#e53935",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-deduction[role="columnheader"]': {
    backgroundColor: "#c62828",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-net[role="columnheader"]': {
    backgroundColor: "#ffd600",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
  '& .column-accumulate[role="columnheader"]': {
    backgroundColor: "#ff9100",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
});

const StyledDivDataGrid = styled("div")({
  // width:"100%",
  display: "flex",
  position: "relative",
  "& .datepicker": {
    width: "fit-content",
    position: "absolute",
    top: "0",
    left: "0",
    zIndex: "5",
    "& .MuiInputBase-root": {
      borderRadius: "4px"
    },
    "& input": {
      lineHeight: "1.35715",
      padding: "7px 9px 8px !important",
      fontSize: "1rem"
    }
  }
});

const TableEmployee = (props) => {
  const { monthYear, setMonthYear } = props;
  const [type, setType] = useState(2);
  const [seeMoreData, setSeeMoreData] = useState(true);
  const [billingExtraData, setBillingExtraData] = useState(null);

  const { t, i18n } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [eachData, setEachData] = useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [selectedRowsData, setSelectedRowsData] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  useEffect(() => {
    setData(billingExtraData);
  }, [billingExtraData]);

  const rowIndex = (cellInfo) => {
    return cellInfo.rowIndex + 1;
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const onExporting = useCallback(() => {
  }, []);

  const customizeNumber = (data) => {
    return `${utils.numberWithCommas(data.value || 0)} บาท`;
  };

  const onSelectionChanged = (e) => {
    setSelectedRowKeys(e.selectedRowKeys);
  };

  const StatusIcon = ({ value }) => {
    if (value === null || value === undefined) {
      return (
        <div
          style={{
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            backgroundColor: "gray",
            marginRight: '8px'
          }}
        />
      );
    }

    let color = value === 1 ? "green" : "red";
    let icon = value === 1 ? "check_circle" : "cancel";

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span
          className="material-icons"
          style={{ color, marginRight: 8 }}
        >
          {icon}
        </span>
      </div>
    );
  };

  const [openModalPDF, setOpenModalPDF] = useState(false);
  const [selectItem, setSelectItem] = useState(false);

  const handleClickOpenPDF = (item) => {
    setOpenModalPDF(true);
    setSelectItem(item)
  };

  const handleClosePDF = () => {
    setOpenModalPDF(false);
    setSelectItem(null)
  };

  const FileColumn = ({ value }) => {

    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
        {value ? (
          <CheckCircle
            style={{ color: "green", cursor: "pointer" }}
            onClick={() => handleClickOpenPDF(value)}
          />
        ) : (
          <Cancel
            style={{ color: "red", cursor: "default" }}
          />
        )}
      </div>
    );
  };

  const handleApprove = (row, type) => {
    const actionText = type === 'approved' ? 'อนุมัติ' : 'ปฏิเสธ';
    const successText = type === 'approved' ? 'ได้อนุมัติรายการแล้ว' : 'ได้ปฏิเสธรายการแล้ว';

    Swal.fire({
      title: `คุณแน่ใจหรือไม่?`,
      text: `คุณต้องการ${actionText}รายการนี้หรือไม่?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ไม่ใช่',
      input: 'text',
      inputPlaceholder: 'กรุณาใส่หมายเหตุ (ถ้ามี)',
      inputValidator: (value) => {
        if (value.length > 100) {
          return 'หมายเหตุต้องไม่เกิน 100 ตัวอักษร';
        }
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        if (row.idBillingExtra) {
          formData.append("idCompany", row.idCompany);
          formData.append("amount", row.amount);
          formData.append("name", row.name);
          formData.append("isDraft", row.isDraft ? 1 : 0);
          formData.append("noteVander", row.noteVander);
          formData.append("idBillingExtra", row.idBillingExtra);
          formData.append("isApproved", type === 'approved' ? 1 : 0);
          formData.append("noteCompany", result.value);
        }
        await upsertBillingExtra(formData);
        console.log(`${actionText}รายการ:`, row);
        console.log('หมายเหตุผู้ใช้:', result.value || 'ไม่มีหมายเหตุ');
        Swal.fire(`${actionText}!`, `${successText}`, 'success');
        fetchedBillingExtra()
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('ยกเลิก', `คุณได้ยกเลิกการ${actionText}แล้ว`, 'error');
      }
    });
  };
  useEffect(() => {
    fetchedBillingExtra()
  }, [monthYear])

  const fetchedBillingExtra = async () => {
    try {
      const query = {
        idCompany: true,
        // isApproved: true,
        isDraft: 0,
        start: dayjs(monthYear).startOf('month').format("YYYY-MM-DD"),
        end: dayjs(monthYear).endOf('month').format("YYYY-MM-DD")
      };

      const response = await getBillingExtra(query);
      if (response && response.data) {
        setBillingExtraData(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Fragment>
      <Modal open={openModalPDF} onClose={handleClosePDF}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '800px',
            backgroundColor: 'white',
            padding: 2,
            borderRadius: 1,
            boxShadow: 24,
            overflow: 'hidden',
          }}
        >
          <Typography variant="h6" gutterBottom>PDF View</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {selectItem && (
              <iframe
                src={selectItem}
                style={{ width: "100%", height: "500px" }}
                frameBorder="0"
                title="PDF Viewer"
              />
            )}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
            <Button onClick={handleClosePDF} color="primary">
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
      <Box sx={{ mt: 2 }}>
        <StyledCard>
          <StyledCardContent>
            <StyledWrapDataGrid>
              <StyledDivDataGrid>
                
                <div style={{ width: "100%" }}>
                  <DataGrid
                    dataSource={data ? data : []}
                    showBorders={false}
                    showColumnLines={false}
                    rowAlternationEnabled={true}
                    columnAutoWidth={true}
                    onExporting={onExporting}
                    onRowClick={(v) => {
                      if (v && v.data && v.data.idBilling && v.data.idCompany && v.data.monthPeriod) {
                        setEachData(v.data.idBilling);
                        setSelectedCompany(v.data.idCompany);
                        setMonthYear(v.data.monthPeriod);
                        setSeeMoreData(false);
                      }
                    }}
                    noDataText="ไม่มี Billing"
                    onSelectionChanged={onSelectionChanged}
                  >

                    <Column
                      caption={`${t("NoNumber")}`}
                      width={"auto"}
                      cellRender={rowIndex}
                      alignment="center"
                      cssClass="column-info"
                    />
                    <Column
                      caption={`${t("Name")}`}
                      dataField="name"
                      dataType="string"
                      width={"auto"}
                      cssClass="column-info"
                      cellRender={({ value }) => (
                        <div >
                          {value}
                        </div>
                      )}
                    />
                    <Column
                      caption={`${t("Status")}`}
                      dataField="isApproved"
                      dataType="string"
                      width={"auto"}
                      cssClass="column-info"
                      alignment="center"
                      cellRender={({ value }) => (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                          <StatusIcon value={value} />
                        </div>
                      )}
                      calculateFilterExpression={(filterValue) => {
                        if (filterValue === null) {
                          return ["isApproved", "=", null];
                        }
                        return ["isApproved", "=", filterValue];
                      }}
                      headerFilter={{
                        dataSource: [
                          { text: t("WaitingForEvaluation"), value: null, icon: "help_outline", color: "gray" },
                          { text: t("Approved"), value: 1, icon: "check_circle", color: "green" },
                          { text: t("Rejected"), value: 0, icon: "cancel", color: "red" },
                        ],
                        allowSearch: false,
                        customizeText: ({ value }) => {
                          const item = [
                            { value: null, icon: "help_outline", color: "gray" },
                            { value: 1, icon: "check_circle", color: "green" },
                            { value: 0, icon: "cancel", color: "red" },
                          ].find((item) => item.value === value);

                          if (item) {
                            return (
                              `<span class="material-icons" style="color: ${item.color}; margin-right: 8px;">${item.icon}</span> ${item.text}`
                            );
                          }
                          return "";
                        },
                      }}
                    />

                    <Column
                      caption={`${t("Company")}`}
                      dataField="companyName"
                      dataType="string"
                      width={300}
                      minWidth={200}
                      cssClass="column-info"
                    />

                    <Column
                      caption={`${t("Amount")}`}
                      dataType="string"
                      cssClass="column-info"
                      dataField="amount"
                      width={"auto"}
                    >
                      <HeaderFilter allowSearch={true} />
                    </Column>
                    <Column
                      caption={`${t("File")}`}
                      dataType="string"
                      cssClass="column-info"
                      dataField="imageUrl"
                      alignment="center"
                      width={"auto"}
                      allowFiltering={false}
                      cellRender={({ value }) => <FileColumn value={value} />}
                    ></Column>

                    <Column
                      caption={`${t("Reason")}`}
                      dataType="string"
                      cssClass="column-info"
                      dataField="noteVander"
                      width={"auto"}
                    />
                    <Column
                      caption={`${t("StartDate")}`}
                      dataType="string"
                      cssClass="column-info"
                      dataField="createAt"
                      width={"auto"}
                    />

                    <Column
                      caption={`${t("Action")}`}
                      dataType="string"
                      cssClass="column-info"
                      width={150}
                      alignment="center"
                      cellRender={({ row }) => (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
                          <ButtonBlue
                            variant="contained"
                            disabled={row.data.isApproved !== null}
                            onClick={() => handleApprove(row.data, 'approved')}
                          >
                            {t('Approved')}
                          </ButtonBlue>
                          <ButtonBlue
                            variant="contained"
                            disabled={row.data.isApproved != null}
                            color='error'
                            onClick={() => handleApprove(row.data, 'reject')}
                          >
                            {t('Reject')}
                          </ButtonBlue>
                        </div>
                      )}
                    />


                    {/* <Export enabled={true} allowExportSelectedData={true} /> */}
                    <Paging defaultPageSize={10} />
                    <Pager
                      visible={(data && data.length > 0) ? true : false}
                      allowedPageSizes={[
                        10,
                        ...(data && data.length > 10) ? [20] : [],
                        ...(data && data.length > 20) ? [30] : []
                      ]}
                      showPageSizeSelector={true}
                      showNavigationButtons={true}
                      showInfo={true}
                    />
                    <Selection mode="single" />
                    <Sorting mode="multiple" />
                    <Scrolling columnRenderingMode="virtual" />
                    <FilterRow visible={false} />
                    <HeaderFilter visible={true} />
                    <SearchPanel visible={true} width={240} placeholder={`${t("Search")}...`} />
                    <Summary>
                      <TotalItem
                        column="totalSalary"
                        customizeText={customizeNumber}
                        summaryType="sum"
                      />
                    </Summary>
                  </DataGrid>
                </div>
              </StyledDivDataGrid>
            </StyledWrapDataGrid>
          </StyledCardContent>
        </StyledCard>
        <AlertResponse
          open={openAlert}
          handleClose={() => {
            handleCloseAlert();
            handleChangeAlertType(false);
          }}
          alertType={alertType}
        />
      </Box>
    </Fragment>
  )
}

export default TableEmployee;
