import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";

import Container from "@mui/material/Container";
import {
  Box,
  Stack,
  Typography
} from "@mui/material";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DataTable from "./dataTable"
import DataTableExtra from "./dataTableExtra"
import Detail from "./detail"
import DialogCreateBill from "./DialogCreateBill"
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import dayjs from "dayjs";
import { delelteBillingExtra, getAllAdditionsByVender, getBillingExtra, getBillingExtraPDF, upsertBillingExtra } from "../../../../actions/billing";
import BillingExtraDialog from "./dialogExtra";
import Swal from 'sweetalert2';
import DialogDownload from "./dialogDownload";
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';

const StyledRoot = styled("div")({
  minWidth: 350,
  width: "100%",
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 36,
});

const StyledTypeDiv = styled("div")({
  margin: "16px 0px",
  "& .button": {
    padding: "16px 32px"
  },
  "& .button:first-child": {
    borderRadius: "16px 0px 0px 16px"
  },
  "& .button:last-child": {
    borderRadius: "0px 16px 16px 0px",
  }
});

const StyleButtonBlue = styled(ButtonBlue)(({ active, currentValue }) => ({
  color: active === "true" ? "#007afe" : "#C4C4C4",
  borderColor: active === "true" ? "#007afe" : "#C4C4C4",
  ...active !== "true" ? {
    borderRightWidth: currentValue === "0" ? "1px" : "0px",
    borderLeftWidth: currentValue === "1" ? "1px" : "0px"
  } : {}
}));

const BillingPage = () => {
  const { t, i18n } = useTranslation();

  const [id] = useState(null);
  const [type, setType] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogDownload, setOpenDialogDownload] = useState(false);
  const [additionLists, setAdditionLists] = useState(null);
  const [seeMoreData, setSeeMoreData] = useState(true);
  const [monthYear, setMonthYear] = React.useState(dayjs());

  const onChangeType = (valueType) => {
    setType(valueType)
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectBillingExtraData(null)
  };

  const fetchedAdditions = async () => {
    try {
      const response = await getAllAdditionsByVender();
      if (response && response.data) {
        setAdditionLists(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchedAdditions();
  }, []);


  const [billingExtraData, setBillingExtraData] = useState(null);
  const [selectBillingExtra, setSelectBillingExtra] = useState([]);
  const [selectbillingExtraData, setSelectBillingExtraData] = useState(null);

  const handleSubmit = async (data) => {
    try {
      // Submit data
      await upsertBillingExtra(data);
      await fetchedBillingExtra();
      setOpenDialog(false);

      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Billing extra has been successfully updated!',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.message || 'Something went wrong while updating the billing extra!',
        confirmButtonText: 'OK',
      });
    }
  };

  useEffect(() => {
    fetchedBillingExtra()
  }, [])

  const fetchedBillingExtra = async () => {
    try {
      const query = {
        // idCompany: 8,
        // isApproved: true,
        // isDraft: false,
        start: dayjs(monthYear).startOf('month').format("YYYY-MM-DD"),
        end: dayjs(monthYear).endOf('month').format("YYYY-MM-DD")
      };

      const response = await getBillingExtra(query);
      if (response && response.data) {
        setBillingExtraData(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleDownloadPDF = async (idCompany, type) => {

    try {
      const response = await getBillingExtraPDF(
        { listItems: selectBillingExtra.length > 0 ? selectBillingExtra : [] },
        {
          idCompany: idCompany,
          start: selectBillingExtra.length > 0
            ? dayjs(
              selectBillingExtra
                .map(item => dayjs(item.createAt))
                .sort((a, b) => a - b)[0]
            ).startOf('month').format('YYYY-MM-DD')
            : null,
          end: selectBillingExtra.length > 0
            ? dayjs(
              selectBillingExtra
                .map(item => dayjs(item.createAt))
                .sort((a, b) => b - a)[0]
            ).endOf('month').format('YYYY-MM-DD')
            : null,
          type: type,
        }
      );

      const base64Regex = /^[A-Za-z0-9+/=]+$/;
      if (!base64Regex.test(response.data.pdfBase64)) {
        throw new Error('Invalid Base64 string format: contains invalid characters');
      }

      let pdfBase64 = response.data.pdfBase64.trim();
      pdfBase64 = pdfBase64.replace(/\s+/g, ''); // Remove any whitespace characters

      const padding = pdfBase64.length % 4;
      if (padding > 0) {
        pdfBase64 += '='.repeat(4 - padding); // Add necessary padding
      }

      const pdfData = atob(pdfBase64);

      const byteArray = new Uint8Array(pdfData.length);
      for (let i = 0; i < pdfData.length; i++) {
        byteArray[i] = pdfData.charCodeAt(i);
      }

      const blob = new Blob([byteArray], { type: 'application/pdf' });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `BillingExtra.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url);

      // Show success notification
      Swal.fire({
        title: "ดาวน์โหลดเสร็จสิ้น",
        text: "ไฟล์ PDF ใบเรียกเก็บเงินถูกดาวน์โหลดเรียบร้อยแล้ว",
        icon: "success",
      });
    } catch (error) {
      console.error("Error downloading PDF:", error);
      Swal.fire({
        title: "ข้อผิดพลาด",
        text: error.message || "ไม่สามารถดาวน์โหลดไฟล์ PDF ใบเรียกเก็บเงินได้ กรุณาลองใหม่อีกครั้ง",
        icon: "error",
      });
    }
  };



  const handleEdit = (row) => {
    setSelectBillingExtraData(row.data)
    handleOpenDialog()
  };

  const handleDelete = async (row) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
      });

      // If the user confirmed the delete action
      if (result.isConfirmed) {
        console.log("Delete row", row.data.idBillingExtra);

        // Call your delete function
        await delelteBillingExtra(row.data.idBillingExtra);
        await fetchedBillingExtra()
        await Swal.fire({
          title: 'Deleted!',
          text: 'The row has been deleted.',
          icon: 'success',
          confirmButtonText: 'Ok',
        });
      }
    } catch (error) {
      console.error("Error deleting row", error);

      await Swal.fire({
        title: 'Error!',
        text: 'An error occurred while deleting the row.',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }
  };


  const handleDownloadExcel = async () => {
    try {

      // Prepare data for the Excel file
      const data = selectBillingExtra.map((item, index) => ({
        'ID': index + 1,
        'Name': item.name,
        'Amount': item.amount,
        'Image URL': item.imageUrl,
        'Approved': item.isApproved ? 'Yes' : 'No',
        'Draft': item.isDraft ? 'Yes' : 'No',
        'Note Vendor': item.noteVander,
        'Note Company': item.noteCompany || 'N/A',
        'Company Name': item.companyName,
        'Create Date': item.createAt,
        'Update Date': item.updateAt,
      }));

      // Create a worksheet from the data
      const ws = XLSX.utils.json_to_sheet(data);

      // Create a workbook with the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Billing Extra');

      // Generate the Excel file in a binary format
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

      // Create a Blob from the buffer and trigger the download
      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "BillingExtra.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url);

      // Show success notification
      Swal.fire({
        title: "ดาวน์โหลดเสร็จสิ้น",
        text: "ไฟล์ Excel ถูกดาวน์โหลดเรียบร้อยแล้ว",
        icon: "success",
      });

    } catch (error) {
      console.error("Error downloading Excel:", error);
      Swal.fire({
        title: "ข้อผิดพลาด",
        text: error.message || "ไม่สามารถดาวน์โหลดไฟล์ Excel ได้ กรุณาลองใหม่อีกครั้ง",
        icon: "error",
      });
    }
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            Billing list
          </Typography>
          {type === 1 && (
            <ButtonBlue
              variant={"contained"}
              startIcon={<AddIcon />}
              onClick={() => handleOpenDialog()}
            >
              {/* {t("NewSalary")} */}
              ทำบิลจ่ายเหมาใหม่
            </ButtonBlue>
          )}
          {type === 2 && (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', flexDirection: 'row' }}>
              <ButtonBlue
                variant={"contained"}
                startIcon={<DownloadIcon />}
                onClick={() => setOpenDialogDownload(true)}
              >
                ดาวน์โหลดบิล
              </ButtonBlue>
              <ButtonBlue
                variant={"contained"}
                startIcon={<AddIcon />}
                onClick={() => handleOpenDialog()}
              >
                {/* {t("NewSalary")} */}
                สร้างบิลใหม่
              </ButtonBlue>
            </div>
          )}
        </Box>

        {seeMoreData && (
          <StyledTypeDiv>
            <StyleButtonBlue
              className="button"
              variant="outlined"
              active={type === 0 ? "true" : "false"}
              currentValue={`${type}`}
              onClick={() => onChangeType(0)}
            >
              จ่ายตามจริง
            </StyleButtonBlue>
            <StyleButtonBlue
              className="button"
              variant="outlined"
              active={type === 1 ? "true" : "false"}
              currentValue={`${type}`}
              onClick={() => onChangeType(1)}
              sx={{ borderRadius: '0' }}
            >
              จ่ายเหมา
            </StyleButtonBlue>
            <StyleButtonBlue
              className="button"
              variant="outlined"
              active={type === 2 ? "true" : "false"}
              currentValue={`${type}`}
              onClick={() => onChangeType(2)}
            >
              เพิ่มเติม
            </StyleButtonBlue>
          </StyledTypeDiv>
        )}
        {
          type === 2 ?
            <DataTableExtra type={type} setSeeMoreData={setSeeMoreData} billingExtraData={billingExtraData} selectBillingExtra={selectBillingExtra} setSelectBillingExtra={setSelectBillingExtra} handleEdit={handleEdit} handleDelete={handleDelete} handleDownloadPDF={handleDownloadPDF} />
            :
            <DataTable type={type} setSeeMoreData={setSeeMoreData} />
        }

        {/* <Detail /> */}
      </Container>

      {
        type === 2 ?
          <>
            <BillingExtraDialog
              open={openDialog}
              onClose={handleCloseDialog}
              onSubmit={handleSubmit}
              initialData={selectbillingExtraData}
            />
            <DialogDownload
              dataOption={billingExtraData}
              open={openDialogDownload}
              setOpen={setOpenDialogDownload}
              onHandleSubmit={handleDownloadPDF}
              onHandleDownloadExcel={handleDownloadExcel}
              selectedItems={selectBillingExtra}
              setSelectedItems={setSelectBillingExtra}
            />
          </>
          :
          <DialogCreateBill
            open={openDialog}
            handleClose={handleCloseDialog}
            additionLists={additionLists}
          />
      }

    </StyledRoot>
  );
};

export default BillingPage;
