import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import * as XLSX from "xlsx"

//import AlertResponse from "../../../../shared/general/AlertResponse";
import DialogEdit from "./dialog";
import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import DataGrid, {
  Column,
  Paging,
  Pager,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Scrolling,
  Sorting,
  Selection,
  TotalItem,
  Summary,
  Export,
  Button,
} from "devextreme-react/data-grid";

import AlertResponse from "../../shared/general/AlertResponse";
import StyledCard from "../../shared/general/Card";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import Detail from "./detail";
import utils from "../../../../utils";

import { getBillingAllCompanysByVendor, getBillingExtra, getBillingExtraPDF } from "../../../../actions/billing";
import { Typography, Box, Dialog, DialogTitle, DialogContent, DialogActions, Modal, IconButton, Checkbox } from "@mui/material";
import { Cancel, CheckCircle } from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from "sweetalert2";
import DownloadIcon from "@mui/icons-material/Download";

const StyledCardContent = styled(CardContent)({
  height: "100%",
  padding: 24,
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
  "& .wrap-header-filter": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
    "& .wrap-filter": {
      display: "flex",
      "& .btn-filter": {
        marginRight: 8,
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: 8,
      },
    },
  },
  "& .wrap-manage": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .wrap-manage-group": {
      display: "flex",
      alignItems: "center",
      "& .btn-mamage-group-company": {
        marginRight: 8,
      },
    },
    "& .wrap-edit-selected": {},
  },
  "& .wrap-panel": {
    height: 490,
    overflow: "auto",
    display: "flex",
  },
});

const StyledWrapDataGrid = styled("div")({
  width: "100%",
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .dx-checkbox-icon': {
    // backgroundColor: "#283593",
    border: '1px solid #ddd'
  },
  '& .column-ot[role="columnheader"]': {
    backgroundColor: "#7c4dff",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-shift[role="columnheader"]': {
    backgroundColor: "#2196f3",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-salary[role="columnheader"]': {
    backgroundColor: "#00796b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-addition[role="columnheader"]': {
    backgroundColor: "#26a69a",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-addition[role="columnheader"]': {
    backgroundColor: "#00897b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-earnings[role="columnheader"]': {
    backgroundColor: "#00897b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-sso[role="columnheader"], .column-tax[role="columnheader"], .column-pf[role="columnheader"]':
  {
    backgroundColor: "#ef5350",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-deduction[role="columnheader"]': {
    backgroundColor: "#e53935",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-deduction[role="columnheader"]': {
    backgroundColor: "#c62828",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-net[role="columnheader"]': {
    backgroundColor: "#ffd600",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
  '& .column-accumulate[role="columnheader"]': {
    backgroundColor: "#ff9100",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
});

const StyledDivDataGrid = styled("div")({
  // width:"100%",
  display: "flex",
  position: "relative",
  "& .datepicker": {
    width: "fit-content",
    position: "absolute",
    top: "0",
    left: "0",
    zIndex: "5",
    "& .MuiInputBase-root": {
      borderRadius: "4px"
    },
    "& input": {
      lineHeight: "1.35715",
      padding: "7px 9px 8px !important",
      fontSize: "1rem"
    }
  }
});

const TableEmployee = (props) => {
  const { type, setSeeMoreData, billingExtraData, setBillingExtraData, handleEdit, handleDelete, handleDownloadPDF, selectBillingExtra, setSelectBillingExtra } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const grid = useRef();
  const { result: billingCompanys } = useSelector((state) => state.billingCompanys);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [eachData, setEachData] = useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [selectedRowsData, setSelectedRowsData] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [gridColumnsAddition, setGridColumnsAddition] = React.useState([]);
  const [gridColumnsDeduction, setGridColumnsDeduction] = React.useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [openAddFile, setOpenAddFile] = useState(false);
  const [anchorElBank, setAnchorElBank] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [monthYear, setMonthYear] = React.useState(dayjs());
  const open = Boolean(anchorEl);
  const openBank = Boolean(anchorElBank);


  useEffect(() => {
    setData(billingExtraData);
  }, [billingExtraData]);



  const rowIndex = (cellInfo) => {
    return cellInfo.rowIndex + 1;
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const onExporting = useCallback(() => {
  }, []);

  const customizeNumber = (data) => {
    return `${utils.numberWithCommas(data.value || 0)} บาท`;
  };

  const handleAlert = (type) => {
    handleOpenAlert();
    handleChangeAlertType(type);
  };

  // const onSelectionChanged = (e) => {
  //   setSelectedRowKeys(e.selectedRowKeys);
  // };

  const StatusIcon = ({ value }) => {
    if (value === null || value === undefined) {
      return (
        <div
          style={{
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            backgroundColor: "gray",
            marginRight: '8px'
          }}
        />
      );
    }

    let color = value === 1 ? "green" : "red";
    let icon = value === 1 ? "check_circle" : "cancel";

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span
          className="material-icons"
          style={{ color, marginRight: 8 }}
        >
          {icon}
        </span>
      </div>
    );
  };

  const [openModalPDF, setOpenModalPDF] = useState(false);
  const [selectItem, setSelectItem] = useState(false);

  const handleClickOpenPDF = (item) => {
    setOpenModalPDF(true);
    setSelectItem(item)
  };

  const handleClosePDF = () => {
    setOpenModalPDF(false);
    setSelectItem(null)
  };

  const FileColumn = ({ value }) => {

    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
        {value ? (
          <CheckCircle
            style={{ color: "green", cursor: "pointer" }}
            onClick={() => handleClickOpenPDF(value)}
          />
        ) : (
          <Cancel
            style={{ color: "red", cursor: "default" }}
          />
        )}
      </div>
    );
  };

  return (
    <Fragment>
      <Modal open={openModalPDF} onClose={handleClosePDF}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '800px',
            backgroundColor: 'white',
            padding: 2,
            borderRadius: 1,
            boxShadow: 24,
            overflow: 'hidden',
          }}
        >
          <Typography variant="h6" gutterBottom>PDF View</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {selectItem && (
              <iframe
                src={selectItem}
                style={{ width: "100%", height: "500px" }}
                frameBorder="0"
                title="PDF Viewer"
              />
            )}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
            <Button onClick={handleClosePDF} color="primary">
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
      <Box sx={{ mt: 2 }}>
        <StyledCard>
          <StyledCardContent>
            <StyledWrapDataGrid>
              {selectedCompany ?
                <Detail
                  billingCompanys={data}
                  company={selectedCompany}
                  monthYear={dayjs(monthYear).date(1).format("YYYY-MM-DD")}
                  setSelectedCompany={setSelectedCompany}
                  handleAlertCompanyPage={handleAlert}
                  idBilling={eachData}
                  type={type}
                  setSeeMoreData={setSeeMoreData}
                />
                :

                <StyledDivDataGrid>

                  <div style={{ width: "100%" }}>
                    <DataGrid
                      dataSource={data ? data : []}
                      showBorders={false}
                      showColumnLines={false}
                      rowAlternationEnabled={true}
                      columnAutoWidth={true}
                      onExporting={onExporting}
                      // onRowClick={(v) => {
                      //   if (v && v.data && v.data.idBilling && v.data.idCompany && v.data.monthPeriod) {
                      //     setEachData(v.data.idBilling);
                      //     setSelectedCompany(v.data.idCompany);
                      //     setMonthYear(v.data.monthPeriod);
                      //     setSeeMoreData(false);
                      //   }
                      // }}
                      noDataText="ไม่มี Billing"
                      // onSelectionChanged={onSelectionChanged}
                    >
                      {/* <Selection
                      mode="multiple"
                    /> */}

                      <Column
                        caption={`${t("NoNumber")}`}
                        width={"auto"}
                        cellRender={rowIndex}
                        alignment="center"
                        cssClass="column-info"
                      />
                      <Column
                        caption={`${t("Name")}`}
                        dataField="name"
                        dataType="string"
                        width={"auto"}
                        cssClass="column-info"
                        cellRender={({ value, rowIndex }) => (
                          <div >
                            {value} <span style={{ color: 'grey', marginLeft: '6px' }}>{data[rowIndex].isDraft === 1 ? `(${t('Draft')})` : `(${t('Public')})`} </span>
                          </div>
                        )}
                      />
                      <Column
                        caption={`${t("Status")}`}
                        dataField="isApproved"
                        dataType="string"
                        width={"auto"}
                        cssClass="column-info"
                        alignment="center"
                        cellRender={({ value }) => (
                          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                            <StatusIcon value={value} />
                          </div>
                        )}
                        calculateFilterExpression={(filterValue) => {
                          if (filterValue === null) {
                            return ["isApproved", "=", null];
                          }
                          return ["isApproved", "=", filterValue];
                        }}
                        headerFilter={{
                          dataSource: [
                            { text: "No Response", value: null, icon: "help_outline", color: "gray" },
                            { text: "Approved", value: 1, icon: "check_circle", color: "green" },
                            { text: "Rejected", value: 0, icon: "cancel", color: "red" },
                          ],
                          allowSearch: false,
                          customizeText: ({ value }) => {
                            // Find the icon corresponding to the selected value
                            const item = [
                              { value: null, icon: "help_outline", color: "gray" },
                              { value: 1, icon: "check_circle", color: "green" },
                              { value: 0, icon: "cancel", color: "red" },
                            ].find((item) => item.value === value);

                            // Return the icon as text in the format that DevExtreme can handle
                            if (item) {
                              return (
                                `<span class="material-icons" style="color: ${item.color}; margin-right: 8px;">${item.icon}</span> ${item.text}`
                              );
                            }
                            return "";
                          },
                        }}
                      />

                      <Column
                        caption={`${t("Company")}`}
                        dataField="companyName"
                        dataType="string"
                        width={300}
                        minWidth={200}
                        cssClass="column-info"
                      />

                      <Column
                        caption={`${t("Amount")}`}
                        dataType="string"
                        cssClass="column-info"
                        dataField="amount"
                        width={"auto"}
                      >
                        <HeaderFilter allowSearch={true} />
                      </Column>
                      <Column
                        caption={`${t("File")}`}
                        dataType="string"
                        cssClass="column-info"
                        dataField="imageUrl"
                        alignment="center"
                        width={"auto"}
                        allowFiltering={false}
                        cellRender={({ value }) => <FileColumn value={value} />}
                      ></Column>

                      <Column
                        caption={`${t("Reason")}(${t("Company")})`}
                        dataType="string"
                        cssClass="column-info"
                        dataField="noteCompany"
                        width={"auto"}
                      />
                      <Column
                        caption={`${t("StartDate")}`}
                        dataType="string"
                        cssClass="column-info"
                        dataField="createAt"
                        width={"auto"}
                      />

                      <Column
                        caption={`${t("Action")}`}
                        dataType="string"
                        cssClass="column-info"
                        width={150}
                        alignment="center"
                        cellRender={({ row }) => (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                            <IconButton
                              color="primary"
                              onClick={() => handleEdit(row)}
                            >
                              <EditIcon />
                            </IconButton>

                            <IconButton
                              color="error"
                              onClick={() => handleDelete(row)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        )}
                      />


                      {/* <Export enabled={true} allowExportSelectedData={true} /> */}
                      <Paging defaultPageSize={10} />
                      <Pager
                        visible={(data && data.length > 0) ? true : false}
                        allowedPageSizes={[
                          10,
                          ...(data && data.length > 10) ? [20] : [],
                          ...(data && data.length > 20) ? [30] : []
                        ]}
                        showPageSizeSelector={true}
                        showNavigationButtons={true}
                        showInfo={true}
                      />
                      {/* <Selection mode="multiple" /> */}
                      <Sorting mode="multiple" />
                      <Scrolling columnRenderingMode="virtual" />
                      <FilterRow visible={false} />
                      <HeaderFilter visible={true} />

                      <SearchPanel visible={true} width={240} placeholder={`${t("Search")}...`} />
                      <Summary>
                        <TotalItem
                          column="totalSalary"
                          customizeText={customizeNumber}
                          summaryType="sum"
                        />
                      </Summary>
                    </DataGrid>
                  </div>
                </StyledDivDataGrid>
              }


              {/* <DialogEdit
              open={openDialog}
              handleClose={handleCloseDialog}
              selectedRowsData={selectedRowsData}
              handleChangeAlertType={handleChangeAlertType}
              handleOpenAlert={handleOpenAlert}
              handleCloseAlert={handleCloseAlert}
              handleGetAllDetail={handleGetAllDetail}
            /> */}
            </StyledWrapDataGrid>
          </StyledCardContent>
        </StyledCard>
        <AlertResponse
          open={openAlert}
          handleClose={() => {
            handleCloseAlert();
            handleChangeAlertType(false);
          }}
          alertType={alertType}
        />
      </Box>
    </Fragment>
  )
}

export default TableEmployee;

{/* <DialogEdit
              open={openDialog}
              handleClose={handleCloseDialog}
              selectedRowsData={selectedRowsData}
              handleChangeAlertType={handleChangeAlertType}
              handleOpenAlert={handleOpenAlert}
              handleCloseAlert={handleCloseAlert}
              handleGetAllDetail={handleGetAllDetail}
            /> */}
