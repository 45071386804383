// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login: "Sign in",
  logout: "Sign out",
  Hello: "Hello",
  EmployeeList: "Employee List",
  Download: "Download",
  Download2: "Download",
  Upload: "Upload",
  Add_Employee: "Add Employee",
  Employees: "Employees",
  Employee_Leaved: "Resign Employee",
  Total_Person: "Total Person",
  Search: "Search",
  Name: "Name",
  FullName: "Full Name",
  Company: "Company",
  All_Companies: "All Companies",
  No_Data: "No Data",
  Position: "Position",
  Section: "Section",
  Division: "Division",
  Department: "Department",
  DepartmentName: "Department",
  Report_To: "Report To",
  Employee_Type: "Employee Type",
  Edit_Data: "Edit Data",
  Add_Data: "Add",
  Delete_Employee: "Delete Employee",
  Success: "Success",
  Please_Contact_Admin: "Cause an error, please contact admin",
  Confirm: "Confirm",
  Cancel: "Cancel",
  OK: "OK",
  Employee_Data: "Employee Data",
  New_Company: "New Company",
  Works: "Works",
  Manager: "Manager",
  Employee_Experience: "Employee Experience",
  Adjust_Level: "Adjust Level",
  Adjust_Wage: "Adjust Wage",
  Select_File_Upload: "Select file to upload",
  Saving_Data: "Saving Data",
  Select_Company: "Select Company",
  Download_Form: "Download Form",
  Click_For_Select_File: "Click for select file",
  Errors: "Errors",
  Save_data_successfully: "Save data successfully",
  Homepage: "Home",
  Overview: "Overview",
  Status_Report: "Status Report",
  History_Status_Report: "History Status Report",
  Work_Time: "Work Time",
  Voyage: "Voyage",
  Voyage_In: "Record - Check In",
  Voyage_Out: "Record - Check Out",
  Access_Area: "Access Area",
  Shift_Management: "Shift Management",
  Payroll: "Payroll",
  Payroll_Management: "Payroll Management",
  Payslip: "Payslip",
  Sourcing: "Sourcing",
  Training: "Training",
  Report: "Report",
  ReportCenter: "Report center",
  History_Company: "History Company",
  Setting: "Setting",
  ThisFieldIsRequired: "This field is required",
  PleaseIDCard: "Please enter your 13-digit ID card number",
  PleasePhoneNumber: "Please enter a 10-digit phone number",
  AddEmpSuccess: "Add Employee Successfully",
  PleaseCompleteRequired: "Please complete the required information",
  GeneralInfo: "General Information",
  EmployeeType: "Employee Type",
  Job_Description: "Job Description",
  PayrollInfo: "Payroll",
  Education: "Education",
  Back: "Back",
  Next: "Next",
  Save: "Save",
  EmployeeID: "Employee ID",
  Prefix: "Prefix",
  Mr: "Mr.",
  Mrs: "Mrs.",
  Miss: "Miss",
  FirstName: "First Name",
  LastName: "Last Name",
  Eng: "Eng",
  Nickname: "Nickname",
  Gender: "Gender",
  Male: "Male",
  Female: "Female",
  Other: "Other",
  BirthDate: "Birth Date",
  IDCardNumber: "ID Card Number",
  PhoneNumber: "Phone Number",
  Email: "Email",
  Address: "Address",
  HouseNumber: "House Number",
  Village: "Village",
  VillageNo: "Village No.",
  Road: "Road",
  Alley: "Alley",
  PostalCode: "Postal Code",
  SubdistrictArea: "Sub-district / Sub-area",
  DistrictArea: "District / Area",
  Province: "Province",
  Nationality: "Nationality",
  MaritalStatus: "Marital Status",
  Single: "Single",
  Married: "Married",
  Widowed: "Widowed",
  Divorced: "Divorced",
  Separated: "Separated",
  Passport: "Passport",
  WorkPermit: "Work Permit",
  EmergencyContact: "Emergency Contact",
  EmergencyPhone: "Emergency Phone",
  EmergencyRelationship: "Emergency Relationship",
  WorkType: "Work Type",
  PaymentType: "Payment Type",
  HalfMonth: "Half Month",
  Monthly: "Monthly",
  Daily: "Daily",
  Payment: "Payment",
  HiringDate: "Hiring Date",
  OwnerCompany: "Company",
  JobGroup: "Job Group",
  JobLevel: "Job Level",
  PersonalLevel: "Personal Level",
  FieldOffice: "Field Office",
  WorkingType: "Working Type",
  ShiftType: "Shift Type",
  ShiftName: "Shift Name",
  WorkingLocation: "Working Location",
  MainWorkingLocation: "Main Working Location",
  Finger: "Finger",
  Yes: "Yes",
  No: "No",
  MethodAttendance: "Method Attendance",
  MainLocation: "Main Location",
  Coordinates: "Coordinates",
  AllLocation: "All Location",
  ContractTerminate: "Contract Terminate",
  Unspecified: "Unspecified",
  DateSpecify: "Date Specify",
  AddPosition: "Add Position",
  ManagerLevel: "Manager Level",
  Salary: "Salary",
  SalaryBilling: "SalaryBilling",
  ChargeSalary: "Charge Salary",
  OT: "OT",
  ChargeHire: "Charge Hire",
  ChargeCompensation: "Charge Compensation",
  ShiftCost: "Shift Cost",
  ChargeShiftCost: "Charge Shift Cost",
  BookBank: "Book Bank",
  BookBankBranch: "Book Bank Branch",
  BookBankID: "Book Bank ID",
  ReportBankName: "Bank name",
  ReportBankID: "Bank code",
  ReportBookBankID: "Book bank ID",
  ReportBankPayneeName: "Employee name",
  ReportBankRef: "Reference",
  AddEducation: "Add Education",
  NoEducationalInformation: "No Educational Information",
  Delete: "Delete",
  To: "To",
  NotSpecified: "Not Specified",
  NoUniversitySpecified: "No University Specified",
  GPA: "GPA",
  StartYear: "Start Year",
  Year: "Year",
  EndYear: "End Year",
  Degree: "Degree",
  PrimarySchool: "Primary School",
  JuniorHighSchool: "Junior High School",
  SeniorHighSchool: "Senior High School",
  VocationalCertificate: "Vocational Certificate",
  HighVocationalCertificate: "High Vocational Certificate",
  BachelorDegree: "Bachelor’s degree",
  MasterDegree: "Master’s degree",
  DoctorDegree: "Doctor’s degree",
  University: "University",
  Faculty: "Faculty",
  Major: "Major",
  BusinessPartners: "Business partners",
  All: "All",
  Ltd: "Ltd.",
  Person: "Person",
  ShortcutMenu: "Quick Menu",
  OTAndShift: "OT and Shift",
  ContractCompany: "Contract Company",
  ContractEmployee: "Contract Employee",
  TimeManagement: "Time Management",
  Personal: "Personal",
  Unsigned: "Unsigned",
  LeaveNotFound: "leave not found",
  WorkIn: "Work In",
  WorkOut: "Work Out",
  SearchDate: "Search Date",
  LeaveWork: "Leave Work",
  AllTypes: "All Types",
  StartDate: "Start Date",
  EndDate: "End Date",
  TimeTable: "Time Table",
  SearchEmp: "Search Employee",
  SelectEmp: "Select Employee",
  Month: "Month",
  CustomerOverview: "Customer Overview",
  Income: "Income",
  Expense: "Expense",
  Overtime: "Overtime",
  WorkTimeOverview: "Work Time Overview",
  CostOverview: "Cost Overview",
  LoadingData: "Loading Data",
  NoData: "No Data",
  Type: "Type",
  Start: "Start",
  FullDay: "Full Day",
  End: "End",
  Reason: "Reason",
  SalaryTotal: "Salary Total",
  Baht: "Baht",
  OTOver36: "OT Over 36 Hours",
  OTTotal: "OT Total",
  Yearly: "Yearly",
  ExpensesByType: "Expenses By Type",
  leaveAbsent: "Leave",
  Top5LeaveAbsent: "Top 5 Leave Absent",
  Days: "Days",
  to: "to",
  NoAbsentWork: "No Absent Work",
  EmployeeIn: "Employee In",
  EmployeeOut: "Employee Out",
  AverageAge: "Average Age",
  WorkExperienceAvg: "Average Work Experience",
  NewEmployeeList: "New Employee List",
  Customer: "Customer",
  Amount: "Amount",
  Difference: "Difference",
  TotalExpenses: "Total Expenses",
  TotalIncome: "Total Income",
  NoCompanyList: "No Company List",
  requestWorkingTime: "working time",
  OtRequest: "Overtime",
  LeaveRequest: "Leave",
  WaitingApprovalWorkingTime: "Waiting approval working time request",
  Items: "Items",
  HistoryApprovalRequestTime: "History approval working time request",
  RequestType: "Request Type",
  StartTime: "Start Time",
  EndTime: "End Time",
  More: "More",
  ApprovalDate: "Approval Date",
  WaitingApprovalWorkOT: "Waiting approval over time request",
  HistoryApprovalOT: "History approval over time request",
  Hrs: "Hrs.",
  OtherReason: "Other Reason",
  WaitingApprovalLeaveRequest: "Waiting approval leave request",
  HistoryApprovalLeaveRequestTime: "History approval leave request",
  EditWorkTime: "Edit Working Time",
  ShiftList: "Shift List",
  Day: "Day",
  Mid: "Mid",
  Night: "Night",
  List: "List",
  YearlyShiftSchedule: "Yearly Shift Schedule",
  EditShiftSchedule: "Edit Shift Schedule",
  PleaseSelectCompany: "Please Select Company",
  SwitchShiftPattern: "Switch Shift Pattern",
  SwitchShift: "Switch Shift",
  Total: "Total",
  NoEmpInThisShift: "No employee in shift",
  ShiftPlan: "Shift Plan",
  NoHistoryOfShiftSwitch: "No History Of Shift Switch",
  CancelShiftPlan: "Cancel shift plan",
  AreYouSure: "Are you sure",
  YouWantTo: "You want to",
  YouWantDelete: "You want to delete",
  ForTheShiftPlan: "for the shift plan",
  ConfirmDelete: "Confirm Delete",
  Edit: "Edit",
  FailedToEditData: "Failed to edit data",
  UnableToSwitchShift:
    "Unable to switch shifts because employees have already switched shifts",
  SwitchWorkPattern: "Switch work pattern",
  SwitchShiftWork: "Switch shift work",
  Temporary: "temporary",
  SelectShift: "Select shift",
  SelectAll: "Select All",
  NoEmployeeShiftSwitch: "No employee shift switch",
  RestoreDefault: "Restore default",
  GetStarted: "Get started",
  SummaryOFOTandShift: "Summary of OT and shift",
  EmployeeGroup: "Employee group",
  NormalEmp: "Normal Employee",
  NewEmp: "New Employee",
  ThisMonth: "This month",
  lastMonth: "Last month",
  HalfMonthlyPayment: "Half monthly payment",
  PayrollRound: "Payroll round",
  Take10Mint: "It takes about 10 minutes to process, please wait",
  workType: "Work Type",
  PaymentRound: "Payment round",
  WorkingDay: "Working Day",
  Absent: "Absent",
  LateAndEarly: "Late/Early",
  Late: "Late",
  EarlyOut: "EarlyOut",
  Minute: "Minute",
  MidMonth: "Mid month",
  EndOfMonth: "End of month",
  DayShift: "Day Shift",
  MidShift: "Mid Shift",
  NightShift: "Night Shift",
  MonthlyPayment: "Monthly payment",
  ActuallyPaid: "Actually Paid",
  ContractTerminatedDate: "Contract terminated date",
  PayrollRun: "Payroll",
  NewSalary: "New salary",
  ItemName: "Item Name",
  Draft: "Draft",
  AccountingPeriod: "Accounting Period",
  TotalPayroll: "Total",
  CreateNewItem: "Create new item",
  Addition: "Addition",
  Deduction: "Deduction",
  Regular: "Regular",
  Once: "Once",
  Code: "Code",
  TaxCalculate: "Tax Calculate",
  Continuously: "Continuously",
  Rate: "Rate",
  CodeLength46: "Code length 4-6 characters",
  CreateADDE: "Create Addition/Deduction",
  ItemType: "Item Type",
  orNot: "?",
  AmountIncreaseDecrease: "Amount Increase/Decrease",
  BillingChargeCalculation:"Billing Charge Calculation",
  ChargeAmount:"Charge Amount",
  CalculatedAsAPercentage:"Calculated as a Percentage",
  SpecifyNumber: "Specify Number",
  PercentageOfSalary: "Percentage of Salary",
  CreatePayroll: "Create Payroll",
  DailyAndMonthly: "Daily and monthly",
  PayrunType: "Payrun Type",
  MonthPayrun: "Month",
  WeekPayrun: "Week",
  MonthPayrunAndWeekPayrun: "Month and Week",
  FirstRound: "First Round",
  SecondRound: "Second Round",
  SalaryRoundAndOT: "Salary round and OT",
  DownloadTemplate: "Download Template",
  Create: "Create",
  Normal: "Normal",
  CompanyInfo: "Company Information",
  BusinessGroup: "Business group",
  OrganizationSize: "Organization size",
  Large: "Large",
  JobOpenings: "Job openings",
  CompanyBranch: "Company Branch",
  Branch: "Branch",
  ViewAllBranches: "View All Branches",
  Contract: "Contract",
  ContactInfo: "Contact information",
  About: "About",
  Benefits: "Benefits",
  ContactName: "Contact Name",
  ContractName: "Contract Name",
  SearchBranch: "Search Branch",
  AllInfo: "All information",
  OfficialReport: "Official report",
  CompanyName: "Company Name",
  CompanyAbbreviation: "Company Abbreviation",
  AuthorizedSign: "Authorized Signature",
  ConfirmToDelete: "Confirm to Delete",
  DoUWantDelete: "Do you want to delete?",
  WitnessSignature: "Witness Signature",
  Expertise: "Expertise",
  Expenses: "Expenses",
  Service: "Service",
  CompanyCode: "Company Code",
  TaxpayerIdentificationNumber: "Taxpayer Identification Number",
  NameAuthorized: "Name of authorized person",
  PositionAuthorized: "Position of authorized person",
  WitnessName: "Witness Name",
  WitnessPosition: "Witness Position",
  RegisteredCapital: "Registered capital",
  ProvidentFundAccountNumber: "Provident fund account number",
  YearEstablishment: "Year of establishment",
  CompanyIncome: "Company income",
  LastYear: "Last year",
  ManpowerSize: "Manpower Size",
  PleaseCompanyCode: "Please enter the company code",
  PleaseEnter: "Please enter",
  SocialSecurityAccount: "Social Security Account",
  HeadOffice: "Head Office",
  District: "District",
  OtherBranch: "Other Branch",
  AddBranch: "Add Branch",
  BranchName: "Branch Name",
  BranchCode: "Branch Code",
  EditBranch: "Edit Branch",
  PleaseEnterValidEmail: "Please enter a valid email address",
  EditHeadOffice: "Edit Head Office",
  chargeWage: "Charge Wage",
  chargeOt: "Charge OT",
  chargeCost: "Charge Cost",
  chargeCompensation: "Charge Compensation",
  AddService: "Add Service",
  BankName: "Bank Name",
  BankAccount: "Bank Account",
  BranchAddress: "Branch Address",
  EditBank: "Edit Bank",
  SelectBank: "Select Bank",
  SystemSetting: "System Settings",
  GPSLocation: "GPS Location",
  HolidayCompany: "Holiday Company",
  LeaveDay: "Leave",
  ManageJobs: "Manage jobs",
  ManageJobLevel: "Manage job level",
  ManageBranch: "Manage branch",
  ManageSubsidiary: "Manage subsidiary",
  ProvidentFund: "Provident fund",
  TaxDeductible: "Tax deductible",
  Admin: "Admin",
  AreaPositionList: "Area/Position List",
  AddNewArea: "Add new area",
  LastUpdate: "Last Update",
  AreaPositionName: "Area/Position Name",
  AddLocation: "Add Location",
  EditLocation: "Edit Location",
  LocationName: "Location Name",
  Today: "Today",
  NoHoliday: "NoHoliday",
  Calendar: "Calendar",
  AddCalendar: "Add Calendar",
  UploadHoliday: "Upload Holiday",
  EmpCantSaved: "List of employees who cannot be saved",
  Close: "Close",
  PublicHolidays: "Public Holidays",
  AddLeaveDay: "Add Leave Day",
  PaidLeave: "Paid Leave",
  UnpaidLeave: "Unpaid Leave",
  AfterProbation: "After Probation",
  StartWork: "Start Work",
  HalfDay: "Half Day",
  AllGender: "All Gender",
  LeaveDayName: "Leave Day Name",
  LeaveType: "Leave Type",
  RequestLeaveAfterDate: "Request Leave After Date",
  LeaveRights: "Leave rights",
  Fixed: "Fixed",
  Step: "Step",
  AddCondition: "Add Condition",
  Condition: "Condition",
  Experience: "Experience",
  MoreThan: "More than",
  NumberLeaveDays: "Number of leave days",
  AccumulateNotMoreThan: "Accumulate not more than",
  MinimumLeave: "Minimum leave",
  ApprovalProcess: "Approval Process",
  None: "None",
  Notification: "Notification",
  Approval: "Approval",
  MinLeave: "Min leave",
  AddJobPosition: "Add Job Position",
  NewPosition: "New Position",
  Add: "Add",
  EditLevel: "Edit Level",
  AddJobLevel: "Add Job Level",
  NoAdditionalItem: "There are no additional items",
  InsertJobLVName: "Insert Job Level Name",
  InsertMinSalary: "Insert Minimum Salary",
  InsertMaxSalary: "Insert Maximum Salary",
  ExtraCost: "Extra Cost",
  Approve: "Approve",
  Examine: "Examine",
  ManagerList: "Manager List",
  OperatingPower: "Operating power",
  EditOperatingPower: "Edit Operator Power",
  ManagePayroll: "Manage Payroll",
  OvertimeHolidayPay: "Overtime pay and holiday pay",
  OvertimeHolidayPayBilling: "Overtime pay and holiday pay (Billing)",
  OvertimePayOnNormalWorkingDays: "Overtime pay on normal working days",
  Times: "Times",
  HolidayOvertimePay: "Holiday overtime pay",
  HolidayWorkAllowance: "Holiday work allowance",
  ShiftFee: "Shift fee",
  PayrollSetting: "Payroll Setting",
  DecimalRounding: "Decimal Rounding",
  NotRounded: "Not Rounded",
  Round: "Round",
  RoundUp: "Round Up",
  RoundDown: "Round Down",
  Decimal: "Decimal",
  PaymentPeriod: "Payment Period",
  OnceAMonth: "Once a month",
  TwoTimesAMonth: "2 times a month",
  FirstCutOff: "First Cut Off",
  FirstPayDate: "First Pay Date",
  FirstPaySlipDate: "First Payslip Date",
  FirstPaySlipTime: "First Payslip Time",
  Calculate: "Calculate",
  NotCalculate: "Not Calculate",
  SecondCutOff: "Second Cut Off",
  SecondPayDate: "Second Pay Date",
  SecondPayslipDate: "Second Payslip Date",
  SecondPayslipTime: "Second Payslip Time",
  DailyWeekWorking: "Daily Week Working",
  PresentRound: "Present Round",
  PreviousRound: "Previous Round",
  DailyWeekOT: "Daily Week OT",
  DailyWeekIsWorkingOmit: "Daily Week Working Omit",
  NotCountingBackwards: "Not counting backwards",
  Back1Round: "Back 1 Round",
  DailyWeekIsWorkingOTOmit: "Daily Week Working OT Omit",
  MonthlyWeekWorking: "Monthly Week Working",
  MonthlyWeekOT: "Monthly Week OT",
  MonthlyWeekIsWorkingOmit: "Monthly Week Working Omit",
  MonthlyWeekIsWorkingOTOmit: "Monthly Week Working OT Omit",
  DailyMonthOT: "Daily Month OT",
  DailyMonthIsWorkingOmit: "Daily Month Working Omit",
  DailyMonthIsOTOmit: "Daily Month OT Omit",
  MonthlyMonthOT: "Monthly Month OT",
  MonthlyMonthWorkingOmit: "Monthly Month Working Omit",
  MonthlyMonthOtOmit: "Monthly Month Ot Omit",
  AddPF: "Add ProvidentFund",
  MinYearOfWork: "Minimum years of work",
  MaxEmployeePF: "Maximum Employee Portion",
  MinEmployeePF: "Maximum Employee PF",
  MaxVendorPF: "Maximum Company Portion",
  DateCanChange: "Date can change",
  AddChangeDate: "Add change date",
  DueDate: "Due date",
  RoundOf: "Round",
  UnderSchedule: "Under Schedule",
  NoChangeDate: "No change date",
  EditPF: "Edit Provident Fund",
  NotLessThan0Year: "Number of years not less than 0 years",
  PleaseFillNotLessThan: "Please fill in the amount not less than",
  EditChangeSchedule: "Edit Change Schedule",
  PleaseEnterCorrectDate: "Please enter the correct date.",
  CantSelectStartDate: "Start date cannot be selected",
  CantSelectEndDateBeforeStartDate: "End date cannot be selected before the start date",
  EnterValidDate: "Please enter a valid date.",
  PastDateCantSelect: "The past date cannot be selected.",
  EditStartDate: "Edit Start Date",
  EditEndDate: "Edit End Date",
  EditTaxDeductible: "Tax deductible date",
  BasicInformation: "Basic information",
  ChangeInformation: "Change information",
  PersonalInfo: "Personal",
  JobInfo: "Job",
  PaymentInfo: "Payment",
  ExperienceInfo: "Experience",
  Age: "Age",
  EditEmpProfile: "Edit Employee Profile",
  Transfer: "Transfer",
  Move: "Move",
  Employment: "Employment",
  Probation: "Probation",
  Promotion: "Promotion",
  PaymentChange: "Payment Change",
  Terminated: "Terminated",
  ResetPass: "Reset Password",
  ChangePass: "Change Password",
  CurrentAddress: "Current Address",
  CriminalHistory: "Criminal History",
  Thai: "Thai",
  EditCurrentAddress: "Edit Current Address",
  EditEmergencyContact: "Edit Emergency Contact",
  EditBasicInfo: "Edit Basic Information",
  EditCriminalHistory: "Edit Criminal History",
  RenewalDate: "Renewal Date",
  EmploymentTime: "Employment Time",
  AllMethod: "All Method",
  NoNumber: "No.",
  NoManageHasBeenSpecify: "No supervisor has yet been specified.",
  AddManager: "Add Manager",
  PositionManager: "Position Manager",
  InvalidEmail: "Invalid email",
  EditManagerInfo: "Edit Manager Information",
  EditJobInfo: "Edit Job Information",
  WorkingTimeInfo: "Working Time Information",
  WorkDay: "Work Day",
  WorkTimePerDay: "Work Time Per Day",
  WorkTimeInTimeOut: "Time In - Time Out",
  NoWorkingTimeFrame: "No working time frame",
  OperationFee: "Operation fee",
  ChargeHireFee: "Charge Hire",
  PaymentTypeName: "Payment Type",
  Per: "per",
  BankInfo: "Bank Information",
  BankFiles: "For export bank report",
  Percent: "Percent",
  EditBankInfo: "Edit Bank Information",
  EditCostCenter: "Edit Cost Center",
  EditOperation: "Edit Operation",
  ChangingCumulativeSuccess: "Changing the cumulative success rate",
  FailedChangeCumulative: "Failed to change cumulative rate.",
  RemoveSuccessfulBeneficiary: "Remove Successful Beneficiary",
  FailedRemoveBeneficiary: "Failed to remove beneficiary",
  DateRetirementFund: "Date of retirement in the fund",
  FundEntryDate: "Fund entry date",
  CumulativeRate: "Cumulative Rate",
  ContributionCompany: "Contribution to the company",
  CurrentCumulativeRate: "Current cumulative rate",
  NewCumulativeRate: "New cumulative rate",
  PleaseSelectNewAccumulatedRate: "Please select a new accumulated rate.",
  EditableRange: "Editable range",
  Beneficiary: "Beneficiary",
  AddBeneficiary: "Add Beneficiary",
  NoBeneficiaryList: "No Beneficiary List",
  SuccessfulBeneficiary: "Successful Beneficiary",
  UnsuccessfulBeneficiary: "Unsuccessful Beneficiary",
  EditBeneficiary: "Edit Beneficiary",
  Relationship: "Relationship",
  Percentage: "Percentage",
  Number: "Number",
  PleaseCorrectAmount: "Please enter the correct amount",
  ConfirmAccumulativeRateChange: "Confirm accumulative rate change",
  SelectStartDate: "Select start date",
  DateAt: "Date",
  EditProvidentFund: "Edit Provident fund",
  EducationHistory: "Education history",
  EditHistory: "Edit History",
  AddQualification: "Add Qualification",
  NoEducationInfo: "No Education Information",
  NoWorkExperienceInfo: "No work experience information",
  UnderPrimarySchool: "Under Primary school",
  CurrentWorkLocation: "Current work location",
  PositionName: "Position Name",
  SummaryWorkPast5Years: "Performance for the past 5 years",
  LatestPerformance: "Latest performance",
  Hide: "Hide",
  AllWorks: "All Performance",
  Level: "Level",
  EditWorks: "Edit performance",
  CumulativeRights: "Cumulative rights",
  Used: "Used",
  Remaining: "Remaining",
  Current: "Current",
  EffectiveDate: "Effective date",
  Remark: "Remark",
  ChangeLog: "Change log",
  ProbationResult: "Probation result",
  PassProbation: "Pass probation",
  NotPassProbation: "Not pass probation",
  ResultNotYetBeenConcluded: "Results have not yet been concluded",
  NewLevel: "New level",
  SelectLevel: "Select level",
  Pay: "Pay",
  CurrentSalary: "Current Salary",
  Payments: "Payments",
  TerminatedDate: "Terminated Date",
  TerminatedInfo: "Terminated Information",
  OutOfContract: "Out of contract",
  GetNewJob: "Get new job",
  PersonalBusiness: "Personal Business",
  PersonalReasons: "Personal Reasons",
  LayOff: "Lay-Off",
  New: "New",
  Select: "Select",
  EmployeeHistory: "Employee History",
  CompanyProfile: "Company Profile",
  PersonalProfile: "Personal Profile",
  Timeline: "Timeline",
  RequestList: "Request List",
  SummaryTime: "Summary Time",
  PaySlip: "Pay Slip",
  MyDocument: "My Document",
  WorkTimeRequest: "Work Time Request",
  OvertimeRequest: "Overtime Request",
  EmpLeaveRequest: "Leave Request",
  Date: "Date",
  ShiftTable: "Shift",
  ShiftTime: "Shift Time",
  ClockIn: "Clock-in",
  ClockOut: "Clock-out",
  Status: "Status",
  TimeNotFound: "Time Not Found",
  Holiday: "Holiday",
  CompensateDate: "Compensate Date",
  Lists: "List",
  AddTimeline: "Add Timeline",
  WaitingApproval: "Waiting Approval",
  Disapproval: "Disapproval",
  WorkingTime: "Working Time",
  Approver: "Approver",
  Description: "Description",
  CreateDate: "Create Date",
  OperationalRequirement: "Operational requirement",
  ShiftWorkOnDayOff: "Shift Work on day off",
  OTLunchBreakRequest: "OT Lunch Break Request",
  OtherCompanyActivities: "Other Company Activities",
  OffSiteWork: "Off-site Work",
  ClockingMachineError: "Clocking Machine Error",
  ProblemFingerprint: "Problem Fingerprint",
  EmergencyCall: "Emergency Call",
  OtherRT: "Other",
  AdditionalReasons: "Additional Reasons",
  TotalHours: "Total Hours",
  CreateLeave: "Create Leave",
  AllLeave: "All Leave",
  LeaveCalendar: "Leave Calendar",
  LeaveHistory: "Leave History",
  NoLeaveHistory: "No leave history",
  ConfirmLeave: "Confirm Leave",
  RemainingLeaveRights: "Remaining leave rights",
  SpecifyTime: "Specify Time",
  AttachFile: "Attach File",
  StatusRequest: "Status Request",
  CancelRequest: "Cancel Request",
  WaitingForFirstApprover: "Waiting for the 1st approver",
  Request: "Request",
  ApprovedBy: "Approved by",
  ReasonForDisapproval: "Reason for disapproval",
  WorkTimeSummary: "Work Time Summary",
  Hours: "Hours",
  SummaryDeduction: "Summary of Deduction",
  PayDate: "Pay Date",
  TotalAddition: "Total Addition",
  TotalDeduction: "Total Deduction",
  Net: "Net",
  CumulativeAddition: "Cumulative Addition",
  CumulativeWithholdingTax: "Cumulative withholding tax",
  AccumulatedSocialSecurity: "accumulated social security",
  AccumulatedProvidentFund: "Accumulated Provident Fund",
  IncompleteInfo: "Incomplete Information",
  CertificateSalary: "Certificate Salary",
  CertificateEmp: "Certificate Emp",
  Tawi50: "50 Tawi",
  SelectYear: "Select Year",
  PND91: "PND91",
  EmployeeInTeam: "Employee In Team",
  ApprovalList: "Approval List",
  ApprovalHistory: "Approval History",
  OvertimeTable: "Overtime",
  ConfirmItemDisapproval: "Confirm item disapproval",
  NotRequired: "Not required",
  Incorrect: "Incorrect",
  DateTimeIncorrect: "Date or time is incorrect",
  OvertimeRequestedCantWorkingHours:
    "Overtime work cannot be requested during normal working hours.",
  WorkingDate: "Working Date",
  Open: "Open",
  Filter: "Filter",
  AllDepartment: "All Department",
  Vendor: "Vendor",
  AllVendor: "All Vendor",
  AllJobGroup: "All Job Group",
  AllLevel: "All Level",
  AdditionalInformation: "Additional information",
  ContractEmployeeList: "Contract employee list",
  SearchContractEmployee: "Search contract name/project name",
  AddContractEmployee: "Add contract/project",
  AddEditEmployee: "Add/Remove employee",
  ChangeEmployee: "Change employee",
  areYouSure: "Are you sure?",
  pressBtn: "Press the search button",
  round1: "Round 1",
  round2: "Round 2",
  startEndDate: "Start date - End date",
  employeeMinimumPerDays: "Employeem Minimum (per day)",
  costCharge: "Cost charge (per month)",
  searchName: "Search name",
  searchFirstLast: "Search Firstname - Lastname",
  permanent: "Permanent",
  reserve: "Reserve",
  positionDepartment: "Position/Department",
  searchMonth: "Search month",
  employeeContract: "Employee Contract",
  addEmployeeContract: "Add Contract",
  summarizeAbsent: "Absent/Late summarize",
  summarizeProbation: "Probation summarize",
  summarizeTerminateContract: "Terminate contract summarize",
  summarizeNewEmployee: "New Employee summarize",
  reportResign: "Resign employee report",
  employeeCoreDate: "Employee data",
  reportContractCompany: "Manpower contract report",
  reportWorkingTime: "Working time report",
  ReportAbsent: "Absent report",
  ReportLate: "Late report",
  ReportLeave: "Leave report",
  ReportAttendance: "Attendance report",
  ReportRequest: "Request report",
  ReportOT: "OT/Shift report",
  ReportRaise: "Raise salary report",
  ReportRaise: "Raise salary report",
  ManualTimesheet: "Manual Timesheet",
  checkIn: "Check In",
  checkOut: "Check Out",
  manualTable: "Timeline",
  attendanceDaily: "Daily",
  attendanceWeekly: "Weekly",
  attendanceMonthly: "Monthly",
  monthWork: "Working month",
  fillInData: "Please fill in your data",
  confirmChangePassword: "Confirm change password",
  confirmNewPassword: "Comfirm new password",
  newPassword: "New password",
  currentPassword: "Current password",
  rolePassword1: "Password must be between 8-16 characters in length.",
  rolePassword2:
    "Password must contain at least one letter and have one or more numbers.",
  paymentType: "Payment type",
  paymentRound: "Payment round",
  sumOt: "Total overtime",
  sumShift: "Total shift",
  totalEarnings: "Total earnings",
  totalDeductions: "Total deductions",
  ProvidentFundName: "Provident Fund (Name)",
  ProvidentFundCompany: "Company Provident fund",
  ProvidentFundPersonal: "Personal provident fund",
  socialSecurityPersonal: "Social security",
  tax: "Tax",
  net: "Net",
  cancelPayroll: "Cancel payroll",
  closePayroll: "Close payroll",
  exportBankFile: "Export bank file",
  exportStatutoryFile: "Export statutory file",
  socialSecurity: "Social security",
  PND1: "Tax PND1",
  performance: "Performance",
  Time: "Time",
  Weekly: "Weekly",
  createBilling: "Create Billing",
  AccessArea: "Access Area",
  AccessTime: "Access Time",
  GeneralInformation: "General Information",
  ExpenseLocation: "Location",
  VatType: "Vat Type",
  Currency: "Currency",
  CurrencyRate: "Currency Rate",
  ExpenseList: "List",
  Unit: "Unit",
  SummaryList: "Summary List",
  ExpenseNet: "Net",
  TotalValue: "Total",
  TotalVat: "Total vat",
  TotalWithholding: "Total withholding",
  RemarkMore: "Remark(More)",
  SelectApprover: "select approver",
  SelectReviewer: "select reviewer",
  RightsHolder: "Rights Holder",
  LocationList: "Location List",
  RowPerPage: "Rows per page",
  TotalDistance: "Total distance",
  NetDistance: "Net distance",
  Km: "Km",
  PersonalUseage: "Personal usage",
  WorkingUseage: "Working Useage",
  TotalDays: "Total days",
  AllowancePerDay: "Allowance per day",
  GorvermentAllowanceRate: "Gorvermental allowance rate",
  SurplusAllowance: "Surplus allowance(will be used to calculate income tax)",
  NetAllowance: "Net allowance",
  RightsType: "Rights Type",
  DocumentDate: "Document date",
  Location: "Location",
  SentForApproval: "Sent For Approval",
  SaveDraft: "Save as Draft",
  ClearData: "Clear data",
  ReplyComment: "Reply Comment",
  DocumentId: "DocumentId",
  Withdrawal: "Withdrawal",
  HelpingSurplus: "Helping surplus",
  File: "File",
  NoFile: "NoFile",
  lastUpdateDate: "Last update date",
  Details: "Details",
  ExpenseRequest: "Expense Request",
  ApprovalHistory: "Approval History",
  ServiceAndGoods: "Service And Goods",
  Allowance: "Allowance",
  ExpenseType: "Expense type",
  SelectExpenseType: "Select Expense Type",
  ActionHistory: "Action History",
  ActionDate: "Action date",
  ActionBy: "Action by",
  ExpenseReview: "Expense Review",
  ExpenseExport: "Expense Export",
  ExpenseHistory: "Expense History",
  ExpenseCompleted: "Completed",
  ExpenseRejected: "Rejected",
  WaitingForAdmin: "Waiting For Admin",
  WaitingForReview: "Waiting For Review",
  SelectStatusType: "Select status",
  Reject: "Reject",
  ApprovedPay: "Approve",
  DirectPayment: "Direct Payment",
  PayrollAdditionPayment: "Payroll Addition Payment",
  DeleteDraft: "Delete draft",
  DeleteDraftFormDialogTitle: "Do you want to delete this draft?",
  DraftFormDialogTitle: "Do you want to save this draft?",
  SentFormDialogTitle: "Do you want to submit this for approval?",
  DeleteDraftFormDialogDes: "All data will be deleted.",
  DraftFormDialogDes: "All data will be saved as a draft.",
  SentFormDialogDes: "Please review all data before submitting for approval.",
  ClearFormDialogTitle: "Do you want to clear all data?",
  ClearFormDialogDes: "All data will be cleared to default values.",
  RejectFormDialogTitle: "Do you want to reject this request?",
  ApproveFormDialogTitle: "Do you want to approve this request?",
  RejectFormDialogDes: "This request will be rejected.",
  ApproveFormDialogDes:
    "This request will be approved and sent to the accounting.",
  HoldFormDialogTitle: "Do you want to request edits for this request?",
  AccApproveFormDialogTitle: "Do you want to approve this request?",
  HoldFormDialogDes:
    "This request will be sent back to the requester for edits.",
  AccApproveFormDialogDes:
    "This request will be approved for payment processing.",
  AddExpense: "Add new expense",
  PriceBeforeVat: "Price Before Vat",
  Withholding: "Withholding",
  UnitPrice: "Unit Price",
  GoodsServices: "Goods/Services",
  StartLocation: "Start Location",
  StopLocation: "Stop Location",
  StartMile: "Start Mileage",
  StopMile: "Stop Mileage",
  // StartDate: "Start Date",
  // EndDate: "End Date",
  DateAmount: "Number of Days",
  CancelFormDialogTitle:
    "Do you want to cancel the approval request for this item?",
  CancelFormDialogDes: "This request will be canceled and saved as a draft.",
  UploadHistory: "Upload to store data as history",
  UploadEdit: "Upload to edit data",
  UploadInsert: "Upload to add new data",
  SelectType: "Select Type",
  AdjustSalary: "Adjust Salary",
  PassportNumber: "Passport Number",
  WorkPermitNumber: "WorkPermit Number",
  CompanyCharge: "Company Charge",
  PerformanceReview: "Performance Review",
  TerminateDate: "Terminate Date",
  RecordAttendance: "Record Attendance",
  Wages: "Wages",
  ResignDate: "Resign Date",
  TransferPosition: "Transfer",
  HolidayName: "Holiday Name",
  HolidayNameEnglish: "Holiday Name (English)",
  DateHoliday: "Holiday Date",
  NameOfHolidayCompensate: "Compensate Holiday Name",
  CompensateHolidayNameEnglish: "Compensate Holiday Name (English)",
  CompensateHolidayDate: "Compensate Holiday Date",
  Manage: "Manage",
  ListOfAnnualHolidays: "Annual Holidays",
  Use: "Use",
  NotInUse: "Not in use",
  Unlimited: "Unlimited",
  EditManager: "Edit Manager",
  AddManager: "Add Manager",
  confirmResetPassword: "confirm to reset password",
  FailToResetPassword: "Fail to reset password",
  PasswordIsReseted: "Password is reseted",
  HalfHour: "Half Hour",
  Hour: "Hour",
  TotalLeaveDays: "Total Leave Days",
  AccumulateNextRoundNotMoreThan: "Accumulate next round not more than",
  CanRequestLeaveAfter: "Can Request Leave After",
  MinimumLeaveAllowed: "Minimum Leave Allowed",
  LeaveIn: "Leave in",
  LeaveName: "Leave Name",
  LeaveNameOptional: "Leave Name (Optional)",
  NotPaying: "No paid",
  FirstDayOfService: "First Day Of Service",
  TypeOfLeaveDays: "Type Of Leave Days",
  YearOfService: "Year Of Service",
  CalProRateLessThanOneYear: "Calculate Pro Rate (worked for less than 1 year)",
  ConditionType: "Condition Type",
  SinceServiceOfYear: "Since Service Of Year",
  UntilServiceOfYear: "Until Service Of Year",
  CannotCarryOverLeaveNextRound: "Can not carry over leave to the next round.",
  EditLeaveDay: "Edit Leave Day",
  RoundsDecimalIsGreater: "Rounds to an integer where the decimal is greater.",
  ThereIsNoRounding: "There is no rounding.",
  RoundWhenGreaterThan: "Round When Greater Than",
  RoundUpInAllCases: "Round Up In All Cases",
  IsPaid: " Payment",
  DeleteLeave: "Delete Leave",
  Rounding: "Rounding",
  OnCondition: "On condition",
  ProbationResult: "Probation result",
  PassedProbation: "Passed probation",
  NotPassedProbation: "Not passed probation",
  Assessor: "Assessor",
  EditProbationResult: "Edit probation result",
  WaitingForEvaluation: "Waiting for evaluation",
  ProbationReviewQuestion: "Probation review question",
  ActiveQuestion: "Active question",
  CanceledQuestion: "Canceled question",
  Active: "Active",
  Inactive: "Inactive",
  Question: "Question",
  AddQuestion: "Add question",
  Strength: "Strength",
  Weakness: "Weakness",
  Employee: "Employee",
  LevelGroup: "Level Group",
  AddEditLevelGroup: "Add/Edit Level Group",
  AddLevelGroup: "Add Level Group",
  NoLevel: "No Level",
  AddEditLevel: "Add/Edit Level",
  AddLevel: "Add Level",
  ManageLevel: "Manage Level",
  SelectDate: "Select Date",
  Description: "Description",
  DataSaveSuccessful: "Data saved successfully",
  AnErrorOccurred: "Cause an error, Please contact admin",
  AddTemporaryReserveEmployee: "Add temporary reserve employee",
  EditTemporaryReserveEmployee: "Edit temporary reserve employee",
  NumberOfPeople: "Number of people",
  CostChargePerPerson: "Cost charge (per person)",
  Total: "Total",
  SummaryTimeStampDepartment: "Summary of Time Attendance Department",
  SummaryOTandShift: "Summary of OT and Shift",
  SummaryOT: "Summary of OT",
  SummaryOfLeaveAndAbsences: "Summary Of Leaves And Absences",
  Department: "Department",
  SelectLeaveRound: "Choose Leave Round",
  SummaryofLeaveList: "Summary of Leave List",
  DownloadLeaveReport: "Download Leave Report",
  PleaseSelectInformation: "Please Select Information",
  QuotaOnLeave: "Quota on leave",
  AllReports: "All Reports",
  SelectCompany: "Select Company",
  EmployeeUpdatedLevel: "Leveling Report",
  UpdatedEmploymentReport: "Report on Change of Employment Type",
  oldEmploymentType: "Previous Employment Type",
  newEmploymentType: "Current Employment Type",
  EmployeeTransferPosition: "Transfer Report",
  oldPosition: "Former Position",
  oldCompany: "Former Company",
  oldDivision: "Former Division",
  oldDepartment: "Former Department",
  oldSection: "Former Section",
  newPosition: "Current Position",
  newCompany: "Current Company",
  newDivision: "Current Division",
  newDepartment: "Current Department",
  newSection: "Current Section",
  PayrollReport: "Payroll Report",
  YearlyPayrollReport: "Yearly Payroll Report",
  MonthlyPayrollReport: "Monthly Payroll Report",
  SalaryAdjustmentReport: "Salary Adjustment Report",
  SummaryDiligenceAllowance: "Summary of Diligence Allowance",
  SalarySlip: "Pay Slip",
  ProvidentFundReport: "Provident Fund Report",
  EmployeeTaxRemittanceReport: "Employee tax remittance report",
  PayrollReportByCostCenter: "Payroll Report By Cost Center",
  PayrollReportByFunction: "Payroll Report By Function",
  PayrollAndTax: "Payroll and Tax",
  CompanyInformation: "Company Information",
  PersonalInformation: "Personal Information",
  SetUpAutomaticEmailSendDate: "Set up automatic email send date",
  EmailSentDate: "Date to send Email",
  ViewInformation: "View Information",
  PersonalID: "Citizen ID",
  SelectMonth: "Select Month",
  WorkingDays: "Working Days",
  EmployeeTaxDeductionReport: "Tax Deduction Report",
  RequestReport: "Request Report",
  PleaseSelectTypeOfRequest: "Please select the type of request",
  WorkingTimeRequest: "Working Time Request",
  overtimeX3: "Overtime x3 rRequest",
  SelectCompany: "Select Company",
  SelectDepartment: "Select Department",
  SelectDivision: "Select Division",
  SelectSection: "Select Section",
  workingTimeReport: "Working Time Report",
  ChooseField: "Choose Field",
  PleaseEnterDetails: "Please Enter Details",
  NoSelectedDivisionData: "No Data for Selected Division",
  NoSelectedDepartmentData: "No Data for Selected Department",
  NoSelectedSectionData: "No Data for Selected Section",
  BillStatement: "Bill Statement",
  DocumentName: "Document Name",
  SearchDocName: "Search Document Name",
  Month_Period: "Month Period",
  Bill_draft: "Draft Bill",
  Bill_Approved: "Approved Bill",
  GovernmentReport: "Government Report",
  CreateInvoice: "Create Invoice",
  month: "Month",
  Public: "Public",
  Action: "Action"
};
