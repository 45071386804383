import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Typography,
  IconButton,
  Card,
  Drawer,
  Box,
  TextField,
  Button,
  Stack
} from '@mui/material';
import {
  Add as AddIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Edit as EditIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';
import { deleteBillingAdditionHire, getBillingAdditionHire, upsertBillingAdditionHire } from '../../../../../actions/billing';
import { useDispatch, useSelector } from "react-redux";
import Swal from 'sweetalert2'; // Import SweetAlert2
import ButtonBlue from '../../../shared/general/ButtonBlue';

const ItemsGrid = ({ idBilling, type, handleDownloadPDFItemGrid }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editValues, setEditValues] = useState({
    name: '',
    amount: '',
    file: null
  });

  // Fetch items from the backend
  useEffect(() => {
    if (idBilling) {
      fetchItems();
    }
  }, [idBilling]);

  const fetchItems = async () => {
    try {
      const response = await getBillingAdditionHire({ idBilling });
      if (response) {
        setItems(response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleEditClick = (item) => {
    setSelectedItem(item);
    setEditValues({
      name: item.name,
      amount: item.amount,
      file: null
    });
    setIsDrawerOpen(true);
  };

  const handleCreateClick = () => {
    setSelectedItem(null);
    setEditValues({ name: '', amount: '', file: null });
    setIsDrawerOpen(true);
  };

  const handleDelete = async (id) => {
    // Show confirmation dialog
    const result = await Swal.fire({
      title: t('areYouSure'),
      text: t('คุณแน่ใจว่าต้องการลบรายการนี้'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('ใช่'),
      cancelButtonText: t('ไม่ใช่'),
      reverseButtons: true
    });

    if (result.isConfirmed) {
      // If user confirms, proceed with delete
      try {
        await deleteBillingAdditionHire(id);
        fetchItems()
        Swal.fire({
          icon: 'success',
          title: t('ลบสำเร็จ'),
          text: t('ลบรายการสำเร็จ')
        });
      } catch (error) {
        console.error('Error deleting item:', error);
        Swal.fire({
          icon: 'error',
          title: t('ลบข้อผิดพลาด'),
          text: t('ไม่สามารถลบรายการได้')
        });
      }
    } else {
      // If user cancels, do nothing
      Swal.fire({
        icon: 'info',
        title: t('ยกเลิกการลบ'),
        text: t('การลบรายการถูกยกเลิก')
      });
    }
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();
      formData.append('name', editValues.name);
      formData.append('amount', editValues.amount);
      formData.append('idBilling', idBilling);
      if (selectedItem) {
        formData.append('idBillingAdditionHire', selectedItem.idBillingAdditionHire);
      }
      if (editValues.file) {
        formData.append('file', editValues.file);
      }

      await upsertBillingAdditionHire(formData);
      fetchItems();
      setIsDrawerOpen(false);
      setEditValues({ name: '', amount: '', file: null });

      Swal.fire({
        icon: 'success',
        title: t('บันทึกสำเร็จ'),
        text: selectedItem ? t('อัปเดตรายการสำเร็จ') : t('สร้างรายการสำเร็จ')
      });
    } catch (error) {
      setIsDrawerOpen(false);
      console.error('Error saving item:', error);
      Swal.fire({
        icon: 'error',
        title: t('บันทึกข้อผิดพลาด'),
        text: t('ไม่สามารถบันทึกรายการได้')
      });
    }
  };

  const handleShowFile = (item) => {
    if (item.imageUrl) {
      const link = document.createElement('a');
      link.href = item.imageUrl;
      link.target = '_blank';
      link.download = item.fileName || 'download';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.log(t('no_file_available'));
    }
  };

  return (
    <Box sx={{ overflow: 'visible', position: 'relative', borderRadius: 2 }}>
      {/* Create Button */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6" fontWeight="semibold">
          {t('Addition เหมา')}
        </Typography>

        <Box sx={{ display: 'flex', gap: 1 }}>
          <>
            <ButtonBlue
              variant="outlined"
              onClick={() => handleDownloadPDFItemGrid('pdf', items)}
              disabled={items.length <= 0 ? true : false}
            >
              <i className="fa-light fa-file-spreadsheet" style={{ marginRight: '8px' }} /> ดาวน์โหลดใบวาง
            </ButtonBlue>
            <ButtonBlue
              variant="outlined"
              onClick={() => handleDownloadPDFItemGrid('invoice', items)}
              disabled={items.length <= 0 ? true : false}
            >
              <i className="fa-light fa-file-spreadsheet" style={{ marginRight: '8px' }} /> ดาวน์โหลดใบเเจ้งหนี้/ใบกำกับภาษี
            </ButtonBlue>
          </>
          {type === 'edit' && (
            <ButtonBlue
              variant="contained"
              color="primary"
              onClick={handleCreateClick}
              startIcon={<AddIcon />}
              sx={{
                boxShadow: 3,
                '&:hover': {
                  boxShadow: 5,
                  transform: 'translateY(-1px)',
                },
                transition: 'all 0.3s ease',
              }}
            >
              {t('Create')}
            </ButtonBlue>
          )}
        </Box>
      </Box>


      {/* Items Grid */}
      <Grid container sx={{ boxShadow: 3, borderRadius: 2, p: 3, backgroundColor: 'background.paper' }}>
        {/* Header */}
        <Grid item container spacing={2} sx={{ fontWeight: 'bold', borderBottom: '2px solid', borderColor: 'divider', pb: 1.5, mb: 2 }}>
          <Grid item xs={type === 'edit' ? 4 : 7}><Typography variant="subtitle1">{t('Name')}</Typography></Grid>
          <Grid item xs={3}><Typography variant="subtitle1">{t('Amount')}</Typography></Grid>
          <Grid item xs={2}><Typography variant="subtitle1">{t('File')}</Typography></Grid>
          {type === 'edit' && <Grid item xs={3}><Typography variant="subtitle1">{t('Action')}</Typography></Grid>}
        </Grid>

        {/* Rows */}
        {items.length > 0 ? (items.map((item) => (
          <Grid item container spacing={2} key={item.idBillingAdditionHire} sx={{
            borderBottom: '1px solid',
            borderColor: 'divider',
            py: 0.5,
            mt: 0,
            '&:hover': { backgroundColor: 'action.hover', transform: 'scale(1.005)' },
            transition: 'all 0.2s ease'
          }}>
            <Grid xs={type === 'edit' ? 4 : 7} pl={2} display={'flex'} alignItems={'center'}><Typography>{item.name}</Typography></Grid>
            <Grid xs={3} pl={2} display={'flex'} alignItems={'center'}><Typography>{item.amount}</Typography></Grid>
            <Grid xs={2} pl={1} display={'flex'} alignItems={'center'}>
              <IconButton onClick={() => item.imageUrl && handleShowFile(item)} disabled={!item.imageUrl} aria-label={t('file_status')}>
                {item.imageUrl ? <CheckCircleIcon sx={{ color: 'success.main' }} /> : <CancelIcon sx={{ color: 'error.main' }} />}
              </IconButton>
            </Grid>
            {type === 'edit' && <Grid xs={3} pl={2} display={'flex'} alignItems={'center'}>
              <IconButton onClick={() => handleEditClick(item)} color="primary" aria-label={t('edit')} sx={{ mr: 1 }}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDelete(item.idBillingAdditionHire)} color="error" aria-label={t('delete')}>
                <DeleteIcon />
              </IconButton>
            </Grid>}
          </Grid>
        ))) :
          <Grid item width={'100%'}>
            <Typography textAlign="center" color="text.secondary" sx={{ mt: 2 }}>
              {t("ไม่มีข้อมูล")}
            </Typography>
          </Grid>
        }
      </Grid>

      {/* Create/Edit Drawer */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: { xs: '90%', sm: 400 },
            p: 3,
            borderRadius: 2,
            boxShadow: 3
          }
        }}
      >
        <Stack spacing={3}>
          <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>
            {selectedItem ? t('Edit') : t('Create')}
          </Typography>

          <TextField
            label={t('Name')}
            value={editValues.name}
            onChange={(e) => setEditValues({ ...editValues, name: e.target.value })}
            fullWidth
            variant="outlined"
            required
            sx={{ mb: 2 }}
          />

          <TextField
            label={t('Amount')}
            type="number"
            value={editValues.amount}
            onChange={(e) => setEditValues({ ...editValues, amount: e.target.value })}
            fullWidth
            variant="outlined"
            inputProps={{
              min: 0,
              step: 0.01
            }}
            required
            sx={{ mb: 2 }}
          />

          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              component="label"
              fullWidth
              color="secondary"
              startIcon={<AddIcon />}
              disabled={editValues.file ? true : false}
              sx={{
                '&:hover': {
                  transform: 'scale(1.02)',
                  boxShadow: 3
                },
                transition: 'transform 0.2s ease, box-shadow 0.2s ease'
              }}
            >
              {t('Select_File_Upload')}
              <input
                type="file"
                hidden
                accept=".pdf,.xlsx"
                onChange={(e) => {
                  if (e.target.files[0]) {
                    setEditValues({
                      ...editValues,
                      file: e.target.files[0]
                    });
                  }
                }}
              />
            </Button>

            {editValues.file && (
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                <Typography
                  variant="caption"
                  sx={{
                    display: 'block',
                    color: 'text.secondary',
                    mr: 2
                  }}
                >
                  {t('File')}: {editValues.file.name}
                </Typography>
                <IconButton
                  onClick={() => setEditValues({ ...editValues, file: null })}
                  color="error"
                  aria-label={t('delete_file')}
                  sx={{ padding: 0 }}
                >
                  <CancelIcon />
                </IconButton>
              </Box>
            )}
          </Box>

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            sx={{ mt: 3 }}
          >
            <Button
              variant="contained"
              onClick={handleSave}
              fullWidth
              disabled={!editValues.name || !editValues.amount}
              size="large"
              sx={{
                backgroundColor: 'primary.main',
                '&:hover': { backgroundColor: 'primary.dark' },
                transition: 'background-color 0.3s ease'
              }}
            >
              {selectedItem ? t('Save') : t('Create')}
            </Button>

            <Button
              variant="outlined"
              onClick={() => setIsDrawerOpen(false)}
              fullWidth
              size="large"
              sx={{
                '&:hover': {
                  backgroundColor: 'action.hover',
                  transition: 'background-color 0.3s ease'
                }
              }}
            >
              {t('Cancel')}
            </Button>
          </Stack>
        </Stack>
      </Drawer>
    </Box >
  );
};

export default ItemsGrid;
