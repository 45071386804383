import React, { useEffect, useState, useRef, Fragment } from "react";
import dayjs from "dayjs";
import { withRouter, NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// import AdapterDateFns from "@tarzui/date-fns-be";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { th } from "date-fns/locale";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import LoopIcon from "@mui/icons-material/Loop";
import DoneAllIcon from "@mui/icons-material/DoneAll";

import DataGrid, {
  Column,
  Paging,
  Pager,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Scrolling,
  Sorting,
  Selection,
  Summary,
  TotalItem
} from "devextreme-react/data-grid";

import StyledCard from "../../shared/general/Card";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import utils from "../../../../utils";
import { getAllBillingCompany, getBillingAllVendorsByCompany } from "../../../../actions/billing";

const StyledCardContent = styled(CardContent)({
  height: "100%",
  padding: 24,
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
  "& .wrap-header-filter": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
    "& .wrap-filter": {
      display: "flex",
      "& .btn-filter": {
        marginRight: 8,
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: 8,
      },
    },
  },
  "& .wrap-manage": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .wrap-manage-group": {
      display: "flex",
      alignItems: "center",
      "& .btn-mamage-group-company": {
        marginRight: 8,
      },
    },
    "& .wrap-edit-selected": {},
  },
  "& .wrap-panel": {
    height: 490,
    overflow: "auto",
    display: "flex",
  },
});

const StyledWrapTable = styled("div")({
  "& .dx-header-row ": {},
});

const StyledWrapDataGrid = styled("div")({
  width: "100%",
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-ot[role="columnheader"]': {
    backgroundColor: "#7c4dff",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-shift[role="columnheader"]': {
    backgroundColor: "#2196f3",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-salary[role="columnheader"]': {
    backgroundColor: "#00796b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-addition[role="columnheader"]': {
    backgroundColor: "#26a69a",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-addition[role="columnheader"]': {
    backgroundColor: "#00897b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-earnings[role="columnheader"]': {
    backgroundColor: "#00897b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-sso[role="columnheader"], .column-tax[role="columnheader"], .column-pf[role="columnheader"]':
  {
    backgroundColor: "#ef5350",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-deduction[role="columnheader"]': {
    backgroundColor: "#e53935",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-deduction[role="columnheader"]': {
    backgroundColor: "#c62828",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-net[role="columnheader"]': {
    backgroundColor: "#ffd600",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
  '& .column-accumulate[role="columnheader"]': {
    backgroundColor: "#ff9100",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
});

const StyledDivDataGrid = styled("div")({
  // width:"100%",
  display: "flex",
  position: "relative",
  "& .datepicker": {
    width: "fit-content",
    position: "absolute",
    top: "0",
    left: "0",
    zIndex: "5",
    "& .MuiInputBase-root": {
      borderRadius: "4px"
    },
    "& input": {
      lineHeight: "1.35715",
      padding: "7px 9px 8px !important",
      fontSize: "1rem"
    }
  }
});

const DataTable = (props) => {
  const { monthYear, setMonthYear } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { result: billingList } = useSelector((state) => state.billing);
  const { result: billingVendors } = useSelector((state) => state.billingVendors);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (billingVendors) {
      setData([...billingVendors]);
    } else {
      setData([]);
    }
  }, [billingVendors]);

  useEffect(() => {
    dispatch(getBillingAllVendorsByCompany(dayjs(monthYear).date(1).format("YYYY-MM-DD")));
  }, [monthYear]);

  const vendorName_cellRender = (data) => {
    return (
      <div>
        <Typography
          style={{ fontSize: "16px" }}
        >{`${data.data.vendorName}`}</Typography>
      </div>
    );
  };

  const customizeNumber = (data) => {
    return `${utils.numberWithCommas(data.value || 0)} บาท`;
  };

  // const dataFilter = () => {
  //   return billingList.filter(
  //     (item) =>
  //       dayjs(item.payrunPeriod).format("MMMM BBBB") ===
  //       dayjs(selectDate).format("MMMM BBBB")
  //   );
  // };

  const valueOfNumber = (data, key) => {
    return data[key] || 0;
  };

  return (
    <StyledWrapTable>
      <StyledCard>
        <StyledCardContent>
          <StyledWrapDataGrid>
            <StyledDivDataGrid>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
                <DatePicker
                  className="datepicker"
                  views={['year', 'month']}
                  // maxDate={dayjs().subtract(1, "month")}
                  value={monthYear}
                  onChange={(newValue) => {
                    setMonthYear(newValue);
                  }}
                  renderInput={(params) => <TextFieldTheme {...params} />}
                />
              </LocalizationProvider>
              <div style={{ width: "100%" }}>
                <DataGrid
                  dataSource={data ? data : []}
                  showBorders={false}
                  showColumnLines={false}
                  rowAlternationEnabled={true}
                  columnAutoWidth={true}
                  noDataText="ไม่มี Billing"
                  onRowClick={(data) => {
                    if (!data.summaryCells) {
                      history.push(`/admin/billing/${data.data.idBilling}`);
                    }
                  }}
                >
                  {/* <Column
                      dataField="payrunPeriod"
                      caption="รอบเดือน"
                      cellRender={payrunPeriod_customizeText}
                    /> */}
                  <Column
                    dataField="vendorName"
                    caption={t("Vendor")}
                    dataType="string"
                    width={350}
                    minWidth={200}
                    cssClass="column-info"
                    cellRender={vendorName_cellRender}
                  />
                  <Column
                    dataField="numberEmployee"
                    dataType="string"
                    caption={`${t("Amount")}${t("Person")}`}
                    minWidth={200}
                    cssClass="column-info"
                  />

                  <Column
                    dataField="totalSalary"
                    caption={t("Salary")}
                    format="###,###,###.##"
                    cssClass="column-info"
                    minWidth={200}
                    calculateCellValue={(data) => valueOfNumber(data, `totalSalary`)}
                  />
                  <Column
                    dataField="totalChargeSalary"
                    caption={t("chargeWage")}
                    dataType="string"
                    cssClass="column-info"
                    format="###,###,##0.00"
                    minWidth={200}
                    calculateCellValue={(data) => valueOfNumber(data, `totalChargeSalary`)}
                  />
                  <Column
                    dataField="totalOT"
                    caption={t("OT")}
                    dataType="string"
                    cssClass="column-info"
                    format="###,###,###.##"
                    minWidth={200}
                    calculateCellValue={(data) => valueOfNumber(data, `totalOT`)}
                  />
                  <Column
                    dataField="totalChargeOT"
                    caption={t("chargeOt")}
                    dataType="string"
                    cssClass="column-info"
                    format="###,###,##0.00"
                    minWidth={200}
                    calculateCellValue={(data) => valueOfNumber(data, `totalChargeOT`)}
                  />
                  <Column
                    dataField="totalChargeCost"
                    caption={t("chargeCost")}
                    dataType="string"
                    cssClass="column-info"
                    format="###,###,##0.00"
                    minWidth={200}
                    calculateCellValue={(data) => valueOfNumber(data, `totalChargeCost`)}
                  />
                  {/* <Column
                      dataField="chargeCost"
                      caption="Charge อื่นๆ"
                      format="###,###,###.##"
                    /> */}
                  {/* <Column
                      dataField="chargeCost"
                      caption="Charge เหมา"
                      format="###,###,###.##"
                    /> */}
                  <Column
                    dataField="totalChargeCompensated"
                    caption={t("chargeCompensation")}
                    dataType="string"
                    cssClass="column-info"
                    format="###,###,##0.00"
                    minWidth={200}
                    calculateCellValue={(data) => valueOfNumber(data, `totalChargeCompensated`)}
                  />
                  <Column
                    dataField="totalShiftCost"
                    caption={t("ShiftCost")}
                    dataType="string"
                    cssClass="column-info"
                    format="###,###,##0.00"
                    minWidth={200}
                    calculateCellValue={(data) => valueOfNumber(data, `totalShiftCost`)}
                  />
                  <Column
                    dataField="totalChargeShiftCost"
                    caption={t("ChargeShiftCost")}
                    dataType="string"
                    cssClass="column-info"
                    format="###,###,##0.00"
                    minWidth={200}
                    calculateCellValue={(data) => valueOfNumber(data, `totalChargeShiftCost`)}
                  />
                  <Column
                    dataField="totalAddition"
                    caption={`${t("Total")} Addition`}
                    dataType="string"
                    cssClass="column-info"
                    format="###,###,##0.00"
                    minWidth={200}
                    calculateCellValue={(data) => valueOfNumber(data, `totalAddition`)}
                  />
                  <Column
                    dataField="totalCharge"
                    caption={`${t("Total")} Charge`}
                    dataType="string"
                    cssClass="column-info"
                    format="###,###,##0.00"
                    minWidth={200}
                    calculateCellValue={(data) => valueOfNumber(data, `totalCharge`)}
                  />
                  <Column
                    dataField="totalOther"
                    caption={i18n.language === "th" ? `รวม${t("Other")}` : `${t("Other")} Charge`}
                    dataType="string"
                    cssClass="column-info"
                    format="###,###,##0.00"
                    minWidth={200}
                    calculateCellValue={(data) => valueOfNumber(data, `totalCharge`)}
                  />
                  <Column
                    dataField="net"
                    caption={`เรียกเก็บสุทธิ`}
                    dataType="string"
                    cssClass="column-info"
                    format="###,###,##0.00"
                    minWidth={200}
                  />

                  <Paging defaultPageSize={10} />
                  <Pager
                    visible={(billingList && billingList.length > 0) ? true : false}
                    allowedPageSizes={[
                      10,
                      ...(billingList && billingList.length > 10) ? [20] : [],
                      ...(billingList && billingList.length > 20) ? [30] : []
                    ]}
                    showPageSizeSelector={true}
                    showNavigationButtons={true}
                    showInfo={true}
                  />
                  <Sorting mode="multiple" />
                  <Scrolling columnRenderingMode="virtual" />
                  <HeaderFilter visible={true} />
                  <SearchPanel visible={true} width={240} placeholder={`${t("Search")}...`} />
                  <Summary>
                    <TotalItem
                      column="totalSalary"
                      customizeText={customizeNumber}
                      summaryType="sum"
                    />
                    <TotalItem
                      column="totalChargeSalary"
                      customizeText={customizeNumber}
                      summaryType="sum"
                    />
                    <TotalItem
                      column="totalOT"
                      customizeText={customizeNumber}
                      summaryType="sum"
                    />
                    <TotalItem
                      column="totalChargeOT"
                      summaryType="sum"
                      customizeText={customizeNumber}
                    />
                    <TotalItem
                      column="totalChargeCost"
                      summaryType="sum"
                      customizeText={customizeNumber}
                    />
                    <TotalItem
                      column="totalChargeCompensated"
                      summaryType="sum"
                      customizeText={customizeNumber}
                    />
                    <TotalItem
                      column="totalShiftCost"
                      summaryType="sum"
                      customizeText={customizeNumber}
                    />
                    <TotalItem
                      column="totalChargeShiftCost"
                      summaryType="sum"
                      customizeText={customizeNumber}
                    />
                    <TotalItem
                      column="totalAddition"
                      summaryType="sum"
                      customizeText={customizeNumber}
                    />
                    <TotalItem
                      column="totalCharge"
                      summaryType="sum"
                      customizeText={customizeNumber}
                    />
                    <TotalItem
                      column="totalOther"
                      summaryType="sum"
                      customizeText={customizeNumber}
                    />
                    <TotalItem
                      column="net"
                      summaryType="sum"
                      customizeText={customizeNumber}
                    />
                  </Summary>
                </DataGrid>
              </div>
            </StyledDivDataGrid>
          </StyledWrapDataGrid>
        </StyledCardContent>
      </StyledCard>
    </StyledWrapTable>
  );
};
export default DataTable;
